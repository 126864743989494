<script>
import CustomHomeHeader from "@/components/CustomHomeHeader.vue";

import { mapGetters } from "vuex";
import {  } from "@/services/apiServices";
// import * as mutationTypes from "@/store/mutationTypes";

const reports = [
  {
    icon: require('@/assets/Images/footerIcons/expenseActive.svg'),
    title: 'Sales report',
    sub_text: '',
    // sub_text: 'View comprehensive sales reports for different periods',
    id: 0
  },
  {
    icon: require('@/assets/Images/footerIcons/expenseActive.svg'),
    title: 'Inventory report',
    sub_text: 'Coming soon',
    id: 1
  },
  {
    icon: require('@/assets/Images/footerIcons/expenseActive.svg'),
    title: 'Customer report',
    sub_text: 'Coming soon',
    id: 2
  },
]

export default {
  name: "ReportsIndex",
  components: {
    CustomHomeHeader,
  },
  data() {
    return {
      reports,
    };
  },
  methods: {
    viewReport(id) {
      id == 0
        ? this.$router.push("/sales_report")
        : alert("Coming soon")
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  mounted() {
    
  },
};
</script>
<template>
  <div class="container">
    <div class="body">
      <CustomHomeHeader text="Reports" link="ReportsIndex" />
      <div></div>
    </div>
    <div class="main">
      <h2 style="font-size: 20px;">Select Report</h2>
      <div class="reports_parent">
        <div
          class="report"
          v-for="report, i in reports"
          :key="i"
          @click="viewReport(report.id)"
        >
          <div class="img">
            <img :src="report.icon" :alt="report.title" >
          </div>
          <div class="content">
            <h3>{{ report.title }}</h3>
            <p>{{ report.sub_text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
}
.btn-link {
  text-decoration: none;
  color: white;
}
.main {
  padding: 20px
}
.reports_parent {
  display: flex;
  flex-direction: column;
}
.report {
  /* position: relative; */
  background-color: #f5fcf4;
  border: #c4dbd5 1px solid;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
}
.img {
  display: inline-block;
  border: #c4dbd5 0.5px solid;
  background: #FFF;
  padding: 5px;
  padding-bottom: 2px;
  border-radius: 8px;
  vertical-align: top;
}
.content {
  display: inline-block;
  max-width: 85%;
  margin-left: 10px;
}
.content h3 {
  font-weight: 600;
  opacity: 0.8;
}
.content p {
  margin-top: 5px;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  opacity: 0.9;
}
/* -----------------
Sales report headings as grid
----------------- */
/* .reports_parent {
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}
.report {
  background-color: #f5fcf4;
  height: 100px;
  border: #c4dbd5 1px solid;
  border-radius: 8px;
  padding: 20px;
} */
</style>
