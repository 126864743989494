<script>
import basketBlock from "@/assets/Images/basketBlock.svg";
import graphBlock from "@/assets/Images/graphBlock.svg";
import customerBlock from "@/assets/Images/customerBlock.svg";
import truck from "@/assets/Images/truck.svg";
export default {
  name: "SummaryBlocks",
  props: ["stats"],
  data() {
    return {
      basketBlock,
      graphBlock,
      customerBlock,
      truck,
    };
  },
};
</script>
<template>
  <div class="header_link">
    <a href="#" v-if="stats.length > 4">Show more</a>
  </div>
  <div>
    <div class="container_content_div">
      <div class="content_div one">
        <img :src="basketBlock" alt="orders icon" />
        <div class="text-block">
          <h1>{{ stats[0].value }}</h1>
          <p>{{ stats[0].stat }}</p>
        </div>
      </div>
      <div class="content_div two">
        <img :src="graphBlock" alt="purchase icon" />
        <div class="text-block">
          <h1>{{ stats[1].value }}</h1>
          <p>{{ stats[1].stat }}</p>
        </div>
      </div>
    </div>
    <div class="container_content_div">
      <div class="content_div three">
        <img :src="customerBlock" alt="returns" />
        <div class="text-block">
          <h1>{{ stats[2].value }}</h1>
          <p>{{ stats[2].stat }}</p>
        </div>
      </div>
      <div class="content_div four">
        <img :src="truck" alt="buying" />
        <div class="text-block">
          <h1>{{ stats[3].value }}</h1>
          <p>{{ stats[3].stat }}</p>
        </div>
      </div>
    </div>
    <div v-if="stats.length > 4" class="container_content_div">
      <div class="content_div three">
        <img :src="customerBlock" alt="returns" />
        <div class="text-block">
          <h1>{{ stats[4].value }}</h1>
          <p>{{ stats[4].stat }}</p>
        </div>
      </div>
      <div class="content_div four">
        <img :src="truck" alt="buying" />
        <div class="text-block">
          <h1>{{ stats[5].value }}</h1>
          <p>{{ stats[5].stat }}</p>
        </div>
      </div>
    </div>
  </div>
</template>	

<style scoped>
.container_content_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_div {
  width: 50%;
  border: #c4dbd5 1px solid;
  background-color: #f5fcf4;

  display: flex;
  padding: 10px;
}

.content_div > div {
  margin-left: 10px;
}

.content_div > div > h1 {
  font-size: 16px;
  padding: 4px 0;
  color: #002e22;
}

.content_div > div > p {
  font-size: 16px;
  color: #355f53;
}
.header_link {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 0 16px;
  margin-bottom: 8px;
}

.header_link a {
  color: #008060;
}
.one {
  border-top-left-radius: 8px;
}

.two {
  border-top-right-radius: 8px;
}

.three {
  border-bottom-left-radius: 8px;
}

.four {
  border-bottom-right-radius: 8px;
}
.text-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* until stats are properly populated */
.container_content_div {
  /* display: none; */
}
</style>
