<script>
import CustomHomeHeader from "@/components/CustomHomeHeader.vue";
import SummaryBlocks from "@/components/SummaryBlocks.vue";
import SetStrictStock from "@/components/Inventory/SetStrictStock";

import plusWhite from "@/assets/Images/plusWhite.svg";
import searchIcon from "@/assets/Images/searchIcon.svg";
import profile from "@/assets/Images/cartoonProfile.svg";
import DeleteConfirmationPopUp from "@/components/DeleteConfirmationPopUp.vue";
// import hamMenu from "@/assets/Images/hamMenu.svg"
import linklight from "@/assets/Images/linklight.svg";
import copyIcon from "@/assets/Images/copyIcon.svg";
import share from "@/assets/Images/share.svg";
import dustBinIcon from "@/assets/Images/dustbin.svg";
import penIcon from "@/assets/Images/editPen.svg";
import SearchBar from "@/components/SearchBar.vue";
import { mapGetters } from "vuex";
import { deleteProduct } from "@/services/apiServices"
import * as mutationTypes from "@/store/mutationTypes";

const scrollHeader = [
  { name: "Out" },
  { name: "Low" },
  { name: "Issues" },
  { name: "Avaliable" },
  { name: "Unavailable" },
  { name: "New" },
  { name: "Stale" },
  { name: "Added now" },
];
export default {
  name: "ProductList",
  components: {
    CustomHomeHeader,
    DeleteConfirmationPopUp,
    SummaryBlocks,
    SetStrictStock,
    SearchBar
  },
  data() {
    return {
      activeDeleteItem: {id: null, name: ""},
      plusWhite,
      searchIcon,
      profile,
      // hamMenu,
      linklight,
      copyIcon,
      share,
      dustBinIcon,
      penIcon,
      baseUrl: "",
      isAvailable: true,
      visible: false,
      scrollHeader,
      input: "",
      filter: "available",
      currentStatus: "all",
      showOverlay: false,

      this_day: null,
      ms_per_day: null,
      utc1: null,
    };
  },
  methods: {
    addProduct() {
      this.$store.commit(mutationTypes.SET_PRODUCT_TO_EDIT, {})
      this.$router.push("/product_form/product")
    },
    deleteItem() {
      deleteProduct(this.activeDeleteItem.id)
      this.$store.commit(mutationTypes.DELETE_PRODUCT, this.activeDeleteItem.id)
      alert(this.activeDeleteItem.name + " has been deleted")
      this.activeDeleteItem.id = null
      this.activeDeleteItem.name = null

      this.showOverlay = false
    },
    deleteWarning(id, name) {
      this.activeDeleteItem.id = id
      this.activeDeleteItem.name = name
      this.showOverlay = true;
      this.overlayTitle = "Delete product"
      this.overlayMessage = "This will permanently delete this product."
      this.overlaySubMessage = ""
    },
    getStockColor(total_stock) {
      if (total_stock === 0) {
        return "rgba(206, 60, 85, 0.15)";
      } else if (total_stock < 4) {
        return "#F9F3DB";
      }
    },
    getStockTextStyle(total_stock) {
      if (total_stock === 0) {
        return "red";
      } else if (total_stock <= 3) {
        return "orange";
      }
    },
    getStockText(total_stock) {
      if (total_stock <= 0) {
        return "Out";
      } else if (total_stock <= 3) {
        return `Low`;
      }
    },
    handleCancel() {
      this.showOverlay = false;
    },
    productDetails(product) {
      this.$store.commit(mutationTypes.SET_PRODUCT_TO_EDIT, product);
      this.$router.push({ name: "Product Details" });
    },
    toggleMoreOptions(product) {
      product.visible = !product.visible;
      if (product.visible) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    toggleMode() {
      this.isAvailable = !this.isAvailable;
    },
    handleClickOutside(event) {
      if (
        this.$refs.options &&
        this.$refs.hamMenu &&
        typeof this.$refs.options === "function" &&
        typeof this.$refs.hamMenu === "function" &&
        !this.$refs.options.contains(event.target) &&
        !this.$refs.hamMenu.contains(event.target)
      ) {
        this.visible = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
  },
  computed: {
    ...mapGetters({
      inventory: "getInventory",
    }),
    countLowStock() {
      let count = 0
      for (let i=0; i < this.inventory.length; i++) {
        this.inventory[i].total_stock < 4 ? count ++ : ""
      }
      return count
    },
    countNewProducts() {
      let count = 0
      for (let i=0; i < this.inventory.length; i++) {
        let created_year = parseInt(this.inventory[i].created.substring(0, 4))
        let created_month = parseInt(this.inventory[i].created.substring(5, 7)) - 1
        let created_day = parseInt(this.inventory[i].created.substring(8, 10))

        let utc2 = Date.UTC(created_year, created_month, created_day)
        let difference = Math.floor((this.utc1 - utc2) / this.ms_per_day)

        difference < 8 ? count ++ : ""
      }
      return count
    },
    staleProducts() {
      let count = 0
      for (let i=0; i < this.inventory.length; i++) {
        try {
          let sale_year = parseInt(this.inventory[i].last_sale.substring(0, 4))
          let sale_month = parseInt(this.inventory[i].last_sale.substring(5, 7)) - 1
          let sale_day = parseInt(this.inventory[i].last_sale.substring(8, 10))

          let utc2 = Date.UTC(sale_year, sale_month, sale_day)
          let difference = Math.floor((this.utc1 - utc2) / this.ms_per_day)

          difference > 30 ? count ++ : ""
        } catch { null }
      }
      return count
    },
    modeTextColor() {
      return this.isAvailable ? "#008060" : "gray";
    },
    modeText() {
      return this.isAvailable ? "" : "";
    },
    stats() {
      return [
        {
          stat: "Products",
          value: this.inventory.length,
        }, {
          stat: "Stale",
          value: this.staleProducts, // also, monetary value and margin
        },
        {
          stat: "Low stock",
          value: this.countLowStock,
        },
        {
          stat: "New",
          value: this.countNewProducts,
        },
      ];
    },
     filteredList() {
      return this.inventory.filter(
        (product) =>
          product.product_name
            .toLowerCase()
            .includes(this.input.toLowerCase()) 
      );
    },
  },
  mounted() {
    this.baseUrl = window.location.origin;

    // let order_year = this.inventory[0].created.substring(0, 4)
    // let order_month = this.inventory[0].created.substring(5, 7) - 1
    // let order_date = this.inventory[0].created.substring(8, 10)

    this.ms_per_day = 1000 * 60 * 60 * 24
    const today = new Date();
    this.this_day = today.getDay(); // position in week

    this.utc1 = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    )
  },

  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<template>
  <CustomHomeHeader text="Inventory" link="inventory" />
  <div class="container">
    <div class="main">
      <SummaryBlocks :stats="stats" />
      <div class="semi_header">
        <SearchBar v-model="input" placeholder="Search for product..." />
          <button class="add_product"
          @click="addProduct">  <img :src="plusWhite" alt="a green plus icon" /> Add product
          </button>
      </div>
    </div>
    <ul class="scroll_header">
      <li v-for="i in scrollHeader" :key="i" class="scroll_header_list">
        {{ i.name }}
      </li>
    </ul>
    <div class="card_container">
      <div class="item_card" v-for="(product, i) in filteredList" :key="i">
        <div class="card_top">
          <div @click="productDetails(product)" style="display: flex;">
            <!-- <div v-if="product.product_image">
              <p>has product</p>
            </div> -->

            <div v-if="product.product_image" class="card_top_details">
              <img
                v-if="baseUrl == 'http://127.0.0.1:8080'"
                :src="'http://127.0.0.1:8000' + product.product_image"
                alt="img"
              />
              <img v-else :src="product.product_image" alt="img" />
            </div>
            <div v-else class="card_top_details">
              <img src="@/assets/Images/default_product_image.png" alt="img" />
            </div>
            
            <div class="ct_info">
              <h1>{{ product.product_name }}</h1>
              <p v-if="product.price != 0">&#x20A6; {{ product.price }}</p>
              <p v-else>&#x20A6; - -</p>
            </div>
          </div>
          <div class="ct_right">
            <!-- <img
              :src="hamMenu"
              alt="hamMenu"
              @click="toggleMoreOptions(product)"
              ref="hamMenu"
            /> -->
          </div>
          <div v-if="product.visible" class="options" ref="options">
            <RouterLink to="/changePrice" class="pen">
              <img :src="penIcon" alt="penIcon" />Edit item
            </RouterLink>
            <RouterLink class="thrash" to="/itemInfo">
              <img :src="dustBinIcon" alt="thrash Icon" />Delete
              item</RouterLink
            >
          </div>
        </div>
        <div class="card_buttom">
          <div>
            <img :src="dustBinIcon" alt="dustbin"
              @click="deleteWarning(product.id, product.product_name)"
              style="display: inline-block; margin-right: 5px;"
            />
            <p class="payment_status" style="display: inline-block;">
              {{ product.total_stock }} in stock
              <span
                :style="{
                  backgroundColor: getStockColor(product.total_stock),
                  borderRadius: '24px',
                  color: getStockTextStyle(product.total_stock),
                }"
              >
                {{ getStockText(product.total_stock) }}
              </span>
            </p>
          </div>
          <SetStrictStock :id="product.id" :state="product.strict_stock_count" :product="product"/>
        </div>
      </div>
    </div>
  </div>
  <div style="display: block; height: 35px"><!--props page up--></div>
  <DeleteConfirmationPopUp
    :visiblePopUp="showOverlay"
    :index="deleteIndex"
    @confirm="handleConfirm"
    @cancel="handleCancel"

    :title="overlayTitle"
    :message="overlayMessage"
    :sub_message="overlaySubMessage"

    @delete="deleteItem()"
  />
</template>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 16px;
}
.main {
  width: 100%;
}
.content_holder {
  width: 100%;
}

.header_link {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 0 16px;
  margin-bottom: 8px;
}

.header_link a {
  color: #008060;
}

.semi_header > button {
  background: #008060;
  border: none;
  border-radius: 4px;
  padding: 6px 8px;
}

.add_product {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.add_product > img {
  margin: 0 4px;
}

.semi_header {
 display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}



.item_card {
  margin-bottom: 8px;
  padding: 16px 12px;
  background: #f5fcf4;
  display: flex;
  flex-direction: column;
  border: #c4dbd5 1px solid;
  border-radius: 8px;
}

.card_top {
  display: flex;
  justify-content: space-between;
  border-bottom: #c4dbd5 1px solid;
}

.card_top_details {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.card_top_details > img {
  margin-right: 12px;
  width: 55px;
  height: 55px;
  object-fit: contain;
  border-radius: 5px;
}

.card_container {
  width: 100%;
  margin-bottom: 36px;
}

.clr {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.clr > label {
  margin: 4px 0;
  color: #002e22;
}

.ct_info > h1 {
  font-size: 16px;
  color: #002e22;
  font-weight: 500;
  margin-bottom: 4px;
}

.ct_info > p {
  font-size: 14px;
  color: #355f53;
  font-weight: 400;
}

.card_buttom {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.qty {
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #c4dbd5 1px solid;
}
.available-mode {
  background-color: lightgreen;
  color: darkgreen;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
  margin-left: 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #93cab9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #93cab9;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.profile {
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
}

.ct_right {
  display: flex;
  flex-direction: column;
  color: #143e32;
  align-items: flex-end;
}

.ct_right > p {
  font-weight: 500;
}

.scroll_header {
  display: flex;
  overflow-x: scroll;
  justify-content: space-between;
  border-bottom: #c4dbd5 1px solid !important;
  margin: 16px 0;
  padding: 0;
  width: 100%;
}

.scroll_header::-webkit-scrollbar {
  display: none;
}

.scroll_header {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.availability {
  display: flex;
  align-items: center;
}
li {
  display: flex;
  font-size: 14px;
  color: #002e22;
  list-style: none;
  padding: 8px;
  font-weight: 400;
  text-wrap: nowrap;
}

li:focus,
li:hover {
  color: #008060;
  border-bottom: #008060 1px solid;
}

small,
.payment_status {
  background: transparent;
  padding: 6px;
  padding-right: 0;
  border: #c4dbd5 1px solid;
  border-radius: 24px;
  color: #92aaa3;
}

.payment_status span {
  margin: 4px;
  padding: 4px 8px;
}

.ct_info > p {
  margin: 4px 0;
}

.options {
  z-index: 50;
  position: absolute;
  border-radius: 8px;
  right: 0;
  padding: 12px;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 4px 16px 0px #d1e4de80;
}
.options a {
  padding: 8px;
  text-decoration: none;
  border-bottom: #c4dbd5 0.3px solid;
}
.options a > img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.pen {
  color: #355f53;
}
.thrash {
  color: red;
}
</style>
