<script>
import { mapGetters } from "vuex"

export default {
  name: "SalesBreakdown",
  props: [
   'breakdown',
   'net_sales',
   'date_range',
  ],
  data() {
    return {
      // Empty samplese required to prevent //
      // non-data errors //
      order_count: 0,
      product_sales: [
        {
          product: "Empty",
          qty: 0,
          percent: 0,
          amount: 0
        },
      ],
      orders: [
        {
          unit: "Mon",
          orders: 0,
          products: 0,
          items: 0,
          percent: 0,
          amount: 0,
        },
      ],
      customers: [
        {
          first_name: "",
          order_count: 0,
          order_refs: [],
          item_count: 0,
          total_spent: 0,
          percent: 0,
        },
        {
          first_name: "",
          order_count: 0,
          order_refs: [],
          item_count: 0,
          total_spent: 0,
          percent: 0,
        },
        {
          first_name: "",
          order_count: 0,
          order_refs: [],
          item_count: 0,
          total_spent: 0,
          percent: 0,
        },
      ],      
    };
  },
  methods: {
    updateReport() {
      var products = []
      for (let i=0; i < this.breakdown.unique_products.length; i++) {
        let index = this.inventory.findIndex(product => product.id == this.breakdown.unique_products[i].id)
        try {var name = this.inventory[index].product_name}
        catch {name = "Unknown"}

        let percent = Math.round(this.breakdown.unique_products[i].amount / this.net_sales * 100)

        let product = {
          'product': name,
          'qty': this.breakdown.unique_products[i].qty,
          'percent': percent,
          'amount': this.breakdown.unique_products[i].amount,
        }
        products.push(product)
      }
      this.product_sales = products


      // consider getting this other ways //
      this.orders = this.breakdown.sales_count
      let order_count = 0
      for (let i=0; i < this.orders.length; i++) {
        order_count +=  this.orders[i].count
      }
      this.order_count = order_count

      var max = 0
      var widths = []
      for (let i=0; i < this.orders.length; i++) {
        let width = this.orders[i].count / this.order_count * 100
        width > max ? max = width : ""
        widths.push(width)
      }

      var quotient = Math.floor(100 / max)

      if (quotient > 1) {
        for (let i=0; i < this.orders.length; i++) {
          let id = this.orders[i].unit
          let unit = document.getElementById(id)
          unit.style.width = widths[i] * quotient + "%"
        }
      } else {
        for (let i=0; i < this.orders.length; i++) {
          let id = this.orders[i].unit
          let unit = document.getElementById(id)
          console.log(unit)
          unit.style.width = widths[i] + "%"
        }
      }


      var patrons = this.breakdown.customers
      for (let i in patrons) {
        patrons[i].percent = Math.round(patrons[i].total_spent / this.net_sales * 100)
      }

      patrons.sort((a, b) => (
        a.total_spent < b.total_spent ? 1 : b.total_spent < a.total_spent ? -1 : 0))

      this.customers = patrons
    }
  },
  computed: {
    ...mapGetters({
      inventory: "getInventory",
    }),
    total_items_sold() {
      return this.breakdown.total_item_count
    },
    unique_products() {
      return this.breakdown.unique_product_count
    },
    avg_customer_spend() {
      return Math.round(this.net_sales / this.customers.length)
    },
    top_three_customer_spend() {
      let total_spent = 0
      this.customers.length > 2
        ?  total_spent = this.customers[0].total_spent + this.customers[1].total_spent + this.customers[2].total_spent
        : this.customers.length > 1
          ? total_spent = this.customers[0].total_spent + this.customers[1].total_spent
          : total_spent = this.customers[0].total_spent
      
      return total_spent
    },
    top_three_percent() {
      return Math.round(this.top_three_customer_spend / this.net_sales * 100)
    }
  },
  watch: {
    breakdown: {
      handler: function () {
        if (this.breakdown.total_item_count > 0) {
          this.report = this.sales_report
          this.updateReport()
        }        
      },
    },
  },
};
</script>
<template>
  <div class="container">
    <section class="executive_summary">
      <h2 style="font-size: 28px">2. Sales breakdown</h2>
      <h3 class="section_title">Sales by products sold</h3>
      <p class="prose">
        You sold a total of <span style="font-weight: bold;">{{ total_items_sold }}</span> items this month, made up of <span style="font-weight: bold;">{{ unique_products }}</span> product. Your three best sellers were blue facecap, green wallet, and Ribena, contributing 30% of your sales. iPhone 5 and three other products did not have any sales.
      </p>
      <div style="margin-top: 30px;">
        <h4>Break down of sales.</h4>
        <span>{{ date_range }}</span>
        <div class="">
          <div class="">
            <table style="width: 100%;">
              <tr>
                <th>Product</th>
                <th>Qty</th>
                <th>% of sales</th>
                <th>Amount</th>
              </tr>
              <tr v-for="product, i in this.product_sales" :key="i">
                <td>{{ product.product }}</td>
                <td>{{ product.qty }}</td>
                <td>{{ product.percent }}%</td>
                <td>&#8358; {{ product.amount.toLocaleString() }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <h3 class="section_title" style="margin-top: 50px;">Sales by order numbers</h3>
      <p class="prose">
        You earned a total of 106 orders this month with an average of three items per order, and an average order value of ₦24,500. Week three was your busiest, accounting for 35% of your sales in the period.
      </p>
      <div style="margin-top: 30px;">
        <h4>Break down of orders.</h4>
        <div class="mini_container">
          <div class="flex"></div>
          <div class="sales_graph">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <div>
                <h4 style="display: inline-block; margin-top: 5px;">
                  {{ date_range }}
                </h4>
              </div>
              <div class="select_box">
                <span>Orders ^</span>
              </div>
            </div>
            <ul>
              <li v-for="order, i in this.orders" :key="i">
                <span style="flex-basis: 40px; opacity: 0.8;">{{ order.unit }}</span>
                <div style="flex-grow: 1;">
                  <div :id="order.unit" class="bar_graph">
                    <span style="color: #F5FCF4">{{ order.count }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="flex"></div>
        </div>
      </div>

      <h3 class="section_title" style="margin-top: 50px;">Sales by customer activity</h3>
      <p class="prose">
        You were patronised by <span style="font-weight: bold;">{{ customers.length }}</span> customers this <span>month</span>. They spent an average of <span style="font-weight: bold;">&#8358;{{ avg_customer_spend.toLocaleString() }}</span> but your top three were <span style="font-weight: bold;">{{ customers[0]['first_name'] }}</span>, <span style="font-weight: bold;" v-if="customers.length > 1">{{ customers[1]['first_name'] }}</span>, and <span style="font-weight: bold;" v-if="customers.length > 2">{{ customers[2]['first_name'] }}</span>, who purchased a combined <span style="font-weight: bold;">&#8358;{{ top_three_customer_spend.toLocaleString() }}</span> worth of goods, making up <span style="font-weight: bold;">{{ top_three_percent }}%</span> of your sales.
      </p>
      <div style="margin-top: 30px;">
        <h4>Break down of customer activity.</h4>
        <div class="">
          <div class="">
            <table style="width: 100%;">
              <tr>
                <th style="width: 100px;">Name</th>
                <th>Order count</th>
                <th>Amount spent</th>
                <th>% of sales</th>
              </tr>
              <tr v-for="customer, i in this.customers" :key="i">
                <td>
                  <span class="no_wrap">{{ customer.first_name }}</span> 
                </td>
                <td>{{ customer.order_count }} <span class="light" style="margin-top: 0;">orders</span> 
                </td>
                <td>
                  <span v-if="customer.total_spent > 999999">
                    &#8358;{{ (Math.round(customer.total_spent/1000000)).toLocaleString() }} M
                  </span>

                  <span v-else-if="customer.total_spent > 100000">
                    &#8358;{{ (Math.round(customer.total_spent/1000)).toLocaleString() }} K
                  </span>

                  <span v-else>&#8358;{{ customer.total_spent.toLocaleString() }} </span>
                </td>
                <td>
                  <span style="display: inline-block; margin-top: 5px;">{{ customer.percent }}%</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>      
    </section>
  </div>
</template>

<style scoped>
* {
  box-sizing: border-box;
  color: #143E32;
}
ul {
  padding: 0;
}
.container {
  margin-top: 20px;
  border-top: #C4DBD5 1px solid;
  padding: 20px;
  padding-top: 50px;
}
.section_title {
  margin-top: 20px;
  text-decoration: underline;
}
.prose {
  margin-top: 10px;
  font-size: 18px;
  word-spacing: 2px;
  line-height: 24px;
}
 table {
  margin-top: 5px;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  border-spacing: 0;
 }
th {
  background: #FFF;
  text-align: left;
  border-bottom: 1px solid #C4DBD5;
  border-right: 1px solid #C4DBD5;
}
th:first-of-type {
  border-radius: 8px 0 0 0;
}
th:last-of-type {
  border-radius: 0 8px 0 0;
  border-right: none;
}
th, tr td {
  padding: 10px 7px;
}
td {
  border-bottom: 1px solid #C4DBD5;
  border-right: 1px solid #C4DBD5;
}
table tr:last-of-type td {
  border-bottom: none;
}
table tr td:last-of-type {
  border-right: none;
}
table tr:last-of-type td:first-of-type {
  border-radius: 0 0 0 8px;
}
table tr:last-of-type td:last-of-type {
  border-radius: 0 0 8px 0;
}
tr {
  background: #F5FCF4;
}
.light {
  opacity: 0.7;
  display: inline-block;
  margin-top: 5px;
}
.no_wrap {
  display: inline-block; width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.mini_container > div:nth-of-type(2) {
  width: 100%;
}
.mini_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #F5FCF4;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  padding: 15px 0;
  margin-top: 5px;
}
.flex {
  width: 15px;
}
.sales_graph ul {
  margin-top: 25px; 
}
.sales_graph ul li {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px; 
}
.bar_graph {
  background: #008060;
  border-radius: 5px;
  padding: 3px 10px;
  color: #FFF;
}
.bar_graph span {
  display: inline-block;
  width: 100%;
  text-align: right;
  opacity: 0.9;
}
.select_box {
  height: 34px;
  background: #FFF;
  border: #C4DBD5 1px solid;
  border-radius: 5px;
}
.select_box span {
  display: block;
  padding: 7px;
}
</style>
