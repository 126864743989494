<script>
import noPaymentIcon from "@/assets/Images/noPayment.svg";
import cancelDark from "@/assets/Images/cancelDark.svg";
import plusWhite from "@/assets/Images/plusWhite.svg";
export default {
  props: {
    isVisibleModal: {
      type: Boolean,
      required: true,
    },
      noPayment: {
      type: Boolean,
        required: false,
    },
    paymentStatus: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      noPaymentIcon,
      cancelDark,
      plusWhite,
    };
  },
  methods: {
    closePopup() {
      this.$emit("cancel");
    },
    goToUpdate() {
      this.$emit("changePage");
    },
  },
};
</script>
<template>
  <div class="modal" v-if="isVisibleModal">
    <div class="container_holder">
      <div class="cancel_container">
        <div class="cancel">
          <img :src="cancelDark" alt="cancel" @click="closePopup" />
        </div>
      </div>
      <div class="container">
        <h1>Update Payment</h1>
        <div class="content">
          <p
            :style="{
              paddingBottom: '12px',
              display: 'flex',
              alignItems: 'center',
            }"
          >
            <span
              :style="{
                padding: '5px',
                borderRadius: '12px',
                fontSize: '11px',
              }"
              >NGN</span
            >Order price
          </p>
          <h2>NGN 6,000</h2>
        </div>
        <div class="main-text" v-if="noPayment">
          <img :src="noPaymentIcon" alt="plus icon" />
          <div
            :style="{
              marginTop: '10px',
              marginBottom: '7px',
              textAlign: 'center',
            }"
          >
            <h3 :style="{ marginTop: '7px', marginBottom: '10px' }">
              No payment history
            </h3>
            <p>You have no pending history</p>
          </div>

          <button
            @click="goToUpdate()"
            :style="{
              marginTop: '16px',
              padding: '6px 10px',
              borderRadius: '6px',
              border: 'none',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
            }"
          >
            <img :src="plusWhite" alt="plus icon" /> Add/update
          </button>
        </div>
        <div v-if="paymentStatus" :style="{margin: '12px'}">
          <div :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  }">
            <h3>Payment history</h3>
            <button
              @click="goToUpdate()"
              :style="{
                marginTop: '16px',
                padding: '6px 10px',
                borderRadius: '6px',
                border: 'none',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
              }"
            >
              <img :src="plusWhite" alt="plus icon" /> Add/update
            </button>

          
          </div>
            <table>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Method</th>
                <th>Outstanding</th>
              </tr>
              <tr>
                <td>20/08/24</td>
                <td>12,000</td>
                <td>Transfer</td>
                <td :style="{ color: 'red' }">8,000</td>
              </tr>
              <tr>
                <td>23/08/24</td>
                <td>8,000</td>
                <td>Transfer</td>
                <td :style="{ color: 'red' }">4,000</td>
              </tr>
            </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  backdrop-filter: blur(3px);
  z-index: 1001;
}
.cancel_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}
.container {
  background: #f0f5ef;
  border-radius: 5px;
  width: 100%;
  height: 450px;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px 16px;
  border: 1px solid #c4dbd5;
}
h1 {
  color: #002e22;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 16px;
}
h2 {
  font-size: 32px;
  font-weight: 600;
  color: #143e32;
}
h3 {
  font-weight: 500;
  font-size: 18px;
  color: #002e22;
}
span {
  background: #008060;
  color: white;
  font-size: 12px;
  margin-right: 5px;
}
p {
  color: #355f53;
}
button {
  background-color: #008060;
  color: white;
}
button img {
  margin-right: 8px;
}
.main-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 250px;
}
.cancel img {
  width: 12px;
  height: 12px;
  background: #90a59d;
  padding: 12px;
  border-radius: 50px;
}
table{
    border: 1px solid #c4dbd5;
    border-collapse: collapse;
    border-radius: 4px;
    width: 100%;
    margin: 24px 0;
}
th {
  background: #f0f5ef;
  color: #355f53;
  font-size: 14px;
  padding: 10px;
  text-align: left;
}
td {
  font-weight: 600;
  font-size: 14px;
  color: #002e22;
  padding: 12px;
  border: 1px solid #c4dbd5;
}
</style>
