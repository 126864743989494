<script>
import cancel from '@/assets/Images/cancel.svg'
import menu from '@/assets/Images/menu.svg'

// import OrderBlocks from '@/components/Order/OrderBlocks.vue'
import GraphComponent from '@/components/GraphComponent.vue'
export default {
    data() {
        return {
            cancel,
            menu
        }
    },
    components: {
        // OrderBlocks,
        GraphComponent
    }
}
</script>
<template>
    <div class="morphism">
        <div class="header_main">
            <h1>Orders</h1>
            <div class="profile">
                <img :src='menu' alt="Menu">
            </div>

        </div>
        <div class="next_section">
            <OrderActiveHeader />
            <div class="header_link">
                <a href="#">Show more</a>
            </div>
            <!-- <OrderBlocks /> -->
        </div>
        <div class="customer_details_container">
            <div class="cancel_container">
                <div class="cancel">
                    <button @click="$router.back()">
                        <img :src='cancel' alt='cancel icon' />
                    </button>

                </div>
            </div>

            <div class="form_container">
                <div class="header">
                    <h1>Order Analytics</h1>
                </div>
                <div class="content">
                    <div><OrderBlocks /></div>
                   <div> <GraphComponent title="Order timeline" text="Last order: Dec 24," details="View Details" /></div>
                </div>
            </div>
        </div>

    </div>



</template>
<style scoped>
*,
body {
    box-sizing: border-box;
    margin: 0;
}

.morphism {

    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(79, 75, 75, 0.4);
    z-index: 1;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.header_main {
    filter: blur(7px);
    padding: 10px 16px;
    color: hsl(164, 100%, 9%);
    font-size: 18px;
    font-weight: 500;
    border-bottom: #C4DBD5 1px solid;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
    left: 0px;
    top: 0px;
    z-index: -1;
}
.profile{
    
    display: flex;
}

.next_section {
    filter: blur(7px);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header_link {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    padding: 0 8px;
    margin-bottom: 8px;
}

.header_link a {
    color: #008060;
}

.add {
    position: relative;
    right: 13px;
}


.cancel {
    padding: 6.6px;
    border-radius: 8px;
    background-color: white;
}

.cancel_container {
    margin-top: 24px;
    position: relative;
    margin-bottom: 16px;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
}

.cancel>button {
    background-color: rgb(53, 95, 83, 0.6);
    color: #355F53;
    opacity: 0.8;
    border: none;
    display: flex;
    padding: 10px;
    width: 100%;
    border-radius: 50%;
}

.header {
    border-bottom: #355F53 1px solid;
    text-align: left;
    width: 100%;
}

.header>h1 {
    margin: 16px 0px;
    margin-left: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #002E22;
    text-align: left;

}

.avatar {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 24px;
    margin-bottom: 16px;
}

.content {
    padding: 0 16px;
}
.content > div{
    margin-top: 24px;
    margin-bottom: 16px;
}

.form_container {
    overflow: scroll;
    position: relative;
    width: 100%;
    height: 60vh;
    z-index: 3;
    background: #F0F5EF;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

form {
    overflow: scroll;
    display: flex;
    margin: 0 16px;
    flex-direction: column;
}

label {
    color: #355F53;
    margin: 4px 0;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

input {
    outline: none;
    padding: 12px;
    margin: 12px 0;
    border-radius: 6px;
    border: 1px solid #C4DBD5;
}

input:focus {
    border: #355F53 1px solid;
}

input::placeholder {
    color: #92AAA3;
}

span {
    color: #92AAA3;
}

.err {
    font-size: small;
    color: red;
    margin: 12px 0 !important;
}

.error {
    border: 1px red solid;
}

.btn {
    color: white;
    padding: 12px;
    font-weight: 500;
    border-radius: 4px;
    border: none;
    margin: 24px 0px;
}
.customer_details_container {
    height: 70vh;
    position: absolute;
    width: 100%;
    bottom: 0;
    backdrop-filter: blur(5px);
}
</style>