<script>
import cancelDark from "@/assets/Images/cancelDark.svg";
import shoe from "@/assets/Images/productImg.svg";
import Checkbox from "@/components/CheckBox.vue";
export default {
  props: {
    isMarkedComplete: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      cancelDark,
      shoe,
      isChecked: false,
    };
  },
  methods: {
    closePopup() {
      this.$emit("cancel");
    },
  },
  components: {
    Checkbox,
  },
};
</script>
<template>
  <div class="modal" v-if="isMarkedComplete">
    <div class="container_holder">
      <div class="cancel_container">
        <div class="cancel">
          <img :src="cancelDark" alt="cancel" @click="closePopup" />
        </div>
      </div>
      <div class="container">
        <h1>Marked Complete</h1>

        <div class="main-text">
          <div class="warning">
            <span>Warning</span>
            <p :style="{ margin: ' 12px 0' }">
              Ensure the items have been delivered before marking as complete.
              This action can’t be reversed.
            </p>
          </div>

          <div
            :style="{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'scroll',
            }"
          >
            <div
              :style="{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '12px 0',
                alignItems: 'center',
              }"
            >
              <h2>Order items</h2>
              <p :style="{ color: '#008060' }">Mark all</p>
            </div>
            <div class="order_content">
              <div :style="{ display: 'flex' }">
                <Checkbox v-model="isChecked" />
                <div :style="{ display: 'flex', marginLeft: '12px' }">
                  <img :src="shoe" alt="shoe" />
                  <div :style="{ marginLeft: '10px' }">
                    <h2>Derby Shoes</h2>
                    <p>NGN 3000</p>
                  </div>
                </div>
              </div>
              <p
                :style="{
                  padding: '12px',
                  borderRadius: '24px',
                  background: 'white',
                  border: '1px solid #c4cdc5',
                  color: '#92AAA3',
                }"
              >
                1 unit
              </p>
            </div>
            <div class="order_content">
              <div :style="{ display: 'flex' }">
                <Checkbox v-model="isChecked" />
                <div :style="{ display: 'flex', marginLeft: '12px' }">
                  <img :src="shoe" alt="shoe" />
                  <div :style="{ marginLeft: '10px' }">
                    <h2>Derby Shoes</h2>
                    <p>NGN 3000</p>
                  </div>
                </div>
              </div>
              <p
                :style="{
                  padding: '12px',
                  borderRadius: '24px',
                  background: 'white',
                  border: '1px solid #c4cdc5',
                  color: '#92AAA3',
                }"
              >
                1 unit
              </p>
            </div>
            <div class="order_content">
              <div :style="{ display: 'flex' }">
                <Checkbox v-model="isChecked" />
                <div :style="{ display: 'flex', marginLeft: '12px' }">
                  <img :src="shoe" alt="shoe" />
                  <div :style="{ marginLeft: '10px' }">
                    <h2>Derby Shoes</h2>
                    <p>NGN 3000</p>
                  </div>
                </div>
              </div>
              <p
                :style="{
                  padding: '12px',
                  borderRadius: '24px',
                  background: 'white',
                  border: '1px solid #c4cdc5',
                  color: '#92AAA3',
                }"
              >
                1 unit
              </p>
            </div>
            <div class="order_content">
              <div :style="{ display: 'flex' }">
                <Checkbox v-model="isChecked" />
                <div :style="{ display: 'flex', marginLeft: '12px' }">
                  <img :src="shoe" alt="shoe" />
                  <div :style="{ marginLeft: '10px' }">
                    <h2>Derby Shoes</h2>
                    <p>NGN 3000</p>
                  </div>
                </div>
              </div>
              <p
                :style="{
                  padding: '12px',
                  borderRadius: '24px',
                  background: 'white',
                  border: '1px solid #c4cdc5',
                  color: '#92AAA3',
                }"
              >
                1 unit
              </p>
            </div>
            <div class="order_content">
              <div :style="{ display: 'flex' }">
                <Checkbox v-model="isChecked" />
                <div :style="{ display: 'flex', marginLeft: '12px' }">
                  <img :src="shoe" alt="shoe" />
                  <div :style="{ marginLeft: '10px' }">
                    <h2>Derby Shoes</h2>
                    <p>NGN 3000</p>
                  </div>
                </div>
              </div>
              <p
                :style="{
                  padding: '12px',
                  borderRadius: '24px',
                  background: 'white',
                  border: '1px solid #c4cdc5',
                  color: '#92AAA3',
                }"
              >
                1 unit
              </p>
            </div>
            <div class="order_content">
              <div :style="{ display: 'flex' }">
                <Checkbox v-model="isChecked" />
                <div :style="{ display: 'flex', marginLeft: '12px' }">
                  <img :src="shoe" alt="shoe" />
                  <div :style="{ marginLeft: '10px' }">
                    <h2>Derby Shoes</h2>
                    <p>NGN 3000</p>
                  </div>
                </div>
              </div>
              <p
                :style="{
                  padding: '12px',
                  borderRadius: '24px',
                  background: 'white',
                  border: '1px solid #c4cdc5',
                  color: '#92AAA3',
                }"
              >
                1 unit
              </p>
            </div>
            <div class="order_content">
              <div :style="{ display: 'flex' }">
                <Checkbox v-model="isChecked" />
                <div :style="{ display: 'flex', marginLeft: '12px' }">
                  <img :src="shoe" alt="shoe" />
                  <div :style="{ marginLeft: '10px' }">
                    <h2>Derby Shoes</h2>
                    <p>NGN 3000</p>
                  </div>
                </div>
              </div>
              <p
                :style="{
                  padding: '12px',
                  borderRadius: '24px',
                  background: 'white',
                  border: '1px solid #c4cdc5',
                  color: '#92AAA3',
                }"
              >
                1 unit
              </p>
            </div>
            <div class="order_content">
              <div :style="{ display: 'flex' }">
                <Checkbox v-model="isChecked" />
                <div :style="{ display: 'flex', marginLeft: '12px' }">
                  <img :src="shoe" alt="shoe" />
                  <div :style="{ marginLeft: '10px' }">
                    <h2>Derby Shoes</h2>
                    <p>NGN 3000</p>
                  </div>
                </div>
              </div>
              <p
                :style="{
                  padding: '12px',
                  borderRadius: '24px',
                  background: 'white',
                  border: '1px solid #c4cdc5',
                  color: '#92AAA3',
                }"
              >
                1 unit
              </p>
              
            </div>
<div class="order_content">
    <button>Save</button>
</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  backdrop-filter: blur(3px);
  z-index: 1001;
}
.cancel_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}
.container {
  background: #f0f5ef;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  height: 95%;
  overflow-y: scroll;
}
.container_holder {
  height: 85%;
}
.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px 16px;
  border: 1px solid #c4dbd5;
}
h1 {
  color: #002e22;
  font-size: 18px;
  font-weight: 500;
  padding: 20px 16px;
  border: 1px solid #c4dbd5;
}
h2 {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #143e32;
}

span {
  background: #f9f3db;
  color: #a75a00;
  padding: 8px;
  font-size: 12px;
  margin-right: 5px;
  border-radius: 24px;
}
p {
  color: #355f53;
}
button {
  background-color: #008060;
  color: white;
  border: none;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 12px;
}
.main-text {
  display: flex;
  padding: 16px;
  flex-direction: column;
}
.cancel img {
  width: 12px;
  height: 12px;
  background: #90a59d;
  padding: 12px;
  border-radius: 50px;
}
.warning {
  background: white;
  border-radius: 8px;
  color: #008060;
  margin-bottom: 12px;
  padding: 12px;
}

.order_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
}
</style>
