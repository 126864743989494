<script>
export default {
  name: "BestSeller",
  props: ["best"],
  data() {
    return {
      baseUrl: "",
    };
  },
  mounted() {
    this.baseUrl = window.location.origin;
  },
};
</script>
<template>
  <div>
    <div v-for="(item, i) in best" :key="i" class="customer-cards">
      <div v-if="item.product_image" class="card_img">
        <img
          v-if="baseUrl == 'http://127.0.0.1:8080'"
          :src="'http://127.0.0.1:8000' + item.product_image"
          alt="product image" class="product_img"
        />
        <img v-else :src="item.product_image" alt="product image" class="product_img" />
      </div>
      <div v-else class="card_img">
        <img src="@/assets/Images/default_product_image.png" alt="img" class="product_img" />
      </div>
      <div class="second_section">
        <div v-if="item.product_name" class="first_holder">
          <h1>{{ item.product_name }}</h1>
          <p>{{ item.percent }}% of sales</p>
        </div>
        <div v-else class="first_holder">
          <h1>Product deleted</h1>
          <p>{{ item.percent }}% of sales</p>
        </div>
        <div v-if="item.price" class="second_holder">
          <p>{{ item.price.toLocaleString() }}</p>
          <span class="orders"> {{ item.count }} items </span>
        </div>
        <div v-else class="second_holder">
          <p>--</p>
          <span class="orders"> {{ item.count }} items </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.customer-cards {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin: 8px 0px;
  background: #F5FCF4;
  border: #c4dbd5 0.5px solid;
}
.card_img {
  padding: 12px 0px;
}
.card_img > img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  border-radius: 5px;
}


.second_section {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.first_holder,
.second_holder {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.first_holder > h1 {
  font-size: 14px;
  font-weight: 400;
  color: #355f53;
}
.first_holder > p {
  color: #355f53;
  font-size: 14px;
}
.second_holder > .orders {
  color: #a75a00;
  background-color: #f9f3db;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 24px;
}
.second_holder > p {
  font-weight: 500;
  color: #002e22;
}
.phone-number {
  display: flex;
  font-size: 14px;
  color: #355f53;
}

.phone-number > p {
  margin-right: 4px;
}
</style>
