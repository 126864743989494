<script>
import returnHome from "@/assets/Images/returnHome.svg";
import arrowRight from "@/assets/Images/arrowRight.svg";
const Group = [
  // {
  // 	name:'Continue Editing',
  // 	img: require('@/assets/Images/basketIcon.svg'),
  // 	route: '/product_form'
  // },
  // {
  // 	name:'View product details',
  // 	img: require('@/assets/Images/basketIcon.svg'),
  // 	route: '/product_details'
  // },
  // {
  // 	name: 'Add another product',
  // 	img: require('@/assets/Images/script.svg'),
  // 	route:'/product_form'
  // },
  {
    // name:'View all products',
    name: "Ok",
    img: require("@/assets/Images/link.svg"),
    route: "/inventory",
  },
];

import * as mutationTypes from "@/store/mutationTypes";

export default {
  name: "ProductAdded",
  data() {
    return {
      returnHome,
      Group,
      arrowRight,
    };
  },
  mounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false);
  },
  unmounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true);
  },
};
</script>

<template>
  <!-- <div class="header_main">
		<img :src="returnHome" alt="return home arrow" class="arr" @click="$router.back()" />
		<p>Summary</p>
	</div> -->
  <div class="record">
    <div>
      <h1>Your product has been added</h1>
      <div class="group">
        <div v-for="i in Group" :key="i">
          <RouterLink :to="i.route" replace class="inner">
            <img :src="i.img" alt="{{ i.name }} Icon" />
            <span class="name"> {{ i.name }} </span>
          </RouterLink>
          <img :src="arrowRight" alt="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
a {
  text-decoration: none;
}
.header_main {
  padding: 10px;
  border-bottom: 1px solid #c4dbd5;
  display: flex;
  align-items: center;
}
.arr {
  padding: 4px;
  background-color: rgba(53, 95, 83, 0.5);
  border-radius: 4px;
  margin: 0 8px;
}
button {
  background: #008060;
  border: none;
  border-radius: 4px;
}

button > a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

button > a > img {
  margin: 0 4px;
}

.btn {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.btn > button {
  width: 95%;
  padding: 12.5px 0;
}
.record {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.record > div {
  width: 90%;
}
h1 {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  color: #002e22;
}
.group {
  display: flex;
  flex-direction: column;
  background-color: #f5fcf4;
  border: #c4dbd5 1px solid;
  border-radius: 8px;
  margin-top: 21px;
  width: 100%;
}
.group > div {
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-bottom: #c4dbd5 1px solid;
}
.inner {
  display: flex;
  align-items: center;
  color: #355f53;
}
.name {
  margin-left: 12px;
}
</style>
