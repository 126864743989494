<script>

import HomeIcon from '../assets/Images/footerIcons/HomeIcon.svg'
import Inventory from '../assets/Images/footerIcons/Inventory.svg'
import OrderIcon from '../assets/Images/footerIcons/OrderIcon.svg'
import Customer from '../assets/Images/footerIcons/Customer.svg'
import ExpenseIcon from '../assets/Images/footerIcons/ExpenseIcon.svg'

import { mapGetters } from "vuex"
import * as mutationTypes from "@/store/mutationTypes"

const FooterArray = [
    {
        id: 1,
        name: 'Dashboard',
        Image: require('../assets/Images/footerIcons/HomeIcon.svg'),
        colored: require('../assets/Images/footerIcons/homeActive.svg'),
        route:'/dashboard'
    }, {
        id: 2,
        name: 'Inventory',
        Image: require('../assets/Images/footerIcons/Inventory.svg'),
        colored: require('../assets/Images/footerIcons/inventoryActive.svg'),
        route:'/inventory'
    }, {
        id: 3,
        name: 'Orders',
        Image: require('../assets/Images/footerIcons/OrderIcon.svg'),
        colored: require('../assets/Images/footerIcons/orderActive.svg'),
        route:'/orders'
    }, {
        id: 4,
        name: 'Customers',
        Image: require('../assets/Images/footerIcons/Customer.svg'),
        colored: require('../assets/Images/footerIcons/customerActive.svg'),
        route:'/customers/home'
    }, 
		{
        id: 5,
        name: 'Reports',
        Image: require('../assets/Images/footerIcons/ExpenseIcon.svg'),
        colored: require('../assets/Images/footerIcons/expenseActive.svg'),
        route:'/reports'
    }
]
export default {
	name: 'CustomerHeader',
	data() {
		return {
			FooterArray,
			HomeIcon,
			Inventory,
			OrderIcon,
			Customer,
			activeIcon: null,
			nav_active: false,
			ExpenseIcon,
			isOpen: false,
		}
	},
	methods: {
		selectIcon(index) {
			this.activeIcon = index;
			this.$store.commit(mutationTypes.SET_NAV_POSITION, index)
		},
	},
	computed: {
		...mapGetters({
			nav: "getNavPosition"
		})
	},
	mounted() {
		this.activeIcon = this.nav
	}
}
</script>

<template>
	<footer>
		<RouterLink   v-for="(i, index) in FooterArray" :key=(index) class="footer" @click="selectIcon(index)"
			:class="{ active: index === activeIcon  }" :to="i.route">
			<img :src="index === activeIcon ? i.colored : i.Image " :alt="i.name" >
			<small>{{ i.name }}</small>
		</RouterLink>
	</footer>
</template>

<style scoped>
.colored {
	color: black;
	fill: #008060;
}
a{
	text-decoration: none;
}
#filler {
	fill: #355F53;
}
.noColor {
	background-color: #C4DBD5;

}
footer {
	position: fixed;
	border-top: 1px solid rgba(196, 219, 213, 1);
	bottom: 0;
	left: 0;
	z-index: 1000;
	padding: 8px 0 0;
	display: flex;
	width: 100%;
	border-top: 1px solid rgb(196, 219, 213);
	background: #F0F5EF;
}
.footer {
	color: #92AAA1;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.footer>img {
	width: 24px;
	height: 24px;
}

.active{
	padding-bottom:8px;
}
.active small{
	transition: ease-in;
color: #008060;
}
</style> 


   