<script>
import CustomHeader from "../CustomHeader.vue";
import ButtonComponent from '../ButtonComponent.vue'
const PricingArray = [
    {
        title: 'Starters plan',
        price: 'NGN 0',
        subText: 'upload up to 10 products',
    },
    {
        title: 'Basic plan',
        price: 'NGN 2500',
        subText: 'upload up to 100 products',
    },
    {
        title: 'Pro plan',
        price: 'NGN 5000',
        subText: 'upload up to 1000 products',
    }
]
const PlanArray = [
    {
        img: require('@/assets/Images/copyIconGray.svg'),
        text: 'Status',
        state: 'Active',
    },
    {
        img: require('@/assets/Images/copyIconGray.svg'),
        text: 'Interval',
        period: 'Monthly',
    },
    {
        img: require('@/assets/Images/calendar.svg'),
        text: 'Last payment date',
        date: 'Mar 24 2024',
    },
    {
        img: require('@/assets/Images/calendar.svg'),
        text: 'Next payment date',
        date: 'Apr 24 2024',
    }
]
export default {
    components: {
        CustomHeader,
        ButtonComponent

    },
    data() {
        return {
            PricingArray,
            PlanArray
        }
    }
}
</script>
<template>
    <CustomHeader text="My Profile" />
    <div class="header">
        <ul>
            <li><RouterLink to="/profilePage" class="li">Basic Details</RouterLink></li>
            <li class="main_page">Subscription</li>
            <li><RouterLink to="/paymentProfile">Payment</RouterLink></li>
        </ul>
        <h1>Basic Details</h1>
    </div>

    <div class="card" v-for="card, index in PricingArray" :key="index">
        <div class="top">
            <h1>{{ card.title }}</h1>
            <p>{{ card.price }}</p>
            <sub>{{ card.subText }}</sub>
        </div>
        <div class="btm">
            <a href="/">Plans features</a>
            <button>Select plan</button>
        </div>
    </div>
<div class="basic_wrapper">
    <h1>Basic</h1>
    <div  v-for="info, index in PlanArray" :key="index" >
       
        <div class="plan_info">
            <div>
                <img :src=info.img alt="icon">
                <h3>{{info.text}}</h3>
            </div>
            <span v-if=" info.state" class="state">{{ info.state }}</span>
            <h4 v-if="info.date" class="date">{{ info.date }}</h4>
            <h4 v-if="info.period" class="date">{{ info.period }}</h4>
        </div>

    </div>
</div>
    
    <ButtonComponent>Change Plan</ButtonComponent>
</template>



<style scoped>
.header {
    margin: 0 16px;
}

.last_section {
    display: flex;
    justify-content: end;
    margin: 0 16px;
}

a {
    text-decoration: none;
    color: #355F53;
}

ul {
    border-bottom: 1px solid #C4DBD5;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 16px 0;
}

li, .li {
    padding: 8px;
    color:#92AAA3;
}

h1 {
    font-size: 14px;
    font-weight: 500;
    color: #002E22;
    margin-top: 24px;
}

.main_page {
    color: #008060;
    border-bottom: #008060 1px solid;
}

.card {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    border: #C4DBD5 1px solid;
    border-radius: 8px;
    margin: 8px 16px;
    background-color: #F5FCF4;

}

p {
    font-size: 24px;
    font-weight: 500;
    color: #002E22;
    margin-top: 16px;
}

sub {
    font-weight: 400;
    margin-top: 4px;
    font-family: 14px;
    color: #92AAA3;

}

.top {
    border-bottom: #92AAA3;
}

.btm {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
}

button {
    color: white;
    font-weight: 500;
    background-color: #008060;
    border-radius: 4px;
    border: none;
    padding: 8px 12px;
}

a {
    color: #008060;
}
h3{
    font-size: 16px;
    margin-left: 10px;
    font-weight: 400;
    color: #355F53;

}
.plan_info{
    display: flex;
    margin: 8px 0;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
}
.plan_info > div{
        display: flex;
}
.basic_wrapper{
margin: 0 16px;
}
.date{
    font-weight: 500;
    font-size: 16px;
    color:#002E22;
}
.state{
    color:#92AAA3;
    background: #F5FCF4;
    padding: 4px 12px;
    border-radius: 24px;
   

}
</style>