<template>
  <router-view />
  <AppNav v-if="display" /> <!-- v-if="logged_in" -->
</template>

<script>
import AppNav from '@/components/AppNav.vue'
import { mapGetters } from 'vuex';

export default {
  components: {
    AppNav
  },
  computed: {
		...mapGetters({
			display: 'getAppNavDisplay',
		})
  },
  mounted() {

  }
}
</script>

<style>
* {
  /* box-sizing: border-box; */
}
html {
  scroll-behavior: smooth;
}
body{
  background: #F0F5EF;
}
@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Fonts/AeonikTRIAL-Regular.otf'); 
}
#app {
  font-family: 'Aeonik', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}
nav {
  padding: 30px;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
ul {
  list-style-type: none;
}
</style>
