<!-- eslint-disable vue/no-parsing-error -->
<script>
import returnHome from "@/assets/Images/returnHome.svg";
import card1 from "@/assets/Images/card1.svg";
import pen from '@/assets/Images/pen.svg';
import refund from '@/assets/Images/refund.svg';
import naira from '@/assets/Images/naira.svg';
import sourceIcon from '@/assets/Images/sourceIcon.svg';
import truckIcon from '@/assets/Images/truckIcon.svg';
import linkIcon from '@/assets/Images/linkIcon.svg';
import sheet from '@/assets/Images/sheet.svg';
import copy from '@/assets/Images/copy.svg';
import arrow from '@/assets/Images/arrow.svg';
import profile from '@/assets/Images/profile.svg';
import phoneIcon from '@/assets/Images/phoneIcon.svg';
import address from '@/assets/Images/address.svg'
import CustomHeader from '@/components/CustomHeader.vue'

const Details = [
    {
        name: 'Paymet Status',
        Image: require('@/assets/Images/card.svg'),
        status: 'Paid'
    },
    {

        name: 'Order status',
        Image: require('@/assets/Images/basket.svg'),
        status: 'Ongoing'
    },
    {
        name: 'Order number',
        Image: require('@/assets/Images/box.svg'),
        status: '#16968'

    }, {
        id: 4,
        name: 'Date of Birth',
        Image: require('@/assets/Images/calendar.svg'),
        status: 'Sun, Mar 24 2024'
    },

]
const Products = [
    {
        productName: "Derby shoes",
        productPrice: 3000,
        productQty: '1 unit',
        productImg: require('@/assets/Images/imgAvatar.svg')
    },
    {
        productName: "Derby shoes",
        productPrice: 3000,
        productQty: '1 unit',
        productImg: require('@/assets/Images/imgAvatar.svg')
    }
]
export default {
    data() {
        return {
            returnHome,
            card1,
            Details,
            naira,
            sourceIcon,
            truckIcon,
            Products,
            refund,
            pen,
            linkIcon,
            copy,
            sheet,
            arrow,
            profile,
            address,
            phoneIcon,
            showDeliveryDetails: false,
        }
    },
    methods: {
        getClass(status) {
            return {
                Paid: 'paid',
                Ongoing: 'ongoing'
            }[status]
        },
        toggleDeliveryDetails() {
      this.showDeliveryDetails = !this.showDeliveryDetails;
    }
    },
    computed: {
        total() {
            return this.Products.reduce((acc, a) => acc + a.productPrice, 0)
        }
    },
    components:{
        CustomHeader
    }
}
</script>
<template>
  
  <CustomHeader text="Order Details"/>
    <div class="container">
        <div class="content">
            <h2 class="dets">Customer details</h2>
            <div class="section ">
                <div class="one">
                    <img :src="card1" alt="imgavatar" class="customers_avatar">
                    <div class="info">
                        <h1>Debola Adeola</h1>
                        <p>09014567839 </p>
                    </div>
                </div>
                <span class="profile_span">View profile</span>

            </div>
            <div class="section content_header">
                <h2>Order Details</h2>
                <div class="buttons">
                    <button><img :src="pen" alt="pen">Edit</button><button><img :src="refund"
                            alt="recyle icon">Refund</button>
                </div>
            </div>
            <div class="section two">
                <div v-for="i in Details" :key="i" class="details_list">
                    <div>
                        <img :src='i.Image' alt="icon imges">
                        <h3>{{ i.name }}</h3>
                    </div>
                    <span :class="getClass(i.status)">{{ i.status }}</span>


                </div>
            </div>
            <div class="products_list section">
                <div class="products_container " v-for="a in Products" :key="a">
                    <div class="products_content">
                        <img :src="a.productImg" alt="productImg">
                        <div>
                            <h2>{{ a.productName }}</h2>
                            <p>N{{ a.productPrice }}</p>
                        </div>

                    </div>
                    <span>1 unit</span>
                </div>

            </div>
            <div class="total section">
                <div>
                    <img :src="naira" alt="naira notation" />
                    <p>Total Amount</p>
                </div>
                <p class="total_cost"> NGN {{ total }}</p>
            </div>
            <div class="section total">
                <div>
                    <img :src="sourceIcon" alt="">
                    <h3>Source channel</h3>

                </div>
                <span>Whatsapp</span>
            </div>
            <div class=" section total">
                <div @click="toggleDeliveryDetails">
                    <img :src="truckIcon" alt="">
                    <h3>Delivery method</h3>
                </div>
                <span>Dispatch</span>
            </div>
            <div v-if="showDeliveryDetails">
                <div class="total">
                    <div><img :src="profile" alt="profile Icon">
                        <h3>Contact name</h3>
                    </div>
                    <span>Adebola adeola</span>

                </div>
                <div class="total">
                    <div><img :src="phoneIcon" alt="phoneIcon">
                        <h3>Phone number</h3>
                    </div>
                    <span>08082287122</span>
                </div>
                <div class="total">
                    <div><img :src="address" alt="addressICon">
                        <h3>Address</h3>
                    </div>
                    <span>Ikeja GRA, Lagos</span>
                </div>
            </div>
            <div class="receipt  ">
                <h2>Receipt </h2>
                <div class="total">
                    <div><img :src="linkIcon" alt="receipt" class="icon">
                        <p>Receipt link</p>
                    </div>
                    <a href="">Copy link <img :src="copy" alt="copy"></a>
                </div>
                <div class="total">
                    <div><img :src="sheet" alt="sheet of paper icon" class="icon">
                        <p>View & Share receipt</p>
                    </div>
                    <div class="arro">
                        <a href="">Open Receipt </a><img :src="arrow" alt="arrow">
                    </div>
                </div>
            </div>
            <div class="btn">
                <button>
                    <RouterLink to="/customerDetails">Share product link </RouterLink>
                </button>
            </div>
        </div>
    </div>

</template>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.content {
    width: 95%;
}

.arr {
    padding: 4px;
    background-color: rgba(53, 95, 83, 0.5);
    border-radius: 4px;
    margin: 0 8px;
}

h1 {
    font-weight: 500;
    font-size: 18px;
    color: rgba(0, 46, 34, 1);

}

h2 {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 46, 34, 1);
}

.section {
    display: flex;
    justify-content: space-between;
}

.dets {
    margin: 16px 0;
}

.customers_avatar {
    margin-right: 12px;
}

.one {
    display: flex;
    align-items: center;

}

.info>h1 {
    margin-bottom: 4px;
}

.info>p {
    font-size: 14px;
    line-height: 20px;
    color: rgba(53, 95, 83, 1);
}

.section {
    border-bottom: 1px rgba(196, 219, 213, 1) solid;
    padding-bottom: 16px;
}

.profile_span {
    background-color: rgba(0, 128, 96, 0.1);
    padding: 8px 12px;
    border-radius: 24px;
    color: rgba(0, 128, 96, 1);
    display: flex;
    align-items: center;
}

.content_header {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
}

.buttons {

    display: flex;
}

.buttons>button {

    background: rgba(245, 252, 244, 1);
    border: rgba(196, 219, 213, 1) 1px solid;
    padding: 6px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: rgba(0, 128, 96, 1);
}

.buttons :first-child {
    margin-right: 12px;
}

.buttons>button>img {
    margin-right: 4px;
}

.two {
    display: flex;
    flex-direction: column;
}

.details_list {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.details_list>div {
    padding: 12px 8px;
    display: flex;

}

.details_list>span {
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 46, 34, 1)
}

h3 {
    font-size: 16px;
    font-weight: 400;
    margin-left: 8px;
    color:
        rgba(53, 95, 83, 1)
}

.paid {
    color: gray !important;
    background-color: white;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 24px;
    border: 1px solid rgba(196, 219, 213, 1);
}

.ongoing {
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 24px;

    background-color: rgba(243, 235, 204, 1);
    color: rgba(167, 90, 0, 1) !important;
}

.products_list {
    display: flex;
    flex-direction: column;

}

.products_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.products_content {
    display: flex;
    margin-top: 16px;
    align-items: center;
    margin-bottom: 12px;
}

.products_content>div>p {
    font-size: 14px;
    color: rgba(20, 62, 50, 1);
}

.products_content>img {
    width: 48px;
    height: 48px;
    margin-right: 8px;
}

.products_container>span {
    color: gray !important;
    background-color: white;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 24px;
    border: 1px solid rgba(196, 219, 213, 1)
}

.total {
    display: flex;
    justify-content: space-between;
    padding: 20px 8px;
    align-items: center;
}

.total>div {
    display: flex;

}

p {
    color: rgba(53, 95, 83, 1);

}

.total>div>p {
    margin-left: 8px;
}
.total >span{
    font-weight: 500;
    color: rgba(0, 46, 34, 1);
font-size: 16px;
}
.total_cost {
    color: rgba(0, 46, 34, 1);
}

.receipt {
    display: flex;
    flex-direction: column;
}

a {
    color: rgb(0, 128, 96);
}

.arro {
    display: flex;
    align-items: center;
}

button {

    background: #008060;
    border: none;
    border-radius: 4px;

}

button>a {
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}

button>a>img {
    margin: 0 4px;
}

.btn {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.btn>button {
    width: 95%;
    padding: 12.5px 0;
}
</style>