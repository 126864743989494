<script>
import CustomHomeHeader from "../CustomHomeHeader.vue";
import SummaryBlocks from "../SummaryBlocks.vue";
import plusWhite from "@/assets/Images/plusWhite.svg";
import hamMenu from "@/assets/Images/hamMenu.svg";
import phoneIcon from "@/assets/Images/phoneIcon.svg";
import pen from "@/assets/Images/pen.svg";
import bin from "@/assets/Images/dustbin.svg";
import SearchBar from "@/components/SearchBar.vue";
import { mapGetters } from "vuex";
import { fetchCustomerStats } from "@/services/apiServices"
import * as mutationTypes from "@/store/mutationTypes";
import DeleteConfirmationPopUp from "@/components/DeleteConfirmationPopUp.vue";
export default {
  name: "CustomerList",
  components: {
    CustomHomeHeader,
    SummaryBlocks,
    SearchBar,
    DeleteConfirmationPopUp,
  },
  data() {
    return {
      customer_id: 0,
      plusWhite,
      hamMenu,
      phoneIcon,
      pen,
      bin,
      visible: false,
      visiblePopUp: false,
      input: "",
      drpdown: null,
      showOverlay: false,

      utc1: null,
      ms_per_day: null,
    };
  },
  methods: {
    checkout() {
      if (this.customer_id != 0) {
        this.$store.commit(
          mutationTypes.SAVE_SELECTED_CUSTOMER,
          this.customer_id
        );
        this.$router.push({ name: "Checkout" });
      } else {
        alert("Please select a customer");
      }
    },
    customerDetails(id, customer) {
      // get customer details from server
      fetchCustomerStats(id)
        .then(res => {
          this.$store.commit(mutationTypes.SET_CUSTOMER_TO_EDIT, customer);
          this.$store.commit(mutationTypes.SET_CUSTOMER_STATS, res.data);
        }).finally(
          this.$router.push({ name: "Customer Details" })
        )
    },
    editCustomer(customer) {
      this.$store.commit(mutationTypes.SET_CUSTOMER_TO_EDIT, customer); // should still be there
      this.$router.push({ name: "Customer Edit Form" });
    },
    selectCustomer(id, customer) {
      if (this.origin == "home") {
        this.customerDetails(id, customer);
      } else {
        this.toggleCustomer(id);
      }
    },
    toggleCustomer(id) {
      switch (this.customer_id) {
        case 0:
          this.customer_id = id;
          break;
        case id:
          this.customer_id = 0;
          break;
        default:
          this.customer_id = id;
      }
    },
    toggleDropdown(index) {
      this.drpdown = this.drpdown === index ? null : index;
    },
    closeAllDropdowns() {
      this.drpdown = null;
    },
    handleOverlay() {
      this.showOverlay = true;
      this.overlayMessage = "product";
    },
    handleConfirm() {
      this.showOverlay = false;
    },
    handleCancel() {
      this.showOverlay = false;
    },
    thousands_separators(price){
      let num_parts = price.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    }
  },
  computed: {
    ...mapGetters({
      customers: "getCustomers",
    }),
    origin() {
      return this.$route.params.origin;
    },
    stats() {
      let plural;
      this.customers.length > 1 ? (plural = "s") : (plural = "");
      return [
        {
          stat: "Customer" + plural,
          value: this.customers.length + " total"
        },
        {
          stat: "Last 30 days",
          value: this.activeCustomers + " active"
        },
        {
          stat: "Avg spend", // indicate average uses active users
          value: "₦ " + this.thousands_separators(this.averageActiveSpend)
        },
        {
          stat: "Customers",
          value: this.activeRepeatCustomers + " new"
        },
      ];
    },
    activeCustomers() {
      let count = 0
      for (let i=0; i < this.customers.length; i++) {
        try {
          let purchase_year = parseInt(this.customers[i].last_purchase.substring(0, 4))
          let purchase_month = parseInt(this.customers[i].last_purchase.substring(5, 7)) - 1
          let purchase_day = parseInt(this.customers[i].last_purchase.substring(8, 10))

          let utc2 = Date.UTC(purchase_year, purchase_month, purchase_day)
          let difference = Math.floor((this.utc1 - utc2) / this.ms_per_day)

          difference < 30 ? count ++ : ""
        } catch { null }
      }
      return count
    },
    activeRepeatCustomers() {
      let count = 0
      let active = []
      // let repeat = []
      for (let i=0; i < this.customers.length; i++) {
        try {
          let purchase_year = parseInt(this.customers[i].last_purchase.substring(0, 4))
          let purchase_month = parseInt(this.customers[i].last_purchase.substring(5, 7)) - 1
          let purchase_day = parseInt(this.customers[i].last_purchase.substring(8, 10))

          let utc2 = Date.UTC(purchase_year, purchase_month, purchase_day)
          let difference = Math.floor((this.utc1 - utc2) / this.ms_per_day)
          
          difference < 30 ? active.push(this.customers[i]) : ""
        } catch { null }
      }

      for (let i=0; i < active.length ; i++) {
        active[i].lifetime_orders == 1 ? count ++ : ""
      }
      return count
    },
    averageActiveSpend() {
      let total_spent = 0
      let active = []
      // let repeat = []
      for (let i=0; i < this.customers.length; i++) {
        try {
          let purchase_year = parseInt(this.customers[i].last_purchase.substring(0, 4))
          let purchase_month = parseInt(this.customers[i].last_purchase.substring(5, 7)) - 1
          let purchase_day = parseInt(this.customers[i].last_purchase.substring(8, 10))

          let utc2 = Date.UTC(purchase_year, purchase_month, purchase_day)
          let difference = Math.floor((this.utc1 - utc2) / this.ms_per_day)
          
          difference < 30 ? active.push(this.customers[i]) : ""
        } catch { null }
      }

      for (let i=0; i < active.length ; i++) {
        total_spent += active[i].net_spend
      }
      return parseFloat(total_spent / active.length).toFixed()
    },
    filteredList() {
      return this.customers.filter(
        (customer) =>
          customer.first_name
            .toLowerCase()
            .includes(this.input.toLowerCase()) ||
          customer.last_name.toLowerCase().includes(this.input.toLowerCase()) ||
          customer.phone.toLowerCase().includes(this.input.toLowerCase())
      );
    },
  },
  mounted() {
    this.ms_per_day = 1000 * 60 * 60 * 24
    const today = new Date();
    this.this_day = today.getDay(); // position in week
        
    this.utc1 = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    )

    this.$store.commit(mutationTypes.SET_CUSTOMER_TO_EDIT, {})
    if (this.origin == "cart") {
      this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false);
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<template>
  <div class="container">
    <CustomHomeHeader text="Customers" link="" />
    <div class="card_container">
      <div class="main" @click="closeAllDropdowns">
        <SummaryBlocks :stats="stats" />
        <div class="semi-header">
          <SearchBar v-model="input" placeholder="Search for customers..." />

          <button class="add_cust">
            <RouterLink :to="'/customer_form/' + origin">
              <img :src="plusWhite" alt="a green plus icon" /> Add Customer
            </RouterLink>
          </button>
        </div>
        <div
          v-for="(customer, index) in filteredList"
          :key="index"
          class="customer-cards"
          :class="{ active: customer_id == customer.id }"
        >
          <img
            :src="require('@/assets/Images/card1.svg')"
            alt="customer's img or avatar"
          />
          <div class="second_section">
            <div class="first_holder">
              <h1 @click="selectCustomer(customer.id, customer)">
                {{ customer.first_name }} {{ customer.last_name }}
              </h1>
              <img
                :src="hamMenu"
                alt="hamMenu"
                @click.stop="toggleDropdown(index)"
              />
            </div>
            <div class="second_holder">
              <div class="phone-number">
                <p>{{ customer.phone }}</p>
                <img
                  v-if="customer.phone != ''"
                  :src="phoneIcon"
                  alt="phoneIcon"
                  @click="console.log('call', customer.phone)"
                />
              </div>
              <span v-if="customer.lifetime_orders > 0" class="orders pill">
                {{ customer.lifetime_orders }} order <span v-if="customer.lifetime_orders > 1">s</span> 
              </span>
            </div>
            <div v-if="drpdown === index" class="options">
              <p @click="editCustomer(customer)" class="truck">
                <img :src="pen" alt="edit pen icon" /> Edit Customer
              </p>
              <p v-if="customerlifetime_orders < 1" @click="handleOverlay" class="cancel">
                <img :src="bin" alt="trash icon" /> Delete customer
              </p>
            </div>
          </div>
        </div>
        <DeleteConfirmationPopUp
          :visiblePopUp="showOverlay"
          :message="overlayMessage"
          @confirm="handleConfirm"
          @cancel="handleCancel"
        />
        <div v-if="input && filteredList.length === 0" class="item error">
          <p>No results found!</p>
        </div>
      </div>
    </div>
  </div>
  <div v-if="origin == 'cart'" class="btn">
    <button @click="checkout()">Go to checkout</button>
  </div>
  <div style="display: block; height: 35px"><!--props page up--></div>
</template>

<style scoped>
.container {
  color: #002e22;
  width: 100%;
}
.main {
  width: 100%;
}
.semi-header {
  display: flex;
  margin: 24px 0px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card_container {
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.pill {
  display: flex;
  padding: 4px;
  background: white;
  border: 1px solid #c4dbd5;
  border-radius: 4px;
  margin: 0 12px;
}

button {
  color: white;
  font-size: 14px;
  background: #008060;
  border-radius: 4px;
  border: none;
}
.add_cust {
  padding: 6px 8px;
}

a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
}

button > a > img {
  margin: 0px 4px;
}

.customer-cards {
  width: 100%;
  border-radius: 8px;

  display: flex;
  padding: 14px 0px;
  justify-content: center;
  margin: 8px 0px;
  background: #f5fcf4;
  border: #c4dbd5 0.5px solid;
}
.active {
  border: 1px solid #4caf50;
}
.customer-cards > img {
  width: 52px;
  height: 52px;
  object-fit: contain;
}

.second_section {
  width: 70%;
  margin-left: 12px;

  display: flex;
  flex-direction: column;
}

.first_holder,
.second_holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.first_holder {
  margin-bottom: 8px;
}

.first_holder > h1 {
  font-size: 16px;
  color: #002e22;
}

.orders {
  background-color: #f9f3db;
  font-size: 14px;
  color: #a75a00;
  margin: 0;
  padding: 4px 8px;
  border-radius: 24px;
  border: none;
}

.phone-number {
  display: flex;
  font-size: 14px;
  color: #355f53;
}

.phone-number > p {
  margin-right: 4px;
}
.btn {
  margin-bottom: 15px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn > button {
  width: 100%;
  margin: 0 16px;
  padding: 12.5px 0;
}
.options {
  position: absolute;
  border-radius: 8px;
  right: 0;
  padding: 12px;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 4px 16px 0px #d1e4de80;
  margin-right: 10px;
}
.options p {
  padding: 8px;
  text-decoration: none;
  color: #355f53;
}
.options p > img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.truck {
  border-bottom: #c4dbd5 0.3px solid;
  justify-content: flex-start;
}
</style>
