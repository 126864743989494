<script>
import CustomerHistory from "@/components/Customer/CustomerHistory.vue";
import returnHome from "@/assets/Images/returnHome.svg";
import card1 from "@/assets/Images/card1.svg";
import editWhite from "@/assets/Images/editPenWhite.svg";
import purchaseIcon from "@/assets/Images/purchaseIcon.svg";
import ordersIcon from "@/assets/Images/ordersIcon.svg";
import buying from "@/assets/Images/buying.svg";
import returns from "@/assets/Images/returns.svg";
import phoneIcon from "@/assets/Images/phoneIcon.svg";
import cake from "@/assets/Images/cake.svg";
import address from "@/assets/Images/address.svg";
import profile from "@/assets/Images/profile.svg";
import email from "@/assets/Images/email.svg";
import CustomHeader from "@/components/CustomHeader.vue";
import SummaryBlocks from "@/components/SummaryBlocks.vue";
import arr from "@/assets/Images/arr.svg";
import box from "@/assets/Images/box.svg";

import { mapGetters } from "vuex";
import 
// saveCustomer,
// updateCustomer
"@/services/apiServices";
import * as mutationTypes from "@/store/mutationTypes";
import { customers } from '@/store/state'

export default {
  name: "CustomerDetails",
  components: {
    CustomerHistory,
    CustomHeader,
    SummaryBlocks,
  },
  data() {
    return {
      returnHome,
      card1,
      editWhite,
      purchaseIcon,
      ordersIcon,
      buying,
      returns,
      arr,
      box,
      cake,
      profile,
      address,
      email,
      phoneIcon,
      CustomersArray: customers,
    };
  },
  methods: {
    editCustomer() {
      this.$store.commit(mutationTypes.SET_CUSTOMER_TO_EDIT, this.customer); // should still be there
      this.$router.push({ name: "Customer Edit Form" });
    },
  },
  computed: {
    ...mapGetters({
      customer: "getCustomerToEdit",
      customerStats: "getCustomerStats"
    }),
    stats() {
      let plural;
      this.customerStats.orders.length > 1 ? (plural = "s") : (plural = "");
      return [
        {
          stat: "Order" + plural,
          value: this.customerStats.orders.length + " total"
        },
        {
          stat: "Total spend",
          value: "₦ " + this.customerStats.total_spend.toLocaleString()
        },
        {
          stat: "Returns",
          value: "0"
        },
        {
          stat: "Last purchase",
          value: this.order_date
        },
      ];
    },
    order_date() {
      const ms_per_day = 1000 * 60 * 60 * 24;
      let months = [
        "Jan ",
        "Feb ",
        "Mar ",
        "Apr ",
        "May ",
        "Jun ",
        "Jul ",
        "Aug ",
        "Sep ",
        "Oct ",
        "Nov ",
        "Dec ",
      ];
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const ty = ["Today", "Yesterday"];
      let last_date = this.customerStats.orders[this.customerStats.orders.length -1].order_date
      let order_year = parseInt(last_date.substring(0, 4));
      let order_month = parseInt(last_date.substring(5, 7)) - 1;
      let order_date = parseInt(last_date.substring(8, 10));

      const today = new Date();
      const this_day = today.getDay(); // position in week
      const utc1 = Date.UTC(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const utc2 = Date.UTC(order_year, order_month, order_date);
      let difference = Math.floor((utc1 - utc2) / ms_per_day);
      if (difference > 6) {
        return months[order_month] + " " + order_date;
      } else if (difference > 1) {
        if (this_day - difference < 0) {
          //  months[order_month] +  " - " +
          return months[order_month] + " " + order_date;
        }
        return (
          days[this_day - difference] // + ", " + months[order_month] + order_date
        );
      } else {
        return ty[difference];
      }
    },
    ordinal_suffix() {
      let order_date = parseInt(this.order.order_date.substring(8, 10));
      var j = order_date % 10,
        k = order_date % 100;
      if (j == 1 && k != 11) {
        return "st";
      }
      if (j == 2 && k != 12) {
        return "nd";
      }
      if (j == 3 && k != 13) {
        return "rd";
      }
      if (this.order_date == "Today" || this.order_date == "Yesterday") {
        return "";
      }
      return "th";
    },
  },
};
</script>
<template>
    <CustomHeader text="Customer's Details" link=""/>
  <div class="container">
    <div class="btn_holder">
      <RouterLink to="/customer_edit_form"
        ><button @click="editCustomer()">
          <img :src="editWhite" alt="pencil Icon" class="addBtn" /> Edit
        </button>
      </RouterLink>
    </div>
    <div class="second_container">
      <div class="img-card">
        <img :src="card1" alt="imgavatar" />
        <h1>{{ customer.first_name }} {{ customer.last_name }}</h1>
      </div>
    </div>
    <SummaryBlocks :stats="stats" />

    <div class="main_details">
      <!-- CustomersArray was changed to a list -->
      <ul class="third_container first">
        <li class="dlc">
          <div class="details_list" >     
            <img :src="phoneIcon" alt=""/>
            <h3>Phone number</h3>
          </div>
          <h4 class="list_info">
            {{ customer.phone }}
          </h4>
        </li>
        <li class="dlc">
          <div class="details_list">
            <img :src="email" alt="email icon"/>
            <h3>Email</h3>
          </div>
          <h4 class="list_info">{{ customer.email }}</h4>
        </li>
        <li class="dlc">
          <div class="details_list">
              <img :src="cake" alt="cake icon" />
              <h3>Date of birth</h3>
          </div>
            <h4 class="list_info">
              <span v-if="customer.dob">{{ customer.dob}}</span><span v-else>-</span>
            </h4>
        </li>
        <!-- <li class="dlc">
          <div class="details_list">
            <img :src="profile" alt=""/>
            <h3>Style</h3>
          </div>
          <h4 class="list_info">{{ customer.style}}</h4>
        </li> -->
      </ul>
    </div>
    <div class="" style="display: flex; justify-content: space-between;">
      <div class="details_list">
        <!-- <img :src="box" alt="ordersIcon" /> -->
        <h3 style="margin: 20px 0 10px; font-weight: bold; font-size: 18px;">Last five orders</h3>
      </div>
      <p v-if="customer.lifetime_orders >
        0" class="underlined">{{ customer.lifetime_orders }} 
        Order <span v-if="customer.lifetime_orders > 1">s</span> 
        <img :src="arr" alt="arrow" />
      </p>
    </div>
    <div class="order_history">
      <table style="width: 100%;">
        <tr>
          <th>Date</th>
          <th>Items</th>
          <th>Status</th>
        </tr>
        <tr v-for="order, i in customerStats.orders" :key="i">
          <CustomerHistory
            :order="order" v-if="i < 5"
            :order_items="customerStats.order_items"
            :index="i"
            :products="customerStats.product_names"
          />
        </tr>
      </table>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>
<style scoped>
.container{
    margin:16px;
}
.second_container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.btn_holder {
  /* border: #002e22 solid 2px; */
  width:100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}

.img-card {
display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.img-card > img {
  width: 72px;
  height: 72px;
}

a {
  text-decoration: none;
}

button {
  background-color: #008060;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  border: none;
  border-radius: 4px;
}

h1 {
   color: #002e22;
  padding-top: 4px;
  font-size: 24px;
}
.dlc{
  display: flex;
  justify-content: space-between;
  margin: 8px;
}
.main_details {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: #c4dbd5 2px solid;
}

.third_container {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  padding: 8px;

  justify-content: space-between;
}

h3 {
  margin-left: 8px;
  color: #355f53;
  font-size: 16px;
  font-weight: 400;
}

.third_container > div {
  display: flex;
  
}
.first {
    flex-direction: column;
}
.list_info {
  font-size: 16px;
  font-weight: 500;
  color: #002e22;
}

.underlined {
  color: #008060;
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.details_list {
  display: flex;
  align-items: center;
}

.addBtn {
  margin-right: 4px;
  color: #c4dbd5;
}
table {
  border: 1px solid #C4DBD5;
  border-radius: 8px 8px 0 0;
  border-spacing: 0;
}
th:first-of-type {
  border-radius: 8px 0 0 0;
}
th:last-of-type {
  border-radius: 0 8px 0 0;
}
th {
  background: #F5FCF4;
  padding: 10px;
}
</style>
