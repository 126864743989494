<script>
// import phoneHeroImg from "@/assets/Images/phoneHeroImg.svg";
// import badge from "@/assets/Images/badge.svg";
import logo from "@/assets/Images/logo.svg";
import * as mutationTypes from '@/store/mutationTypes'
import { userRegCount } from '@/services/apiServices'

export default {
  name: 'BetaLanding',
  components: {
  },
  data() {
    return {
      active: 0,
      registered: 0,
      logo,
      slideIndex: 1,
    };
  },
  methods: {
    plusSlides(n) {
      this.showSlides(this.slideIndex += n);
    },
    currentSlide(n) {
      this.showSlides(this.slideIndex = n);
    },
    showSlides(n) {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) {this.slideIndex = 1}    
      if (n < 1) {this.slideIndex = slides.length}
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex-1].style.display = "block";  
      dots[this.slideIndex-1].className += " active";
    },
    signup() {
      this.$router.push("/signup")
    },
    toLogin() {
      this.$router.push("/login")
    }
  },
  mounted() {
		this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false)
    userRegCount()
      .then( res => {
        this.active = res.data.active
        this.registered = res.data.registrations
        let progress = document.getElementById("progress")
        progress.style.width = res.data.registrations + "%"
      })

      this.showSlides(this.slideIndex)

	},
	unmounted() {
		this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true)
	}
};
</script>
<template>
  <div class="container">
    <div class="header dark">
      <div>
        <!-- <img style="width:100px; margin-top: 4px" :src="logo" alt="logo" /> -->
        <div>
          <img style="width:25px;" src="@/assets/Images/yellow_leyyow.png" alt="logo" />
          <img style="width:90px; margin: 5px 10px 0" src="@/assets/Images/green_text_logo.svg" alt="logo" />
        </div>
      </div>
      <div style="padding-top: 5px;">
        <a style="font-size: 22px;" @click="toLogin()">Login</a>
      </div>
    </div>
    <div class="main dark">
      <section>
        <h1>Track your sales with ease</h1>
        <p class="h1_sub">Leyyow helps you track your sales and expenses, and provides marketing tools to help you grow your business.</p>
        <button class="btn_dark" @click="signup()">Sign Up</button>
        <div class="progress_bar">
          <div id="progress" class="progress"></div>
        </div>
        <div class="user_count" style="display: flex; justify-content: space-between;">
          <p class="">{{ active }} of {{ registered }} registrations active</p>
          <p class="">Goal: 100</p>
        </div>
        <p class="user_count" style="margin-top: -20px; font-style: italic;">Be one of the first 100 active merchants in order to earn <a href="#perks" style="color: #F0E68C; text-decoration: underline;">these</a> rewards.</p>

        <div style="border-radius:12px; height:450px; overflow: hidden; margin-bottom: 50px;">
          <img src="@/assets/Images/store_owner.jpeg" alt="small business" style="width: 100%; margin-top: -50px">
        </div>
      </section>

      <section id="perks">
        <h2>Limited pre-launch offer</h2>
        <p>We are inviting 100 merchants to be part of our early development. You'll get the discounts below in addition to having your business needs met early. Registration will close at 100 active merchants.</p>
        <div class="offer">
          <h3>Early sign-up rewards</h3>
          <ul>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>First 10 active customers: <span> 80% off the pro plan for ever!</span></p>
            </li>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Next 40 active customers: <span> 80% off the pro plan for 12 months!</span></p>
            </li>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Next 50 active customers: <span> 80% off the pro plan for 6 months!</span></p>
            </li>
          </ul>
          <div class="center_full_btn">
            <div class="" style="width: 20px;"></div>
            <button class="btn_dark" @click="signup()">Sign up</button>
            <div class="for_flex" style="width: 20px;"></div>
          </div>
        </div>
      </section>

      <section>
        <h2>How does it work?</h2>
        <p>When you initiate or conclude a sale on Instagram or Whatsapp, or even your physical store, manually post the sale on Leyyow to begin tracking it.</p>
        <div>
          <div class="slideshow-container">

            <div class="mySlides fade">
              <div class="numbertext">1 / 5</div>
              <img src="@/assets/Images/select_products.jpeg" style="width:100%">
            </div>

            <div class="mySlides fade">
              <div class="numbertext">2 / 5</div>
              <img src="@/assets/Images/cart.jpeg" style="width:100%">
            </div>

            <div class="mySlides fade">
              <div class="numbertext">3 / 5</div>
              <img src="@/assets/Images/add_customer.jpeg" style="width:100%">
            </div>

            <div class="mySlides fade">
              <div class="numbertext">4 / 5</div>
              <img src="@/assets/Images/checkout.jpeg" style="width:100%">
            </div>

            <div class="mySlides fade">
              <div class="numbertext">5 / 5</div>
              <img src="@/assets/Images/track_orders.jpeg" style="width:100%">
            </div>

            <p class="prev" @click="plusSlides(-1)">❮</p>
            <p class="next" @click="plusSlides(1)">❯</p>
          </div>
          <br>

          <div style="text-align:center">
            <div class="fade">
              <div class="text" v-if="slideIndex == 1">Select products ordered</div>
              <div class="text" v-if="slideIndex == 2">Specify customer's preferences</div>
              <div class="text" v-if="slideIndex == 3">Add customer's details</div>
              <div class="text" v-if="slideIndex == 4">Add order details</div>
              <div class="text" v-if="slideIndex == 5">Begin tracking order</div>
          </div>
            <span class="dot" @click="currentSlide(1)"></span> 
            <span class="dot" @click="currentSlide(2)"></span> 
            <span class="dot" @click="currentSlide(3)"></span> 
            <span class="dot" @click="currentSlide(4)"></span> 
            <span class="dot" @click="currentSlide(5)"></span> 
          </div>
        </div>
      </section>

      <section>
        <h2>Benefits</h2>
        <ul class="benefits">
          <li>
            <p>Know how much your business is making. Track open orders, closed orders, and more.</p>
          </li>
          <li>
            <p>Increase repeat customer purchases.</p>
          </li>
          <li>
            <p>No more endless scrolling through Instagram messages, looking for order or customer details.</p>
          </li>
          <li>
            <p>Get prompts for what your business needs to get done e.g. deliveries due within 48 hours.</p>
          </li>
        </ul>
      </section>

      <section>
        <div class="testimonials">
          <div>
            <h3>Testimonials</h3>
            <p>We’re just getting started so we don’t have any testimonials yet. It’s possible you’d give us our first review.</p>
            <button class="btn_dark" @click="signup()">Sign up</button>
          </div>
        </div>
      </section>
    </div>

    <div class="pricing light">
      <h2>What's your <span>Plan</span>?</h2>
      <div class="plans">
        <div class="" style="width: 20px;"></div>
        <div>
          <h3>pre-launch / basic</h3>
          <p class="price"><span>NGN 5,000</span> / month.</p>
          <ul>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Sales tracking</p>
            </li>
            <!-- <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Expense tracking<span> (Limited)</span></p>
            </li> -->
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Marketing tools<span> (Limited)</span></p>
            </li>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Other top secret stuff</p>
            </li>
          </ul>
          <button class="btn_dark" @click="signup()">Select plan</button>
        </div>
        <div class="" style="width: 20px;"></div>
      </div>
      <div class="plans">
        <div class="" style="width: 20px;"></div>
        <div>
          <h3>Pro plan (coming soon)</h3>
          <p class="price"><span>NGN 25,000</span> / month.</p>
          <ul>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Sales tracking</p>
            </li>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Expense tracking<span> (Full suite)</span></p>
            </li>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Marketing tools<span> (Full suite)</span></p>
            </li>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Multiple users and roles</p>
            </li>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Multiple locations</p>
            </li>
            <li>
              <div><img src="@/assets/Images/medal-star.png" alt="fancy bullet point"></div>
              <p>Even more top secret stuff</p>
            </li>
          </ul>
          <button class="btn_dark btn_light" disabled>Coming soon</button>
        </div>
        <div class="" style="width: 20px;"></div>
      </div>
    </div>

    <div class="footer dark">
      <div style="text-align: left;">
        <a href="mailto:hello@leyyow.com">hello@leyyow.com</a>
        <p style="margin-top: 15px;"><a href="https://instagram.com/_u/leyyow_app/">@leyyow_app</a></p>
      </div>
      <div style="text-align: right;">
        <p><a href="">Terms & Conditions</a></p>
        <p style="margin-top: 15px;"><a href="">Help & FAQs</a></p>
      </div>
    </div>
  </div>
</template>

<style scoped>
p, h1, h2, h3, h4, a {
  color: #F3FCF4;
}
ul {
  list-style-type: none;
  padding: 0;
}
a, p {
  font-size: 18px;
  word-spacing: 2px;
  line-height: 24px;
  opacity: 0.8;
}
.container {
  margin: 0;
}
.header, .main, .pricing, .footer {
  padding: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
}
.dark {
  background: #051F20;
}
h1 {
  margin-top: 50px;
  font-size: 44px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
}
.h1_sub {
  width: 100%;
  text-align: center;
  margin: 15px 0 50px;
}
h2 {
  margin: 100px 0 12px;
  font-size: 28px;
  line-height: 40px;
  word-spacing: 2px;
  letter-spacing: 1px;
}
h3 {
  font-size: 24px;
}
.user_count {
  text-align: center;
  margin: 10px 0 40px;
}
.progress_bar {
  width: 100%;
  border: 1.5px solid #DAF1DD;
  border-radius: 5px;
  margin-top: 10px;
  background: #DAF1DD;
}          
.progress {
  height: 10px;
  /* width: 20%; */
  background: #008060;
  border-radius: 4px;
  border: none;
}
.offer {
  width: 100%;
  display: block;
  background: #DAF1DD;
  border-radius: 12px;
  margin-top: 30px;
  padding: 30px 0;
}
.offer h3 {
  margin-bottom: 20px;
  padding-left: 20px;
}
.offer p, .offer h3 {
  color: #002E22;
}
.offer li {
  display: flex;
  margin-left: 20px;
}
.offer li img {
  position: relative;
  top: 5px;
}
.offer p {
  margin-left: 10px;
  width: 250px;
}
.offer p span {
  display: block;
  font-weight: bold;
  margin-bottom: 20px;
}
.offer button {
  margin-top: 20px;
}
.benefits {
  list-style-type: disc;
  padding-left: 10px;
}
.benefits li {
  margin-bottom: 20px;
}
.benefits li::marker {
  color: #DAF1DD;
}
.testimonials, .plans {
  width: 100%;
  border-radius: 12px;
  margin-bottom: 50px;
  padding-top: 70px;
}
.testimonials {
  margin-top: 100px;
  margin-bottom: 50px;
  padding-top: 70px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url(@/assets/Images/testimonials.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.testimonials div {
  width: 90%;
  padding: 20px;
}
.testimonials p {
  opacity: 1;
  margin-top: 20px;
}
.testimonials button {
  width: 200px;
  margin-top: 20px;
}
.pricing h2 {
  margin-top: 50px;
}
.pricing h2, .pricing p {
  color: #002E22;
}
.pricing h2 span {
  color: #008060;
}
.plans {
  display: flex;
  background: #FFF;
  padding: 20px 0;
  border: 1px solid #C4DBD5;
}
.plans h3 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 100;
  color: #497FED;
  /* color: #008060; */
  margin-bottom: 20px;
}
.plans ul img {
  margin-right: 10px;
  position: relative;
  top: 3px;
}
.plans ul li {
  display: flex;
  margin-bottom: 20px;
}
.plans ul li span {
  opacity: 0.5;
}
.price span {
  font-size: 28px; 
  font-weight: bold;
}
.price {
  margin-bottom: 20px;
}
.btn_dark {
  background-color: #008060;
  width: 100%;
  padding: 12px;
  color: #F3FCF4;
  font-size: 18px;
  border: none;
  border-radius: 5px;
}
.btn_light {
  background-color: #F5FCF4;
  border: 1px solid #C4DBD5;
  color: #92AAA1;
}
.center_full_btn {
  display: flex;
}
a {
  text-decoration: none;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 50px 20px;
}


* {box-sizing: border-box}
body {font-family: Verdana, sans-serif; margin:0}
.mySlides {display: none}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  width: 100%;
  /* max-width: 1000px; */
  height: 420px;
  position: relative;
  margin: auto;
  margin-top: 50px;
  border-radius: 12px;
  overflow: hidden;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: #008060;
  font-weight: bold;
  font-size: 48px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #DAF1DD;
  font-size: 20px;
  margin: -5px 0 10px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #008060;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #DAF1DD;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #008060;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next, .text {font-size: 11px}
}
</style>
