<script>
import CustomHomeHeader from "@/components/CustomHomeHeader.vue";
import dashboardHero from "@/assets/Images/dashboardHero.svg";
import plusWhite from "@/assets/Images/plusWhite.svg";
// import SummaryBlocks from "@/components/SummaryBlocks.vue";
import BestSeller from "@/components/Dashboard/BestSeller.vue";
// import BusinessOverview from '@/components/Dashboard/BusinessOverview.vue'
// import ExpensesChart from '@/components/Dashboard/ExpensesChart.vue'
// import GraphComponent from '../components/GraphComponent.vue'
import { mapGetters } from "vuex";
import { fetchMetrics } from "@/services/apiServices";
import * as mutationTypes from "@/store/mutationTypes";

import * as dayjs from "dayjs"

const CardArray = [
  {
    img: require("@/assets/Images/shop.svg"),
    title: "Add your first product",
    route: "/product_form",
  },
  {
    // no function to immediately add order yet
    img: require("@/assets/Images/basketIcon.svg"),
    title: "Add a sale or order",
    route: "/add_to_cart",
  },
  {
    img: require("@/assets/Images/basketIcon.svg"),
    title: "Add a customer",
    route: "/customer_form/customer/",
  },
  // {
  // 	// no expense yet
  // 	img:require('@/assets/Images/script.svg'),
  // 	title:'Record expense',
  // 	route:'/expense'
  // }
];

export default {
  components: {
    CustomHomeHeader,
    // SummaryBlocks,
    // BusinessOverview,
    BestSeller,
    // ExpensesChart,
    // GraphComponent
  },
  data() {
    return {
      best_sellers: [],
      CardArray,
      dashboardHero,
      // metrics: [],
      period: 1, // [1,2,3,4]:[today, week, month, year]
      plusWhite,
    };
  },
  methods: {
    updateMetrics() {
      fetchMetrics(this.period).then((res) => {
        let till = [];
        this.$store.commit(mutationTypes.SAVE_METRICS, res.data)
        res.data.best_sellers.map((item) => {
          let obj = this.inventory.find(
            (product) => product.id == item.productid
          );

          let new_obj = {};
          Object.assign(new_obj, obj);

          let percent = (item.count / res.data.total_items_in_period) * 100;
          new_obj.count = item.count;
          new_obj.percent = Math.round(percent);

          till.push(new_obj);
        });
        this.best_sellers = till;
      });
    },
  },
  computed: {
    ...mapGetters({
      inventory: "getInventory",
      metrics: "getMetrics"
    }),
    timeOfDay() {
      let hrs = dayjs().get("hours");
      return hrs < 12
        ? "morning"
        : hrs >= 12 && hrs < 6
        ? "afternoon"
        : "evening";
    },
    stats() {
      return [
        // {
        //   stat: "Revenue",
        //   value: "N345,000",
        // },
        // {
        //   stat: "Revenue",
        //   value: "N345,000",
        // },


        // {
        //   stat: "Orders",
        //   value: this.metrics.stats[1].data + " total"
        // },
        // {
        //   stat: "Items sold",
        //   value: this.metrics.items_in_period > 0 ? this.metrics.items_in_period + " total" : 0
        // },
        // {
        //   stat: "Open", // because some are pick up
        //   value: this.metrics.complete > 0 ? this.metrics.stats[1].data - this.metrics.complete + " orders" : 0
        // },
        // {
        //   stat: "-", // Outstanding deliveries
        //   value: "-",
        // }, 


        // {
        //   stat: "Dispatches", // Outstanding deliveries
        //   value: "4 outstanding",
        // }, 
        // {
        //   stat: "Unresolved",
        //   value: "1 refund",
        // }, {
        //   stat: "Vendor owed",
        //   value: "1",
        // }
        // {
        //   stat: "Ship today",
        //   value: "3 orders",
        // },
        // {
        //   stat: "Receivables",
        //   value: "1 customer", // also, monetary value and margin
        // },
        // {
        //   stat: "Sales",
        //   value: "7 today",
        // },
        // {
        //   stat: "Revenue",
        //   value: "N345,000",
        // },  {
        //   stat: "Unresolved",
        //   value: "1 refund",
        // }, {
        //   stat: "Vendor owed",
        //   value: "1",
        // }
      ];
    },
  },
  beforeMount() {
    this.updateMetrics()
  },
};
</script>

<template>
  <CustomHomeHeader text="Dashboard" />
  <div v-if="false" class="container">
    <!-- <div class="container">
			<img :src='dashboardHero' alt="cartoon of a lady typing ">
			<p>No data yet</p>
		</div> -->
    <div class="second_container">
      <h1>What would you like to start with?</h1>
      <div class="group">
        <div v-for="i in CardArray" :key="i">
          <RouterLink :to="i.route" class="inner">
            <img :src="i.img" alt="{{ i.name }} Icon" />
            <span> {{ i.title }} </span>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <!-- Dashboard here -->
    <div style="padding: 20px;">
      <h3>Good {{ timeOfDay }}</h3>
      <p style="margin-top: 5px;" class="">Here’s how is your bussiness is doing</p>
    </div>

    <div class="container">
      <div style="height: 77px;">
        <div class="header_mini" style="display: flex; flex-direction: column; position: absolute; right: 0; margin-right: 20px;">
          <p style="font-size: 17px; margin-bottom: 5px;">Select period</p>
          <select class="custom" v-model="period" @change="updateMetrics">
            <option  value="0">Today</option>
            <option value="1">Past week</option>
            <option value="2">Past month</option>
            <option value="3">Past year</option>
            <!-- <option value="4">Year to date</option> -->
          </select>
        </div>
      </div>

      <!-- <div style="background: #f5fcf4; border: #c4dbd5 1px solid; border-radius: 8px; display: flex; padding: 10px; margin-top: 20px;">
        <div class="img-wrapper">
          <img src="@/assets/Images/shop.svg" alt="Icon" />
        </div>
        <div style="margin-left: 20px; ">
          <p style="margin-bottom: 5px; font-size: 16px;">{{ metrics.stats[0].title }}</p>
          <div>
            <h3 class="h3" style="display: inline-block;">
              <span v-if="metrics.stats[0].currency == 1" style="margin: 0;"> &#8358;</span>
              {{ metrics.stats[0].data.toLocaleString() }}
            </h3>
            <span style="color: #3CB29A;">
              <img v-if="metrics.stats[0].up == 1" src="@/assets/Images/arrow_up_green.svg" alt="Icon" />
              <img v-if="metrics.stats[0].up == 0" src="@/assets/Images/arrow_down_red.svg" alt="Icon" />

              {{ metrics.stats[0].percent }}%
            </span>
          </div>
          <p style="font-size: 16px;">vs {{ metrics.stats[0].period }}</p>
        </div>
      </div> -->


      <!-- <SummaryBlocks :stats="stats" /> -->
      <div>
        <!-- <h1>Business overview</h1> -->
        <!-- <BusinessOverview/> -->
        <div class="selling_header" style="margin-top: 40px">
          <h2 style="font-size: 20px;">Best sellers</h2>
          <a href="">View All</a>
        </div>
        <BestSeller :best="best_sellers" />
        <div>
          <!-- <h1>Expenses</h1> -->
          <!-- <ExpensesChart/> -->
        </div>
        <!-- <GraphComponent title="Sale timeline" text="NGN20M" /> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.second_container {
  margin: 70px 16px;
  margin-bottom: 0;
}
.card {
  display: flex;
}
p {
  font-weight: 500;
  font-size: 18px;
}
h1 {
  font-weight: 500;
  font-size: 16px;
}
button {
  background-color: #008060;
  border-radius: 4px;
  border: none;
  color: #fff;
  padding: 8px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
button > img {
  margin-right: 4px;
}
.group {
  display: flex;
  flex-direction: column;
  background-color: #f5fcf4;
  border: #c4dbd5 1px solid;
  border-radius: 8px;
  margin-top: 21px;
  width: 100%;
}
.group > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-bottom: #c4dbd5 1px solid;
}
.inner {
  display: flex;
  align-items: center;
  color: #355f53;
}
span {
  margin-left: 12px;
}
a {
  text-decoration: none;
}
.custom {
  width: 114px;
  appearance: none;
  background-image: url("../assets/Images/select.svg");
  background-size: 25px;
  background-position: right 8px center;
  background-repeat: no-repeat;
  padding-right: 25px;
  background-color: white;
  color: #92aaa1;
}
select {
  border: 1px #c4dbd5 solid;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  outline: none;
}
.header_mini {
  display: flex;
  justify-content: end;
  margin: 16px 0;
}
.container {
  margin: 70px 16px;
  margin-top: 0px;
}
a {
  color: #008060;
}
.selling_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h1 {
  font-size: 16px;
  font-weight: 500;
  color: #002e22;
  padding: 16px 0;
}
</style>
