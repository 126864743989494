<script>
import CustomHeader from "../CustomHeader.vue";
import plusGreen from '@/assets/Images/plusGreen.svg'

export default {
    components: {
        CustomHeader,

    },
    data() {
        return {
plusGreen
        }
    }
}
</script>
<template>
    <CustomHeader text="My Profile" />
    <div class="header">
        <ul>
            <li>
                <RouterLink to="/profilePage" class="li">Basic Details</RouterLink>
            </li>
            <li>
                <RouterLink to="/subscription" class="li">Subscription</RouterLink>
            </li>
            <li class="main_page">Payment</li>
        </ul>
        <h1 class="first_header">Payment</h1>
    </div>
   <div class="details_container">
     <div class="details">
        <a class="" href="/addBank">
            <img :src="plusGreen" alt="plus icon">
            <span>Add bank</span>
        </a>
    </div>
   </div>
</template>
<style scoped>
.header {
    margin: 0 16px;
}
a {
    text-decoration: none;
    color: #355F53;
}

li,
.li {
    color: #92AAA3;
    padding: 8px;
}

ul {
    border-bottom: 1px solid #C4DBD5;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 16px 0;
}

.first_header{
    font-size: 14px;
    font-weight: 500;
    color: #002E22;
    margin-top: 24px;
}

.main_page {
    color: #008060;
    border-bottom: #008060 1px solid;
}
.details{
    background-color: #F5FCF4;
    height: 139px;
    border-radius: 8px;
    width:90%;
    display:flex;
    justify-content: center;
    border: 1px dotted #C4DBD5;
    align-items: center;
}
.details_container{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #008060;
    margin-top: 24px;
}
.details > a{
    display: flex;
    align-items: center;
    color: #008060;
}
.details > div > img{
    margin-right: 8px;
    
} 
</style>