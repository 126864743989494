<template>
     <div class="graph_container">
        <div class="graph">
       <div class="graph_header">
        <div class="timeline">
            <h1>{{ title }}</h1>
            <img :src="hamMenu" alt="hamMenu icon">
        </div>
        <div>
            <p>{{ text }} <a href="/"> {{ details }}</a></p>
        </div>
       </div>
        <div class="main_graph">
            <img :src="graph" alt="graph">
        </div>
    </div>
    </div>
</template>

<script>
import graph from '@/assets/Images/graph.svg'
import hamMenu from '@/assets/Images/hamMenu.svg'
    export default {
        props: {
            title:String,
            text: String,
            details: String
        },
        data(){
            return{
                graph,
                hamMenu
            }
        }
    }
</script>

<style scoped>
h1{
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 46, 34, 1)
;
}p{
    font-size: 14px;
    font-weight: 400;
    color: rgba(53, 95, 83, 1);
}
a{
color: rgba(0, 128, 96, 1)
;
}
.graph_container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.graph {
    border: 2px solid red;
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    border: #C4DBD5 1px solid;
    border-radius: 5px;
    background-color: white;
    
}

.timeline {
 display: flex;
 justify-content: space-between; 
 margin-bottom: 4px;

}
.graph_header{
    margin-bottom: 16px;
}
.main_graph>img{
width:100%;
}
</style>