import { createRouter, createWebHistory } from "vue-router";

// Views
import AboutusView from "@/views/AboutusView.vue";
import AddToCart from "@/views/Order/AddToCart.vue";
import BetaLanding from "@/views/BetaLanding";
import BulkUpload from "@/views/BulkUpload";
import Cart from "@/views/Order/Cart.vue";
import Checkout from "@/views/Order/Checkout";
import CustomerDetails from "@/views/Customer/CustomerDetails";
import CustomerForm from "@/views/Customer/CustomerForm";
import CustomersView from "@/views/Customer/CustomersView";
import DashboardView from "@/views/DashboardView";
// import ExpenseView from "@/views/ExpenseView";
import ReportSales from "@/views/Reports/SalesReport";
import ReportsIndex from "@/views/Reports/ReportsIndex";
import FourZeroFour from "@/views/FourZeroFour";
import InventoryView from "@/views/Inventory/InventoryView.vue";
// import LandingPage from "@/views/LandingPage"
import LoginView from "@/views/LoginView";
import OrderDetails from "@/views/Order/OrderDetails";
import OrdersView from "@/views/Order/OrdersView";
import ProductDetails from "@/views/Inventory/ProductDetails.vue";
import ProductForm from "@/views/Inventory/ProductForm";
import SignupView from "@/views/SignupView";
import SelectCustomer from "@/views/Order/SelectCustomer";
// Components
import AddBank from "@/components/Profile/AddBank.vue";
import ForgotPassword from "../components/ForgotPassword.vue";
import OrderAnalytics from "@/components/Order/OrderAnalytics.vue";
import OrderConfirmation from "@/components/Order/OrderConfirmation.vue";
import PaymentProfile from "@/components/Profile/PaymentProfile.vue";
import ProductAdded from "@/components/Inventory/ProductAdded.vue";
import ProductImage from "@/components/Inventory/ProductImage.vue";
import ProfileChangePassword from "@/components/Profile/ProfileChangePassword.vue";
import ProfilePage from "@/components/Profile/ProfilePage.vue";
import SubscriptionPage from "@/components/Profile/SubscriptionPage.vue";
import UploadImg from "@/components/Inventory/UploadImg.vue";
import CustomerAddedPopUp from "@/components/Customer/CustomerAddedPopUp.vue";
import DeleteConfirmationPopUp from "@/components/DeleteConfirmationPopUp.vue";
import store from "@/store";
import AddPayment from "@/components/Order/AddPayment.vue";
const routes = [
  {
    path: "/signup",
    name: "signup",
    component: SignupView,
    meta: { requiresGuest: true },
  },
  // {
  //   path: "/",
  //   name: "landing page",
  //   component: LandingPage,
  //   meta: { requiresGuest: true },
  // },
  {
    path: "/",
    name: "beta landing",
    component: BetaLanding,
    meta: { requiresGuest: true },
  },
  {
    path: "/bulk_upload",
    name: "bulk upload",
    component: BulkUpload,
    meta: { requiresAuth: true },
  },
  {
    path: "/about_us",
    name: "About Us",
    component: AboutusView,
    meta: { requiresGuest: true },
  },

  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { requiresGuest: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard Home",
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: "/sales_report",
    name: "Sales Report",
    component: ReportSales,
    meta: { requiresAuth: true },
  },
  {
    path: "/reports",
    name: "Reports Index",
    component: ReportsIndex,
    meta: { requiresAuth: true },
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword,
    meta: { requiresGuest: true },
  },
  {
    // not a route
    path: "/customer_success",
    name: "Add customer Pop Up",
    component: CustomerAddedPopUp,
  },
  {
    // not a route
    path: "/customer_delete",
    name: "Remove customer Pop Up",
    component: DeleteConfirmationPopUp,
  },
  {
    path: "/price_update",
    component: AddPayment,
  },
  {
    path: "/customer_details",
    name: "Customer Details",
    component: CustomerDetails,
    meta: { requiresAuth: true },
  },
  {
    path: "/customers/:origin",
    name: "Customers Home",
    component: CustomersView,
    meta: { requiresAuth: true },
  },
  {
    path: "/customer_form/:origin",
    name: "Customer Form",
    component: CustomerForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/customer_edit_form/:",
    name: "Customer Edit Form",
    component: CustomerForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/choose_customer/:origin",
    name: " Select Customer List",
    component: SelectCustomer,
    meta: { requiresAuth: true },
  },
  {
    path: "/orders",
    name: "Orders",
    component: OrdersView,
    meta: { requiresAuth: true },
  },
  {
    path: "/add_to_cart",
    name: "AddToCart",
    component: AddToCart,
    meta: { requiresAuth: true },
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: { requiresAuth: true },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: { requiresAuth: true },
  },
  {
    path: "/order_confirmation",
    name: " Order Confirmation",
    component: OrderConfirmation,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile_page",
    name: "ProfilePage",
    component: ProfilePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/password_change",
    name: "PasswordChange",
    component: ProfileChangePassword,
    meta: { requiresAuth: true },
  },
  {
    path: "/subscription",
    name: "SubscriptionPage",
    component: SubscriptionPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/payment_profile",
    name: "PaymentProfile",
    component: PaymentProfile,
    meta: { requiresAuth: true },
  },
  {
    path: "/addBank",
    name: "AddBank",
    component: AddBank,
    meta: { requiresAuth: true },
  },
  {
    // not a page
    path: "/order_analytics",
    name: "Order Analytics",
    component: OrderAnalytics,
    meta: { requiresAuth: true },
  },
  {
    path: "/order_details",
    name: "Order Details",
    component: OrderDetails,
    meta: { requiresAuth: true },
  },
  {
    path: "/inventory",
    name: "Inventory View",
    component: InventoryView,
    meta: { requiresAuth: true },
  },
  {
    path: "/uploadImg",
    name: "Upload Image",
    component: UploadImg,
  },
  {
    path: "/productImages",
    name: "Product Image",
    component: ProductImage,
  },
  {
    path: "/product_form/:origin",
    name: "Product Form",
    component: ProductForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit_product",
    name: "Edit Product",
    component: ProductForm,
    meta: { requiresAuth: true },
  },
  {
    // possibly not a route
    path: "/product_added",
    name: "Product Added",
    component: ProductAdded,
  },
  {
    path: "/product_details",
    name: "Product Details",
    component: ProductDetails,
    meta: { requiresAuth: true },
  },
  {
    path: "/404",
    name: "NotFound",
    component: FourZeroFour,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getLoggedIn) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (store.getters.getLoggedIn) {
      next({
        path: "/dashboard",
      });
    } else {
      next();
    }
  } else if (!to.matched.length) {
    next("/404");
  } else {
    next();
  }
});

export default router;
