<script>
import Menu from "@/assets/Images/menu.svg";
import cartoonProfile from "@/assets/Images/cartoonProfile.svg";
import copyIconGray from "@/assets/Images/copyIconGray.svg";
import logoutIcon from "@/assets/Images/logoutIcon.svg";
import { LOGOUT } from "@/store/mutationTypes";

const menuList = [
  {
    name: "Campaign",
  },
  {
    name: "Feedback",
  },
  {
    name: "Affliates",
  },
];
export default {
  props: {
    text: { type: String, required: true },
    link: { type: String, required: true },
  },
  name: "InventoryHeader",

  data() {
    return {
      Menu,
      cartoonProfile,
      copyIconGray,
      menuList,
      logoutIcon,
      visible: false,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.visible = !this.visible;
      if (this.visible) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    logout() {
      window.sessionStorage.removeItem("leyyow_token");
      this.$store.commit(LOGOUT);
      this.$router.push("/login");
    },
    handleClickOutside(e) {
      if (
        this.$refs.menu_drpdown &&
        this.$refs.menu &&
        !this.$refs.menu.contains(e.target) &&
        !this.$refs.menu_drpdown.contains(e.target)
      ) {
        this.visible = false;
      }
    },
  },
};
</script>
<template>
  <div class="header" v-click-outside="handleClickOutside">
    <RouterLink to="/{{ link }}">{{ text }}</RouterLink>
    <div class="profile" @click="toggleMobileMenu">
      <img class="menu" :src="Menu" alt="menu icon" ref="menu" />
    </div>
  </div>
  <div class="menu_drpdown" :class="{ visible: visible }" ref="menu_drpdown">
    <div class="section_one">
      <img :src="cartoonProfile" alt="cartoon Proofile" />
      <h1>Store name</h1>
    </div>
    <div class="section_two">
      <h2>Other store by Leyyow</h2>
      <div v-for="(item, index) in menuList" :key="index" class="item">
        <img :src="copyIconGray" alt="copy icon" />
        <p>{{ item.name }}</p>
        <span class="cs">(coming soon)</span>
      </div>
    </div>
    <div class="log_out">
      <img :src="logoutIcon" alt="logout icon" class="log" />
      <p @click="logout">Log out</p>
    </div>
  </div>
</template>
<style scoped>
.header {
  color: hsl(164, 100%, 9%);
  padding: 10px 16px;
  border-bottom: #c4dbd5 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.menu_drpdown {
  display: none;
  padding: 12px;
  border-radius: 8px;
  background: white;
margin: 16px;
margin-top:32px;
  flex-direction: column;
  position: absolute;
  right: 0;
}
.menu_drpdown.visible {
  display: flex;
}
.section_one {
  display: flex;
  margin-right: 8px;
  padding: 14px;
  border-bottom: #92aaa3 1px solid;
  margin-bottom: 4px;
}
.section_one > h1 {
  font-weight: 400;
  font-size: 14px;
  color: #355f53;
  margin-left: 8px;
  display: flex;
  align-items: center;
}
.item {
  display: flex;
  align-items: center;
  padding: 8px;
}
p {
  color: #355f53;
  margin-right: 4px;
  margin-left: 8px;
}
.menuText {
  margin-left: 4px;
}
.section_two > h2
.cs {
  color: #92aaa3;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}
.section_two {
  border-bottom: #92aaa3 1px solid;
}
h2 {
  padding: 8px;
}
.menu {
  width: 32px;
  height: 32px;
}

.profile {
  display: flex;
  align-items: center;
}

.profile > img {
  margin-right: 4px;
}

a {
  text-decoration: none;
  color: hsl(164, 100%, 9%);
  font-size: 18px;
}

h1 {
  font-weight: 500;
  font-size: 18px;
  color: rgba(0, 46, 34, 1);
}
.log_out {
  display: flex;

  padding: 8px;
}
img {
  width: 16px;
  height: 16px;
}
.log_out > p {
  color: #ce3c55;
}
</style>
