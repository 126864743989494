<template>
    <div class="btn">
        <button>
            <slot>Default</slot>
        </button>
    </div>
</template>

<script>
    export default {
    name: 'ButtonComponent'
    }
</script>

<style scoped>
button {
    box-shadow: 0px 4px 5px 1px rgba(168,215,196,0.63);
background: #008060;
border: none;
border-radius: 4px;
color: white;
display: flex;
justify-content: center;
align-items: center;
font-size: 16px;
font-weight: 500;
font-family: 'Aeonik';
cursor: pointer;
}
h1{
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
}
button>a {
    color: white;


text-decoration: none;

}


button>a>img {
margin: 0 4px;
}

.btn {
position: relative;
bottom: 0;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
margin: 20px 0;

}

.btn>button {
width: 95%;
padding: 12.5px 0;
}
</style>