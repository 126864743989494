<script>
import CustomHeader from "../CustomHeader.vue";
import cancel from "@/assets/Images/cancel.svg";
import ButtonComponent from "../ButtonComponent.vue";

export default {
components: {
  CustomHeader,
  ButtonComponent,
},
data() {
  return {
    cancel,
  };
},
};
</script>
<template>
<div class="morphism">
  <div class="form_section">
    <CustomHeader text="My Profile" />
    <div class="header_first">
      <ul>
        <li>Basic Details</li>
        <li>Subscription</li>
        <li>Payment</li>
      </ul>
      <h1>Basic Details</h1>
    </div>

    <form>
      <label for="">Store name</label>
      <input type="text" placeholder="e.g solesnshades, smile socks" />
      <label for="">Store type</label>
      <select class="custom">
        <option value="e.g solesnshades, smile socks">
          e.g solesnshades, smile socks"
        </option>
        <option value="Gadget">Gadget</option>

        <option value="food">Food</option>
        <option value="skincare">Skincare</option>
      </select>
      <label for="">Email</label>
      <input type="text" placeholder="example@gmail.com" />
      <label for="">Phone number</label>
      <input type="text" placeholder="+234 8082837122" />
      <label for="">Password</label>
      <input type="password" placeholder="*****************" />
    </form>
  </div>
  <div class="change_password_form">
    <div class="cancel_container">
      <div class="cancel">
        <button @click="$router.back()">
          <img :src="cancel" alt="cancel icon" />
        </button>
      </div>
    </div>
    <div class="form_container">
      <div>
        <h1 class="header">Change Password</h1>
        <form>
          <label>Current Password</label>
          <input
            class="passwords"
            type="password"
            placeholder="************"
          />
          <label>New Password</label>
          <input
            class="passwords"
            type="password"
            placeholder="************"
          />
          <ButtonComponent>Save</ButtonComponent>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
*,
body {
box-sizing: border-box;
margin: 0;
}
.header {
padding: 12px 16px;
}
a {
text-decoration: none;
color: #355f53;
}

.first_header {
font-size: 14px;
font-weight: 500;
color: #002e22;
margin-top: 24px;
}

.morphism {
position: relative;
top: 0;
left: 0;
width: 100%;
height: 100vh;
background: rgba(79, 75, 75, 0.4);
z-index: 1;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.header_main {
filter: blur(5px);
padding: 10px 16px;
color: hsl(164, 100%, 9%);
font-size: 18px;
font-weight: 500;
border-bottom: #c4dbd5 1px solid;
width: 100%;
left: 0px;
top: 0px;
z-index: -1;
}
.profile {
display: flex;
}

.next_section {
filter: blur(7px);
width: 100%;
display: flex;
flex-direction: column;
}

.header_link {
width: 100%;
display: flex;
justify-content: flex-end;
margin-top: 16px;
padding: 0 16px;
margin-bottom: 8px;
}

.header_link a {
color: #008060;
}

.add {
position: relative;
right: 13px;
}

.cancel {
padding: 6.6px;
border-radius: 8px;
background-color: white;
}

.cancel_container {
margin-top: 24px;
position: relative;
margin-bottom: 16px;
z-index: 2;
display: flex;
justify-content: flex-end;
}

.cancel > button {
background-color: rgb(53, 95, 83, 0.6);
color: #355f53;
opacity: 0.8;
border: none;
display: flex;
padding: 10px;
width: 100%;
border-radius: 50%;
}

.header {
border-bottom: #355f53 1px solid;
text-align: left;
width: 100%;
}

.header > h1 {
margin: 16px 0px;
margin-left: 16px;
font-size: 18px;
font-weight: 500;
line-height: 28px;
color: #002e22;
text-align: left;
}
h1 {
color: #002e22;
font-weight: 500;
font-size: 16px;
}
.avatar {
display: flex;
justify-content: center;
align-items: flex-end;
margin-top: 24px;
margin-bottom: 16px;
}

.content {
padding: 0 16px;
}
.content > div {
margin-top: 24px;
margin-bottom: 16px;
}

.form_container {
overflow: scroll;
position: relative;
width: 100%;
height: 40vh;
z-index: 3;
background: #f0f5ef;
border-top-left-radius: 8px;
border-top-right-radius: 8px;
}
.form_section {
filter: blur(5px);
}
form {
overflow: scroll;
display: flex;
margin: 0 16px;
flex-direction: column;
margin: 24px;
}

label {
color: #355f53;
margin: 4px 0;
width: 100%;
font-size: 14px;
font-weight: 400;
text-align: left;
}

input,
select {
outline: none;
padding: 12px;
margin-bottom: 12px;
border-radius: 6px;
border: 1px solid #c4dbd5;
background-color: #b1b5b0;
}

input:focus {
border: #355f53 1px solid;
}

input::placeholder {
color: #92aaa3;
}

span {
color: #92aaa3;
}

.err {
font-size: small;
color: red;
margin: 12px 0 !important;
}

.error {
border: 1px red solid;
}

.btn {
color: white;
padding: 12px;
font-weight: 500;
border-radius: 4px;
border: none;
margin: 24px 0px;
}
.customer_details_container {
height: 60vh;
position: fixed;
width: 100%;
bottom: 0;
backdrop-filter: blur(5px);
}
.details {
background-color: #b2b5b1;
height: 139px;
border-radius: 8px;
width: 90%;
display: flex;
justify-content: center;
border: 1px dotted #c4dbd5;
align-items: center;
}
.details_container {
display: flex;
justify-content: center;
align-items: center;
color: #008060;
margin-top: 24px;
filter: blur(5px);
}
.details > div {
display: flex;
align-items: center;
}
.details > div > img {
margin-right: 8px;
}
ul {
border-bottom: 1px solid #c4dbd5;
display: flex;
list-style: none;
padding: 0;
margin: 16px 0;
}

li,
.li {
color: #92aaa3;
padding: 8px;
}
.passwords {
background-color: white;
}
</style>
