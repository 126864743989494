<template>
  <div class="popup" v-if="isVisible">
    <div class="container_holder">
      <div class="cancel_container">
        <div class="cancel">
          <img :src="cancelDark" alt="cancel" @click="closePopup" />
        </div>
      </div>
      <div class="container">
        <h3>{{ title }}</h3>
        <div class="popup_content">
          <div class="content">
            <h2>{{ header }}</h2>
            <input
              type="text"
              name=""
              id=""
              placeholder="Mar 22, 2024"
              v-if="textBox"
            />
            <p>{{ message }}</p>
            <button @click="handleSubmit()" v-if="btnOption">{{ btnMessage }}</button>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancelDark from "@/assets/Images/cancelDark.svg";
export default {
  props: {
    header: String,

    title: {
      type: String,
      required: false,
    },
    btnMessage: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: false,
    },
    placeholderText: {
      type: String,
      required: false,
    },

    isVisible: {
      type: Boolean,
      required: true,
    },
    btnOption: {
            type: Boolean,
      required: false,
    },
    textBox: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      cancelDark,
    };
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$emit('submit')
    }
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  z-index: 3;
}
.container_holder {
  margin: 16px;
  width: 90%;
}
.cancel_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}
h2 {

  color: #355f53;
  font-size: 20px;
  font-weight: 500;
}
p {
  margin: 10px 0;
  color: #355f53;
  /* text-align: justify; */
}
h3 {
  color: #002e22;
  padding: 12px;
  border-bottom: 1px solid #c4dbd5;
}
.container {
  background: white;
  border-radius: 5px;
  width: 100%;
}
.content {
  display: flex;
  width: 100%;
  flex-direction: column;

}
.popup_content {
  display: flex;
  /* justify-content: center; */
  padding: 16px;
  flex-direction: column;
}
button {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  background-color: #008060;
  border: none;
  color: white;
}
a {
  text-decoration: none;
  color: white;
  font-weight: 500;
}
.cancel img {
  width: 16px;
  height: 16px;
  background: #90a59d;
  padding: 12px;
  border-radius: 50px;
}
input{
padding: 12px;
/* width: 90%; */
margin:5px 0px;
border-radius: 8px;
border: 1px solid #c4dbd5 ;
}
</style>
