<script>
import CustomHeader from "@/components/CustomHeader.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import OptionsPriceAndQty from "@/components/Inventory/OptionsPriceAndQty.vue";
import penIcon from "@/assets/Images/editPen.svg";
import hamMenu from "@/assets/Images/hamMenu.svg";
import imgAvatar from "@/assets/Images/imgAvatar.svg";
import copyIcon from "@/assets/Images/inventoryDetailsIcon.svg";
import calendar from "@/assets/Images/calendar.svg";
import priceIcon from "@/assets/Images/price.svg";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      copyIcon,
      priceIcon,
      calendar,
      edit: false,
      hamMenu,
      imgAvatar,
      penIcon,
      variant_options: [],
      variant2Length: 0,
    };
  },
  components: {
    ButtonComponent,
    CustomHeader,
    OptionsPriceAndQty,
  },
  methods: {
    editProduct() {
      this.edit = true
      this.$router.push("/edit_product")
    },
  },
  computed: {
    ...mapGetters({
      product: "getProductToEdit",
    }),
    baseUrl() {
      return window.location.origin
    },
    stats() {
      return [
        {
          stat: "Items in stock",
          value: this.product.total_stock,
        }, {
          stat: "Last 30 days",
          value: "7 sales" // also, monetary value and margin
        }, {
          stat: "in last 30 days",
          value: "12th best seller"
        }, {
          stat: "Last purchase",
          value: "Nov 22"
        }
      ]
    }
  },
  mounted() {
    if (this.product.second_variant.split(',')[0] != "" && this.product.second_variant.split(',')[0] != undefined) {
      this.variant2Length = 1
    }
    let split = this.product.variant_options.split(';')

    // index 1 and beyond of the split result have a preceeding comma
    // hence the need to treat the first index [0] different from the rest
    let options = split[0]
    this.variant_options.push(options.split(','))

    for (let i=1; split[i]; i++) {
      options = split[i].substring(1)
      options = options.split(',')
      this.variant_options.push(options)
    }
  },
};
</script>

<template>
  <CustomHeader text="Product Details" />
<div class="container_main">
 <div class="main">
 <!-- Summary block was here -->
  <div class="holder">

    <div class="holder_first_div">
      <h2>Product image</h2>
      <img :src="hamMenu" alt="ham">
    </div>
    <div class="pd_centered">
      <img v-if="baseUrl == 'http://127.0.0.1:8080'" :src="'http://127.0.0.1:8000' + product.product_image" alt="img"  />
      <img v-else :src="product.product_image" alt="" class="product_image"/>
      <h1>{{ product.product_name }}</h1>
    </div>
    <div class="product_details_header">
      <h2>Product Details</h2>
    </div>
    <div class="container">
      <div>
        <img :src="priceIcon" alt="price icon" />
        <h3>Price</h3>
      </div>
      <p>{{ product.price }}</p>
    </div>

    <div class="container">
      <div>
        <img :src="calendar" alt="calendar icon" />
        <h3>Date added</h3>
      </div>
      <p>{{ product.date }}</p>
    </div>

    <div class="container">
      <div>
        <img :src="copyIcon" alt="copy icon" />
        <h3>Description</h3>
      </div>
    </div>

    <p class="desc">{{ product.description }}</p>

    <!-- <div class="product_details_header">
      <h2>Product variants</h2>
      <button class="white">Manage Variants</button>
    </div> -->
  </div>

 </div>
 <button @click.prevent="editProduct()" class="white">Edit</button>
 <!-- variant options preview -->
  <div v-if="product.has_variant > 0" class="table">
    <div class="variant_title">
      <h3>Product Variants</h3>
    </div>
    <div class="table_header">
      <h3>Options </h3>
      <div class="smaller_section">
        <h3>Qty</h3>
        <h3>Price</h3>
      </div>
    </div>
    <div class="">
      <div v-for="option, i in this.variant_options" :key="i">
        <!-- hack to check if two variants. one variant will be less than 4 -->
        <div v-if="this.variant_options[0].length > 3">
          <OptionsPriceAndQty
            :option1="option[0]"
            :option2="option[1]"
            :index1="i"
            :var2len="variant2Length"
            :currentQty="option[2]"
            :currentPrice="option[3]"
          />
        </div>
        <div v-else>
          <OptionsPriceAndQty
            :option1="option[0]"
            :index1="i"
            :currentQty="option[1]"
            :currentPrice="option[2]"
          />
        </div>
      </div>
    </div>
  </div>
  <ButtonComponent>View Product Feedback</ButtonComponent>
  <div style="display: block; height: 50px"><!--props page up--></div>
</div>
</template>

<style scoped>

.container_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 16px;
}
.main, .table{
  width: 100%;
}
.table h3{
  font-size: 16px;
  font-weight: 400;
}
.table_header{
  padding: 8px;
 display: flex;
    justify-content: space-between;
    background-color: #F5FCF4;
    border-bottom: #C4DBD5 1px solid;
}
.variant_title{
  padding: 12px 0;
  display: flex;
  justify-content: space-between
}
.smaller_section{
    display: flex;
    justify-content: space-around;
    width: 45%;
}
.header_link {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 0 16px;
  margin-bottom: 8px;
}

.header_link a {
  color: #008060;
}
h1 {
  color: #002e22;
  font-weight: 500;
  font-size: 24px;
  margin: 4px 0;
}

h2 {
  color: #002e22;
  font-size: 16px;
  font-weight: 500;
}

.product_details_header {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}

.product_details_header button {
  color: whte;
  border-radius: 8px;
  padding: 8px;
  border: #c4dbd5 1px solid;
}
.pd_centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #c4dbd5;
}

.pd_centered img{
  max-width: 300px;
  border-radius: 8px;
}
.product_image{
  width: 88px;
  height: 88px;
  object-fit: contain;
}

.holder_first_div{
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  align-items: center;
}
.container,
.container > div {
  display: flex;
   width: 95%;
}

.container > div h3 {
  font-weight: 400;
  color: #355f53;
  font-size: 14px;
  margin-left: 8px;
 
}

.container {
  padding: 8px;
  justify-content: space-between;
}
.container p {
  color: #002e22;
  font-size: 16px;
  font-weight: 500;
}
.desc {
  color: #355f53;
  font-size: 16px;
  padding: 8px;
}


.card_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: #c4dbd5 1px solid;
  padding-bottom: 8px;
}

.card_top_details {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.card_top_details > img {
  margin-right: 12px;
}

.ct_info > h1 {
  font-size: 14px;
  color: #355f53;
  margin-bottom: 4px;
  font-weight: 400;
  margin-bottom: 4px;
}

.ct_info {
  display: flex;
  flex-direction: column;
}

.ct_info > div {
  display: flex;
}

.ct_info > div > p {
  font-weight: 500;
  font-size: 14px;
  margin-right: 4px;
  color: #002e22;
  margin-top: 4px;
}

.card_buttom {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.card_buttom > p {
  margin: 0 12px;
  width: 40px;
  height: 40px;
  color: #355f53;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #c4dbd5 1px solid;
}
.white{
color: #008060;
  background-color: white;
  padding: 6px 8px;
  border: 1px #c4dbd5 solid;
  border-radius: 4px;
}

</style>
