<script>
import OptionsPriceAndQty from "@/components/Inventory/OptionsPriceAndQty";
// import OptionsPriceAndQty from '@/components/Inventory/OptionsPricing2'
import dustbin from "@/assets/Images/dustbin.svg";
import greenPlus from "@/assets/Images/greenPlus.svg";

import { mapGetters } from "vuex";
import * as mutationTypes from "@/store/mutationTypes";

export default {
  name: "ProductVariants",
  props: ['defaultPrice'],
  emits: ['variantsOptions', 'variantsStrings'],
  components: {
    OptionsPriceAndQty,
  },
  data() {
    return {
      dustbin,
      greenPlus,
      allVariants: [],
      moreVariants: false,
      text1: "",
      text2: "",
      text3: "",
      totalStock: 0,
      variantCount: 0,
      first_variant_name: "",
      second_variant_name: "",
      third_variant_name: "",
      variant1: [],
      variant2: [],
      variant3: [],
      variant1Length: 0,
      variant2Length: 0,
    };
  },
  methods: {
    delFromVar1(index) {
      this.variant1.splice(index, 1);

      if (this.variant2Length < 1) {
        // no variant2
        this.allVariants.splice(index, 1);
      } else {
        // we have variant2
        let l = this.variant2Length;
        let i = index * l;
        this.allVariants.splice(i, l);
      }

      try {
        this.updateVariantsQtyPrice()
      } catch {null}
    },
    delFromVar2(index) {
      let l = this.variant2Length;
      if (l > 1) {
        for (let i = 0; i < this.variant1.length; i++) {
          let j = l * i + index - i;
          this.allVariants.splice(j, 1);
        }
      } else {
        // set count for all variant1 items to 0
        this.resetVar1Count();
      }
      // note allVariants updated before variant splice
      this.variant2.splice(index, 1);

      this.updateVariantsQtyPrice();
    },
    addOption(text) {
      if (this.text1.trim() != "") {
        this.variant1.push(this.text1.trim());
        this.text1 = "";
      } else if (this.text2.trim() != "") {
        this.variant2.push(this.text2.trim());
        this.text2 = "";
      } else if (this.text3.trim() != "") {
        this.variant3.push(this.text3.trim());
        this.text3 = "";
      }
      document.getElementById(text).focus()
      document.getElementById(text).select()
    },
    removeVariant() {
      alert(
        "All options and values set for " +
          this.second_variant_name +
          " will be deleted"
      );
      this.moreVariants = false;
      // give option to Cancel or OK
      // if ok, then:
      this.variant2 = [];
      this.second_variant_name = "";
      this.variant2Length = 0;
      // else
      // cancel the action
    },
    resetVar1Count() {
      this.$nextTick(function () {
        // variantsQtyArray = document.getElementsByClassName("arrays_qty")

        var localArray = [];
        for (let i = 0; i < this.variant1.length; i++) {
          let object = { option1: this.variant1[i], quantity: 0, price: 0 };
          localArray.push(object);
        }
        this.allVariants = localArray;
      });
    },
    stringifyOptions() {
      let options1 = this.variant1.toString();
      let options2 = this.variant2.toString();
      this.$emit(
        "variantsOptions",
        this.first_variant_name,
        this.second_variant_name,
        options1,
        options2
      );
    },
    toggleMoreVariants() {
      this.variant1Length > 0
        ? (this.moreVariants = true)
        : (this.moreVariants = false);
    },
    updateVariantsQtyPrice(index, qty, price) {
      // updates variant qty and price in allVariants
      this.allVariants[index].quantity = qty;
      this.allVariants[index].price = price;
      this.$emit("variantsStrings", this.stringifiedVariants, this.totalQuantity);
    },
    updateVariant(which) {
      if (this.variant2Length > 0) {
        // first variant2 has been added
        if (which == "array1") {
          let l = this.variant1.length;
          if (l > this.variant1Length) {
            // variant added
            for (let i = 0; i < this.variant2.length; i++) {
              let object = {
                option1: this.variant1[l - 1],
                option2: this.variant2[i],
                quantity: 0,
                price: 0,
              };
              // this.allVariants.push(0)
              this.allVariants.push(object);
            }
          } // else variant removed and handled by the delete function
        } else if (which == "array2") {
          let l = this.variant2.length;
          if (l > this.variant2Length) {
            // variant added
            for (let i = 0; i < this.variant1.length; i++) {
              let j = l * i + l;
              let object = {
                option1: this.variant1[i],
                option2: this.variant2[l - 1],
                quantity: 0,
                price: 0,
              };
              this.allVariants.splice(j - 1, 0, object);
            }
          } // else variant removed and handled by the delete function
        }
      } else {
        // add first variant2
        var localVariants = [];
        for (let i = 0; i < this.variant1.length; i++) {
          for (let y = 0; y < this.variant2.length; y++) {
            let object = {
              option1: this.variant1[i],
              option2: this.variant2[y],
              quantity: 0,
              price: 0,
            };
            localVariants.push(object);
          }
        }
        this.allVariants = localVariants;
      }
      this.variantCount = 2;
    },
  },
  computed: {
    ...mapGetters({
      edit: "getProductToEdit",
    }),
    stringifiedVariants() {
      var localVariants = [];
      for (let i = 0; i < this.allVariants.length; i++) {
        localVariants.push(this.allVariants[i].option1);
        if (this.allVariants[i].option2) {
          localVariants.push(this.allVariants[i].option2);
        }
        localVariants.push(this.allVariants[i].quantity);
        localVariants.push(this.allVariants[i].price + ";");
      }
      return localVariants.toString();
    },
    totalQuantity() {
      return this.allVariants.reduce((sum, item) => sum + Number(item.quantity), 0)
    },
  },
  watch: {
    variant1: {
      handler: function () {
        if (this.variant2Length < 1) {
          // only array 1 exists
          var localVariants = [];
          if (this.variant1Length > 0) {
            // first variant1 added
            let l = this.variant1.length;
            if (l > this.variant1Length) {
              // variant added
              let object = {
                option1: this.variant1[l - 1],
                quantity: 0,
                price: 0,
              };
              this.allVariants.push(object);
            } else {
              // variant removed
              // this.updateAllVariantsQty()
            }
          } else {
            // create first object in array
            let object = { option1: this.variant1[0], quantity: 0, price: 0 };
            localVariants.push(object);
            this.allVariants = localVariants;
          }
        } else {
          // array 2 (second variant) exists
          this.updateVariant("array1");
        }
        this.variant1Length = this.variant1.length;
        this.stringifyOptions();
      },
      deep: true,
    },
    variant2: {
      handler: function () {
        this.updateVariant("array2");
        this.variant2Length = this.variant2.length;
        this.stringifyOptions();
      },
      deep: true,
    },
    variant2Length: {
      handler: function () {
        this.variant2Length > 0 ? (this.variantCount = 2) : 0;
      },
      // deep: true
    },
  },
  mounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false);

    // if (this.edit.id) {
    //   if (this.edit.has_variant) {
    //     this.first_variant_name = this.edit.first_variant_name

    //     if (this.edit.second_variant.split(',')[0] != "" && this.edit.second_variant.split(',')[0] != undefined) {
    //       this.second_variant_name = this.edit.second_variant_name
    //       this.variant2 = this.edit.second_variant.split(',')
    //       this.variant2Length = 1
    //       this.moreVariants = true
    //       this.variantCount = 2
    //     }
        
    //     this.first_variant_name = this.edit.first_variant_name
    //     this.variant1 = this.edit.first_variant.split(',')

    //     // index 1 and beyond of the split result have a preceeding comma
    //     // hence the need to treat the first index [0] different from the rest
    //     let var_options = this.edit.variant_options.split(';')
    //     let opt_0 = var_options[0]
    //     this.allVariants.push(opt_0.split(','))
    //     // this.updateVariantsQtyPrice(0, opt_0.split(',')[1], opt_0.split(',')[2])


    //     for (let i=1; i < var_options.length; i++) {
    //       let opt_i = var_options[i].substring(1)
    //       opt_i = opt_i.split(',')
    //       this.allVariants.push(opt_i)
    //       // this.updateVariantsQtyPrice(i, opt_i[1], opt_i[2])
    //     }
    //   }
    // }
  },
  unmounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true);
  },
};
</script>
<template>
  <div class="variant color">
    <label for="">Variant name</label>
    <div class="variant_name">
      <input v-model="first_variant_name" placeholder="Colour, size, etc" style="text-transform: capitalize;">
    </div>

    <div v-if="this.first_variant_name != ''" class="option_wrapper">
      <p>Enter variant options below. Click "Add" after typing each option.</p>
      <div style="display: flex; flex-wrap: wrap; padding: 10px">
        <div v-for="(option, i) in variant1" :key="i">
          <p class="option">{{ option }} <span @click="delFromVar1">x</span></p>
        </div>
      </div>
      <div style="display: flex;">
        <input
          id="text1"
          class="custom"
          v-model="text1"
          placeholder="Type options here"
        />
        <button @click="addOption('text1')" class="custom" style="width: 70px; background: #C4DBD5; margin-left: 10px;">Add</button>
      </div>
    </div>
  </div>

  <!-- Variant2 -->
  <div v-if="moreVariants" class="variant size">
    <label for="">Variant name</label>
    <div class="variant_name">
      <input v-model="second_variant_name" style="text-transform: capitalize;"  placeholder="Color, size">
    </div>
    <label for="">Variant type</label>
    <div v-if="this.second_variant_name != ''" class="option_wrapper">
      <div style="display: flex; flex-wrap: wrap; padding: 10px">
        <div v-for="(option, i) in variant2" :key="i">
          <p class="option">{{ option }} <span @click="delFromVar2">x</span></p>
        </div>
      </div>
       <h3>Variant type</h3>
      <div style="display: flex;">
        <input
          id="text2"
          class="custom"
          v-model="text2"
          placeholder="Click 'Add' after each option"
        />
        <button @click="addOption('text2')" class="custom" style="width: 70px; background: #C4DBD5; margin-left: 10px;">Add</button>
      </div>
    </div>
  </div>

  <div class="add_variant" v-if="variant1Length > 0">
    <span v-if="moreVariants" @click="removeVariant()"
      >Remove second variant</span
    >
    <p v-else class="variants_list" @click.stop="toggleMoreVariants()"> <img :src="greenPlus" alt="">Add another variant</p>
  </div>

  <!-- variant options preview -->
  <div class="table_header" v-if="this.variant1Length != 0">
    <!-- <div class="variant_name">
      <h2>Product Variants</h2>
    </div> -->
    <h3>Options</h3>
    <div class="smaller_section">
      <h3>Qty</h3>
      <h3>Price</h3>
    </div>
  </div>
  <div class="">
    <div v-for="(option1, index1) in variant1" :key="index1">
      <div v-if="variant2Length > 0">
        <div v-for="(option2, index2) in variant2" :key="index2">
          <OptionsPriceAndQty
            :option1="option1"
            :option2="option2"
            :index1="index1"
            :index2="index2"
            :var2len="variant2Length"
            :defaultPrice="this.defaultPrice"
            @updateQtyPrice="updateVariantsQtyPrice"
          />
        </div>
      </div>
      <div v-else>
        <OptionsPriceAndQty
          :option1="option1"
          :index1="index1"
          :defaultPrice="this.defaultPrice"
          @updateQtyPrice="updateVariantsQtyPrice"
          />
      </div>
    </div>
  </div>

  <!-- variant options preview v2 -->
  <!-- <OptionsPriceAndQty :variants="allVariants" /> -->
</template>

<style scoped>
.table_header {
  /* background-color: #f5fcf4; */
  border-bottom: #c4dbd5 1px solid;
}
h3 {
  color: #355f53;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 0px;
}
.table_header {
  display: flex;
  justify-content: space-between;
}
.smaller_section {
  display: flex;
  justify-content: space-around;
  width: 45%;
}

.option_wrapper {
  width: 100%;
  background-color: #f5fcf4;
  border-radius: 8px;
}
.option {
  color: #008060;
  display: inline-block;
  padding: 6px 8px;
  border-radius: 4px;
  background: #c4dbd5;
  margin: 0 10px 10px 0;
}
.option span {
  margin: 5px;
}
body {
  box-sizing: border-box;
  margin: 0;
}
.add {
  position: relative;
  right: 13px;
}
.variant_name {
  display: flex;
  align-items: center;
}
textarea {
  width: 100%;
  border: #c4dbd5 1px solid;
  background-color: #f5fcf4;
  border-radius: 8px;
  font-family: "Aeonik";
}
label {
  color: #355f53;
  margin: 4px 0;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
input {
  outline: none;
  padding: 12px;
  margin: 12px 0;
  border-radius: 6px;
  border: 1px solid #c4dbd5;
 
}
.var {
  color: #008060;
  margin-right: 8px;
}
input::placeholder {
  color: #92aaa3;
  
}
span {
  color: #92aaa3;
}
.custom {
 background: white;
   width: 90%;
  border: 1px solid #c4dbd5;
  border-radius: 6px;
  margin: 8px 0px;
  padding: 12px;
  font-size: 15px;
  font-family: "Aeonik";
}
.size {
  padding-top: 24.5px;
  border-top: 1px #c4dbd5 solid;
}
.color {
  margin-bottom: 9.5px;
}
.variants_list {
  color: #008060;
  border-bottom: #008060 1px solid;
  padding: 1px;
  width: 50%;
  margin-bottom:24px;
  display: flex;
  font-size: 14px;
  align-items: center;
}
.variants_list > img{
  margin-right: 8px;
}
</style>
