<script>
import OrdersList from "@/components/Order/OrdersList.vue";
import CustomHomeHeader from "@/components/CustomHomeHeader.vue";
import Hero from "@/assets/Images/OrderHero.svg";

import { mapGetters } from "vuex";
import { fetchOrders } from "@/services/apiServices";
import * as mutationTypes from "@/store/mutationTypes";

export default {
  name: "OrdersView",
  components: {
    CustomHomeHeader,
    OrdersList,
  },
  data() {
    return {
      Hero,
    };
  },
  computed: {
    ...mapGetters({
      has_sale: "getHasSale",
    }),
  },
  mounted() {
    fetchOrders() // move this to dashboard?
      .then((res) => {
        this.$store.commit(mutationTypes.LOAD_ORDERS, res.data);
      })
      .catch(() => {})
      .finally(() => {});
  },
};
</script>
<template>
  <div v-if="!this.has_sale" class="container">
    <div class="body">
      <CustomHomeHeader text="Orders" link="OrdersView" />
      <div class="content">
        <img :src="Hero" alt="cartoon of a woman shopping" />
        <p>You have no pending orders</p>
        <button>
          <RouterLink to="/add_to_cart" class="btn-link"
            >Add an order</RouterLink
          >
        </button>
      </div>
      <div></div>
    </div>
  </div>
  <div v-else>
    <OrdersList />
  </div>
</template>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
}
.btn-link {
  text-decoration: none;
  color: white;
}
.body {
  height: 90vh;
}
.profile > img {
  position: relative;
  bottom: 0;
}
.content {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content > h1 {
  margin: 4px 0;
  font-size: 18px;
  font-size: medium;
  color: #002e22;
}
.content > p {
  margin-bottom: 16px;
  font-size: 16px;
  color: #355f53;
}
button {
  border-radius: 4px;
  background: #008060;
  border: none;
  color: white;
  font-size: 14px;
  padding: 8px 30.5px;
}
</style>
