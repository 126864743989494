<script>
export default {
  name: "ProductCount",
  props: [
    "newCount",
    "index"
  ],
  data() {
    return {
      count: null,
    };
  },
  watch: {
    count: {
      // make this work along with strict product count
      // perhaps warn the user that the amount entered is more than available products
      handler() {
        // change to '1' on blur, rather than here
        // this.count == "" ? this.count = 1 : ""
        this.$emit("updateCount", this.count, this.index);
      },
    },
    newCount: {
      handler() {
        this.count = this.newCount
      }
    }
  },
  mounted() {
    this.count = this.newCount
  }
};
</script>
<template>
  <input type="number" v-model="count">
</template>

<style scoped>
input {
  border: #92aaa3 1px solid;

  border-radius: 40px;
  margin: 0 5px;
  width: 30px;
  text-align: center;
}
</style>
