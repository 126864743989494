<script>
import { mapGetters } from "vuex"

export default {
  name: "SalesOverview",
  props: [
  'date_range',
  'sales_report',
  ],
  data() {
    return {
      // currency,
      gross_revenue: 0,
      report: {
        sales: 0,
        shipping: 0,
        breakdown: [
            {'unit': "Wk 1", 'amount': 0},
            {'unit': "Wk 2", 'amount': 0},
            {'unit': "Wk 3", 'amount': 0},
            {'unit': "Wk 4", 'amount': 0},
            {'unit': "Wk 5", 'amount': 0},
        ],
      }
    };
  },
  methods: {
    toggleDisplayMore() {
      var dots = document.getElementById("dots");
      var moreText = document.getElementById("more");
      var btnText = document.getElementById("less");

      if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.style.display = "none"; 
        moreText.style.display = "none";
      } else {
        dots.style.display = "none";
        btnText.style.display = "inline"; 
        moreText.style.display = "inline";
      }
    },
    updateReport() {
      const gross = this.report.sales + this.report.shipping
      if ( gross > 0 ) {
        this.gross_revenue = gross

        // COMPUTE DATA FOR GRAPHS //
        let shipping = document.getElementById("shipping")
        let discounts = document.getElementById("discounts")
        let refunds = document.getElementById("refunds")
        let net_sales = document.getElementById("net_sales")

        // Multiplied by 85% instead of 100 to leave room between bars //
        shipping.style.width = (this.report.shipping / gross  * 85) + "%"
        discounts.style.width = (0 / gross * 85) + "%"
        refunds.style.width = (0 / gross * 85) + "%"
        net_sales.style.width = (this.report.sales / gross * 85) + "%"


        var max = 0
        var widths = []
        for (let i=0; i < this.report.breakdown.length; i++) {
          let width = this.report.breakdown[i].amount / gross * 100
          width > max ? max = width : ""
          widths.push(width)
        }

        // Makes the graph fill the page as best as possible //
        var quotient = Math.floor(100 / max)
        if (quotient > 1) {
          for (let i=0; i < this.report.breakdown.length; i++) {
            let id = this.report.breakdown[i].unit
            let unit = document.getElementById(id)
            unit.style.width = Math.floor(widths[i]) * quotient + "%"
          }
        } else {
          for (let i=0; i < this.report.breakdown.length; i++) {
            let id = this.report.breakdown[i].unit
            let unit = document.getElementById(id)
            unit.style.width = widths[i] + "%"
          }
        }
      } else {
        // To prevent DOM errors and to make //
        // DOM update when there is no data.  //
        this.gross_revenue = 0,
        this.report = {
          sales: 0,
          shipping: 0,
          breakdown: [
              {'unit': "Wk 1", 'amount': 0},
              {'unit': "Wk 2", 'amount': 0},
              {'unit': "Wk 3", 'amount': 0},
              {'unit': "Wk 4", 'amount': 0},
              {'unit': "Wk 5", 'amount': 0},
          ],
        }
      }
    },
  },
  computed: {
    ...mapGetters({
    })
  },
  watch: {
    sales_report: {
      handler: function () {
        this.report = this.sales_report
        this.updateReport()
      },
    },
  },
  mounted() {
    this.updateReport()
  },
};
</script>
<template>
  <CustomHomeHeader text="Sales report" link="Reports Index" />
  <div class="container">
    <section class="executive_summary">
      <h2 style="font-size: 28px">1. Summary</h2>
      <h2 style="font-size: 28px; display: none;">Executive summary</h2>
      <p class="prose">Sales report for the period {{ date_range }}, 2024.</p>
      <p class="prose" style="display: none;">
        The sales report for [Insert Period] reveals a [positive/negative/stable] performance in our sales operations. Overall sales revenue has [increased/decreased/remained steady] by [X%] compared to the previous period. Key drivers of this performance include [highlight significant factors, e.g., new product launches, seasonal demand fluctuations, or changes in market conditions]<span id="dots" style="font-weight: bold; color: #008060;" @click="toggleDisplayMore()"> Read more...</span>
        <span id="more">The sales report for [Insert Period] reveals a [positive/negative/stable] performance in our sales operations. Overall sales revenue has [increased/decreased/remained steady] by [X%] compared to the previous period. Key drivers of this performance include [highlight significant factors, e.g., new product launches, seasonal demand fluctuations, or changes in market conditions].</span><span id="less" style="font-weight: bold; color: #008060; display: none;" @click="toggleDisplayMore()"> Show less</span>
      </p>
      <div style="margin-top: 30px;">
        <h4 class="figure">Gross revenue components.</h4>
        <div class="mini_container">
          <div class="flex"></div>
          <div class="sales_summary">
            <h3>&#8358; {{ report.sales.toLocaleString() }} <span>Net sales</span> </h3>
            <ul class="sales_bar">
              <li id="net_sales" style="background: #008060;"></li>
              <li id="discounts" style="background: #D0E9DE;"></li>
              <li id="refunds" style="background: pink;"></li>
              <li id="shipping" style="background: orange;"></li>
            </ul>
            <ul class="key" style="border-top: none;">
              <li><span class="key_colour" style="background: #008060;"></span><p class="key_text">Gross revenue</p></li>
              <li><p class="key_value">&#8358; {{ this.gross_revenue.toLocaleString() }}</p></li>
            </ul>
            <ul class="key">
              <li><span class="key_colour" style="background: #6F847E;"></span><p class="key_text">Shipping</p></li>
              <li><p class="key_value">- &#8358; {{ report.shipping.toLocaleString() }}</p></li>
            </ul>
            <ul class="key">
              <li><span class="key_colour" style="background: #D0E9DE;"></span><p class="key_text">Discounts</p></li>
              <li><p class="key_value">-&#8358; {{ 0 }}</p></li>
            </ul>
            <ul class="key">
              <li><span class="key_colour" style="background: #F5FCF4;"></span><p class="key_text">Refunds</p></li>
              <li><p class="key_value">-&#8358; {{ 0 }}</p></li>
            </ul>
          </div>
          <div class="flex"></div>
        </div>

        <h4 class="figure">Gross revenue in period.</h4>
        <div class="mini_container">
          <div class="flex"></div>
          <div class="sales_graph">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <div>
                <h4 style="display: inline-block; margin-top: 5px;">
                  {{ date_range }}
                </h4>
              </div>
              <div class="select_box">
                <span>Gross revenue ^</span>
              </div>
            </div>
            <ul>
              <li v-for="period, i in report.breakdown" :key="i">
                <span style="flex-basis: 40px; opacity: 0.8;">{{ period.unit }}</span>
                <div style="flex-grow: 1;">
                  <div :id="period.unit" class="bar_graph">
                    <span style="color: #F5FCF4;">&#8358;</span>
                    <!-- the info is too small to fit in the space -->
                    <!-- <span>&#8358; {{ period.amount.toLocaleString() }}</span> -->
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="flex"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
* {
  color: #143E32;
  box-sizing: border-box;
}
ul {
  padding: 0;
}
.container {
  padding: 20px;
}
.prose {
  margin-top: 10px;
  font-size: 18px;
  word-spacing: 2px;
  line-height: 24px;
  opacity: 0.9;
}
#more {
  display: none;
}
.mini_container > div:nth-of-type(2) {
  width: 100%;
}
.sales_summary h3 {
  font-size: 24px;
  text-align: center;
}
.sales_summary h3 span {
  display: block;
  font-size: 16px;
  font-weight: lighter;
}
.sales_bar {
  width: 100%;
  height: 17px;
  border-radius: 5px;
  margin-top: 10px;
  /* background: #DAF1DD; */
}
.sales_bar li {
  display: inline-block;
  width: 20px;
  height: 100%;
  border-radius: 5px;
  margin-right: 5px;
}
.mini_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #F5FCF4;
  border: #C4DBD5 1px solid;
  border-radius: 8px;
  padding: 15px 0;
  margin-top: 5px
}
.flex {
  width: 15px;
}
.key {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 15px;
  border-top: #C4DBD5 1px solid;
}
.key_colour {
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 5px;
}
.key_text, .key_colour {
  display: inline-block;
}
.key_text {
  margin-left: 5px;
  vertical-align: top;
}
.sales_graph ul {
  margin-top: 25px; 
 }
.sales_graph ul li {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px; 
 }
 .bar_graph {
  background: #008060;
  border-radius: 5px;
  padding: 3px 10px;
  color: #FFF;
 }
 .bar_graph span {
  display: inline-block;
  width: 100%;
  text-align: right;
  opacity: 0.9;
 }
 .select_box {
  height: 34px;
  background: #FFF;
  border: #C4DBD5 1px solid;
  border-radius: 5px;
 }
 .select_box span {
  display: block;
  padding: 7px;
 }
 .figure {
  margin-top: 5px;
  opacity: 0.8;
  margin-top: 25px;
 }
</style>
