<script>
export default {
  name: "CustomerHistory",
  components: {
  },
  props: [
    'order',
    'order_items',
    'index',
    'products',
  ],
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    order_date() {
      const ms_per_day = 1000 * 60 * 60 * 24;
      let months = [
        "Jan ",
        "Feb ",
        "Mar ",
        "Apr ",
        "May ",
        "Jun ",
        "Jul ",
        "Aug ",
        "Sep ",
        "Oct ",
        "Nov ",
        "Dec ",
      ];
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const ty = ["Today", "Yesterday"];
      let this_order_date = this.order.order_date
      let order_year = parseInt(this_order_date.substring(0, 4));
      let order_month = parseInt(this_order_date.substring(5, 7)) - 1;
      let order_date = parseInt(this_order_date.substring(8, 10));

      const today = new Date();
      const this_day = today.getDay(); // position in week
      const utc1 = Date.UTC(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const utc2 = Date.UTC(order_year, order_month, order_date);
      let difference = Math.floor((utc1 - utc2) / ms_per_day);
      if (difference > 6) {
        return months[order_month] + " " + order_date;
      } else if (difference > 1) {
        if (this_day - difference < 0) {
          //  months[order_month] +  " - " +
          return months[order_month] + " " + order_date;
        }
        return (
          days[this_day - difference] // + ", " + months[order_month] + order_date
        );
      } else {
        return ty[difference];
      }
    },
    ordinal_suffix() {
      let order_date = parseInt(this.order.order_date.substring(8, 10));
      var j = order_date % 10,
        k = order_date % 100;
      if (j == 1 && k != 11) {
        return "st";
      }
      if (j == 2 && k != 12) {
        return "nd";
      }
      if (j == 3 && k != 13) {
        return "rd";
      }
      if (this.order_date == "Today" || this.order_date == "Yesterday") {
        return "";
      }
      return "th";
    },
  },
};
</script>
<template>  
  <td class="td1">
    {{ order_date }} <br>
    ...{{ order.order_ref.slice(-5) }}
  </td>
  <td>
    <span v-for="item, i in order_items[index]" :key="i">
      {{ products[item.product_id] }},
    </span>
  </td>
  <td class="td3">
    <span v-if="order.fulfilled == 0">Pending</span>
    <span v-else-if="order.fulfilled == 1">Complete</span>
    <span v-else-if="order.fulfilled == 2">Part</span>
    <span v-else-if="order.fulfilled == 3">Cancelled</span>
  </td>
</template>

<style scoped>
td {
  padding: 10px;
  vertical-align: baseline;
}
.td1 {
  width: 50px;
}
.td3 {
  width: 65px;
  text-align: right;
  vertical-align: baseline;
}
</style>
