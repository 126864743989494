<script>
import returnHome from "@/assets/Images/returnHome.svg";
import arrowRight from "@/assets/Images/arrowRight.svg";
const Order = [
  {
    name: "Download invoice",
    img: require("@/assets/Images/basketIcon.svg"),
    route: "/orders",
    condition: "notPaidOrPartiallyPaid",
  },
  {
    name: "Copy invoice link",
    img: require("@/assets/Images/link.svg"),
    route: "/summary",
    condition: "notPaidOrPartiallyPaid",
  },
  {
    name: "Download Receipt",
    img: require("@/assets/Images/script.svg"),
    route: "/summary",
    condition: "paid",
  },
  {
    name: "Copy Receipt link",
    img: require("@/assets/Images/script.svg"),
    route: "/summary",
    condition: "paid",
  },
  {
    name: "View order",
    img: require("@/assets/Images/script.svg"),
    route: "/summary",
    condition: "always",
  },
];
const Products = [
  {
    id:1,
    name: "Add another product",
    img: require("@/assets/Images/scrollSheet.svg"),
    route: "/product_form",
  },
  {
    id:2,
    name: "Copy product link",
    img: require("@/assets/Images/link.svg"),
route: '/order_confirmation',
  },
  {
    id:3,
    name: 'view all products',
    img: require('@/assets/Images/shop.svg'),
    route: "/inventory",
  }
]

export default {
  name: "OrderConfirmation",
  props: {
    paidStatus: {
      type: String,
      required: true,
      validator: function (value) {
        return ["paid", "notPaidOrPartiallyPaid"].includes(value);
      },
    },
  },

  data: () => {
    return {
      returnHome,
      Products,
      Order,
      arrowRight,
    };
  },
 computed: {
    message() {
      const type = this.$route.query.type;
      if (type === 'product') {
        return 'Product Successfully Added!';
      } else if (type === 'order') {
        return 'Order Created Successfully!';
      } else {
        return 'Operation Successful!';
      }
   },
     isProduct() {
      return this.$route.query.type === 'product';
   },
     isOrder() {
      return this.$route.query.type === 'order';
    },
  },
  methods: {
    // if paid
    // download receipt | copy receipt link
    // if not paid or partially paid
    // download invoice / copy invoice link
    // always view order,
    // Add another order
        goToInventory() {
      this.$router.push('/inventory');
    },

    shareOrderLink() {
      // Replace with actual order sharing logic
      alert('Order link shared!');
    },
      downloadInvoice() {
      // Replace with actual invoice downloading logic
      alert('Invoice downloaded!');
    }
  },

};
</script>
<template>
  <div class="header_main">
    <img
      :src="returnHome"
      alt="return home arrow"
      class="arr"
      @click="$router.back()"
    />
    <p>Summary</p>
  </div>
  <div class="record">
    <div>
      <h1>
            {{ message }}
          </h1> 
        <div >
           <div v-if="isProduct" class="group">
      <div >
        <div v-for="product in Products" :key="product.id"  class="row">
          <RouterLink :to="product.route" class="inner">
            <img :src="product.img" :alt="`${product.name} Icon`" />
          <p class="text">{{ product.name }}</p>
          </RouterLink>
        </div>
      </div>
    </div>
    <!-- <div v-else-if="isOrder">
      <p>Invoice: {{ invoice }}</p>
      <button @click="shareOrderLink">Share Order Link</button>
      <button @click="downloadInvoice">Download Invoice</button>
    </div> -->
  </div>
          <div >
            <div v-for="i in filteredGroup" :key="i.name">
              <RouterLink :to="i.route" class="inner">
                
                <span>{{ i.name }}</span>
              </RouterLink>
              <img :src="arrowRight" alt="arrow" />
            </div>
          </div>
        </div>
      </div>


</template>
<style scoped>
a {
  text-decoration: none;
}
.header_main {
  padding: 10px 0;
  border-bottom: 1px solid #c4dbd5;
  display: flex;
  align-items: center;
}
.arr {
  padding: 4px;
  background-color: rgba(53, 95, 83, 0.5);
  border-radius: 4px;
  margin: 0 8px;
}
button {
  background: #008060;
  border: none;
  border-radius: 4px;
}

button > a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

button > a > img {
  margin: 0 4px;
}

.btn {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.btn > button {
  width: 95%;
  padding: 12.5px 0;
}
.record {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.record > div {
  width: 90%;
}
h1 {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  color: #002e22;
}
.group {
  display: flex;
  flex-direction: column;
  background-color: #f5fcf4;
  border: #c4dbd5 1px solid;
  border-radius: 8px;
  margin-top: 21px;
  width: 100%;
}
.group > div {
  display: flex;
  flex-direction: column;
  border-bottom: #c4dbd5 1px solid;
}
.inner {
  display: flex;
  align-items: center;
  color: #355f53;
}
.row{
  padding: 12px 14px;
  border-bottom: #c4dbd5 1px solid;
}
.row .inner > .text {
  margin-left:14px ;
}

span {
  margin-left: 12px;
}
</style>
