<script>
import noPayment from "@/assets/Images/noPayment.svg";
import cancelDark from "@/assets/Images/cancelDark.svg";
import plusWhite from "@/assets/Images/plusWhite.svg";
import drpdown from "@/assets/Images/select.svg";
export default {
  props: {
    isSetStatus: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      noPayment,
      cancelDark,
      plusWhite,
      drpdown,
    };
  },
  methods: {
    closePopup() {
      this.$emit("cancel");
    },
  },
};
</script>
<template>
  <div class="modal" v-if="isSetStatus">
    <div class="container_holder">
      <div class="cancel_container">
        <div class="cancel">
          <img :src="cancelDark" alt="cancel" @click="closePopup" />
        </div>
      </div>
      <div class="container">
        <h1>Set status</h1>
        <div class="content">
            <p>Action required by?</p>
            <select v-model="selected" class="custom">
                <option value="Merchant">Merchant</option>
                <option value="Payment solution">Payment solution</option>
                <option value="Developers">Developers</option>
            </select>
            <button>Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  z-index: 1002;
}
.cancel_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}
.container {
  background: #f0f5ef;
  border-radius: 5px;
  width: 100%;
}
.container_holder {
  width: 95%;
}
select {
  border: 1px #c4dbd5 solid;
  border-radius: 6px;
  /* width: 100%; */
  padding: 12px;
  margin-bottom: 24px;
  outline: none;
  background-color: #f5fcf4;
}
input:focus,
.custom:focus, {
  border: #008060 1px solid;

}
.custom {
  width: 100%;
  appearance: none;
  background-image: url("@/assets/Images/select.svg");
  background-size: 25px;
  background-position: right 8px center;
  background-repeat: no-repeat;
  padding-right: 25px;
}

.content {
  display: flex;
  width: auto;
  flex-direction: column;
  padding: 24px 16px;
  border: 1px solid #c4dbd5;
}
h1 {
  color: #002e22;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 16px;
}

label {
  font-weight: 500;
  font-size: 18px;
  color: #002e22;
  margin-bottom: 8px;
}
span {
  background: #008060;
  color: white;
  font-size: 12px;
  margin-right: 5px;
}
p {
  color: #355f53;
  margin: 10px 0;
}
button {
  background-color: #008060;
  color: white;
  padding: 12px;
border: none;
border-radius: 8px;
}
button img {
  margin-right: 8px;
}

.cancel img {
  width: 12px;
  height: 12px;
  background: #90a59d;
  padding: 12px;
  border-radius: 50px;
}
select {
  width: 100%;
  padding: 10px;
}
</style>
