<script>
import CustomHeader from "@/components/CustomHeader.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import greenPlus from "@/assets/Images/greenPlus.svg";

import ProductVariants from "@/components/Inventory/ProductVariants";

import {
  createProduct,
  // productImageUpdate,
  updateProduct,
} from "@/services/apiServices";
import { mapGetters } from "vuex";
import * as mutationTypes from "@/store/mutationTypes";
import PopUpScreen from "@/components/PopUpScreen.vue";

export default {
  name: "ProductForm",
  components: {
    CustomHeader,
    ButtonComponent,
    ProductVariants,
    PopUpScreen,
  },
  data() {
    return {
      imageData: "", // we will store base64 format of image in this string
      greenPlus,
      variantsView: false,
      new_product: false,
      showUp: false,
      product: {
        description: "",
        discount: null,
        discount_type: null,
        display_variants: false,
        display: true, //null // display product on shop floor
        first_variant: "", // Convert array to string to store in db
        first_variant_name: "",
        has_discount: null,
        has_variant: false,
        id: null,
        owner: null,
        price: null,
        product_image: null,
        product_name: null,
        second_variant: "", // Convert array to string to store in db
        second_variant_name: "",
        store: null,
        strict_stock_count: true,
        total_stock: null, // also for manual quantity
        variant_options: "", // Holds all variants as string for db storage
      },

      variant_options: [],
      variant2Length: 0,
      dynastees: false, // temp - dynastees want this feature so only want to display it to them for now
      // make this something they can turn on/off in settings, perhaps
    };
  },
  methods: {
    backToCart() {
      this.saveProduct()
      // saveProduct commits new product to state, and directs back to add_to_cart
    },
    createNewProduct(product) {
      // creates new product with the image only
      createProduct(product)
        .then((res) => {
          this.product.id = res.data.id;
          this.new_product = true;
          // this.product.temp_id = res.data.id; // when creating multiple products at a time
        })
        .catch((err) => {
          // an alert was here
          this.showUp = true
          console.log(err);
        });
      // .finally(() => {
      // });
    },
    editProduct(id) {
      let pass = null;
      let compulsory = [
        this.product.product_name,
        this.product.price,
        this.product.total_stock,
      ];
      for (let i = 0; i < compulsory.length; i++) {
        if (compulsory[i] == null || compulsory[i] == "") {
          pass = false;
          alert("Ensure all fields with a * are complete");
          break;
        }
        pass = true;
      }

      this.product.first_variant == "" && this.product.first_variant_name == "" // && this.product.variant_options
        ? (this.product.has_variant = false)
        : (this.product.has_variant = true);

      if (pass) {
        this.product.price = this.product.price * 100 // updateProduct() divides by 100 due to fractional price in db
        updateProduct(this.product, id)
          .then((res) => {
            res.data.product.price = res.data.product.price / 100
            !this.new_product
              ? this.$store.commit(mutationTypes.UPDATE_PRODUCT, {
                  id: id,
                  updated_product: res.data.product,
                })
              : this.$store.commit(
                  mutationTypes.SAVE_PRODUCT,
                  res.data.product
                );
          })
          .catch((err) => {
            console.log(err);
            this.showUp = true
          })
          .finally(() => {
            // Modal feedback that product added. Add another?
            if (this.edit.id) {
              alert(this.edit.product_name + " updated");
            } else {
              alert(this.product.product_name + " added");

              if (this.origin == 'order') {
                this.$store.commit(mutationTypes.ADD_TO_CART_MAP, this.product.id);
                this.$router.replace({ path: "/add_to_cart" })
              } else {
                this.$router.replace({ path: "/inventory" })
              }
            }
            // this.$router.push({ path: '/order_confirmation', query: { type: 'product' } });
            // this.loading = false;
            // clear product.id when done
          });
      }
    },
    previewImage(event) {
      if (this.product.id > 0) {
        // replacing an image while creating a new product
        this.product.id = null;
        this.saveProduct(event);
      } else if (this.edit.id) {
        // productImageUpdate(this.edit.id)
        null; // edit product image not working yet
      } else {
        // first time adding an image for a new product
        this.saveProduct(event);
      }

      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    saveProduct(e) {
      let new_product = new FormData();
      try {
        new_product.append("product_image", e.target.files[0]);
        // this.uploading_image = true
      } catch {
        null;
      }

      if (!this.edit.id) {
        // let fake_product_id = 1 // delete
        if (this.product.id > 0) {
        // if (fake_product_id > 0) { // delete and replace with line above
          this.editProduct(this.product.id)
        } else {
          this.createNewProduct(new_product);
        }
      } else {
        this.editProduct(this.edit.id);
      }
    },
    setVariantsOptions(variant1, variant2, options1, options2) {
      this.product.first_variant_name = variant1;
      this.product.first_variant = options1;
      this.product.second_variant_name = variant2;
      this.product.second_variant = options2;
    },
    setVariantsStrings(variantStrings, total_stock) {
      this.product.variant_options = variantStrings;
      // disable total_stock
      this.product.total_stock = total_stock;
    },
    toggleVariants() {
      // let variants = document.getElementById("displayVariants")
      // if (variants.checked) {
      //   this.product.display_variants = true
      // } else {
      //   this.product.display_variants = false
      // }
    },
    toggleShowVariants() {
      this.variantsView = !this.variantsView;
    },
  },
  computed: {
    ...mapGetters({
      edit: "getProductToEdit",
      owners: "getOwners",
    }),
    baseUrl() {
      return window.location.origin;
    },
    origin() {
      return this.$route.params.origin
    }
  },
  mounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false);

    if (this.edit.id) {
      this.product.product_name = this.edit.product_name;
      this.product.discount = this.edit.discount;
      this.product.discount_type = this.edit.discount_type;
      this.product.first_variant = this.edit.first_variant;
      this.product.first_variant_name = this.edit.first_variant_name;
      this.product.has_discount = this.edit.has_discount;
      this.product.has_variant = this.edit.has_variant;
      this.product.id = this.edit.id;
      this.product.second_variant_name = this.edit.second_variant_name;
      this.product.second_variant = this.edit.second_variant;
      this.product.owner = this.edit.owner;
      this.product.price = this.edit.price;
      this.product.product_image = this.edit.product_image;
      this.product.strict_stock_count = this.edit.strict_stock_count;
      this.product.total_stock = this.edit.total_stock;
      this.product.variant_options = this.edit.variant_options;

      // display variants if exists
      this.product.has_variant ? this.variantsView = true : null

      this.variant2Length = this.product.second_variant.split(",").length;
      let split = this.product.variant_options.split(";");

      // index 1 and beyond of the split result have a preceeding comma
      // hence the need to treat the first index [0] different from the rest
      let options = split[0];
      this.variant_options.push(options.split(","));

      for (let i = 1; split[i]; i++) {
        options = split[i].substring(1);
        options = options.split(",");
        this.variant_options.push(options);
      }
    }
  },
  unmounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true);
  },
};
</script>
<template>
  <CustomHeader text="Add Product Details" />
  <div class="container">
    <div>
      <label for="">Add Product Image*</label>
      <input type="file" @change="previewImage" />
      <div v-if="imageData.length > 0">
        <img style="width: 100%; max-width: 300px;"  :src="imageData" alt="product image">
      </div>
    </div>
    <div v-if="this.edit.id > 0" class="pd_centered">
      <img
        v-if="baseUrl == 'http://127.0.0.1:8080'"
        :src="'http://127.0.0.1:8000' + product.product_image"
        alt="img"
      />
      <img v-else :src="product.product_image" alt="" class="product image" />
    </div>

    <label for="">Product Name*</label>
    <input v-model="product.product_name" placeholder="Red Bottoms Shoes" />
    <label for=""> Price*</label>
    <input
      v-model="product.price"
      placeholder="5000"
      pattern="[0-9]*"
      inputmode="numeric"
      type="number"
    />
    <label for="" v-if="dynastees">Product Owner</label>
    <select name="owner" id="" v-model="product.owner" v-if="dynastees">
      <option v-for="(owner, i) in this.owners" :value="owner[i].id" :key="i">
        {{ owner[i].name }}
      </option>
    </select>

    <div v-if="this.$route.params.origin === 'product'">
      <label for="">Description <span>(optional)</span></label>
      <textarea
        cols="30"
        rows="5"
        v-model="product.description"
        placeholder="This is a very nice product."
      ></textarea>
    </div>
    
    <label for="">Quantity available*</label>
    <input
      v-model="product.total_stock"
      placeholder="0"
      pattern="[0-9]*"
      inputmode="numeric"
      type="number"
    />

    <div v-if="this.$route.params.origin === 'product'">
      <div>
        <label for="displayVariants">
          <!-- <span v-if="!this.product.display_variants">Does this product have variants like colour, size, material, etc?</span> -->
          <!-- <span v-else>Close variants</span> -->
        </label>
        <!-- <input
            id="displayVariants"
            type="checkbox"
            class="checkbox"
            @change="toggleVariants()"
          /> -->
      </div>
      <div class="card_note">
        <!-- <div v-if="this.product.display_variants" class="card_note"> -->
        <h1>Product variants</h1>
        <p>
          Add variants like size, colour. <br />Separate each variant option with
          a comma.
        </p>
        <p @click="toggleShowVariants" class="variants_list">
        <img :src="greenPlus" alt="green plus">  Add variants like color and size
        </p>
        <div class="" v-if="variantsView">

          <ProductVariants
            :defaultPrice = this.product.price
            @variantsOptions="setVariantsOptions"
            @variantsStrings="setVariantsStrings"
          />
        </div>
      </div>
    </div>

    <!-- discount -->
    <!-- <div class="card_note">
			<h1>Add Discount Price</h1>
			<p>Apply discounts easily to boost sales and attract customers.</p>
			<div class="card_drpdowns">
				<div class="py">
					<div class="py_mode">
						<h3>SKU</h3>
						<select name="" id="" class="custom">
							<option value="" disabled>
		Amount, QTY, Gift Card
							</option>
							<option value="">Amonut</option>
							<option value="">QTY</option>
							<option value="">Gift card</option>
						</select>
					</div>
					<div class="py_mode">
						<h3>Amount</h3>
						<input type="number" placeholder="NGN 5,000">
					</div>
				</div>
			</div>
		</div> -->
  </div>
  <PopUpScreen
    :isVisible="showUp"
    @close="showUp = false"
    title="Error creating or saving product"
    header="Whoops!"
    message="Something went wrong while trying to create this product. Please ensure all fileds are filled!"
  />
  <ButtonComponent v-if="!this.edit.id" @click.prevent="saveProduct()">
    Add product
  </ButtonComponent>
  <ButtonComponent v-else-if="this.origin=='order'" @click.prevent="backToCart(this.newProduct.id)">
    Add product
  </ButtonComponent>
  <ButtonComponent v-else @click.prevent="editProduct(this.edit.id)">
    Save edit
  </ButtonComponent>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  margin-bottom: 32px;
}
form {
  display: flex;
  flex-direction: column;
}
label,
div > label {
  color: rgba(53, 95, 83, 1);
  margin-top: 8px;
}
input,
textarea {
  margin-top: 4px;
  padding: 12px;
  border-radius: 6px;
  border: rgb(196, 219, 213) 1px solid;
  background-color: rgb(245, 252, 244);
  font-size: 14px;
  font-weight: 400;
  font-family: Aeonik;
  width: 90%;
  margin-bottom: 8px;
}
span,
::placeholder {
  color: rgb(146, 170, 163);
}
a {
  color: white;
  text-decoration: none;
}

.var {
  color: rgb(0, 128, 96);
}
.card_note {
  padding: 14px;
  border-radius: 8px;
  background-color: #f5fcf4;
  color: rgb(53, 95, 83);
  border: #c4dbd5 1px solid;
  margin: 8px 0;
  width: 90%;
}
.card_note > p {
  line-height: 20px;
}
h1 {
  color: rgb(20, 62, 50);
  font-weight: 500;
  font-size: 16px;
}
p {
  margin-top: 8px;
  font-size: 14px;
  color: #355f53;
}

.card_note div > button {
  color: gray;
  margin-left: 8px;
  background: white;
  border-radius: 24px;
  padding: 4px 8px;
  border: #c4dbd5 1px solid;
}
.custom {
  width: 100%;
  appearance: none;
  background-image: url("@/assets/Images/select.svg");
  background-color: rgba(245, 252, 244, 1);

  background-size: 25px;
  background-position: right 8px center;
  background-repeat: no-repeat;
  padding-right: 25px;
}

select {
  margin-top: 4px;
  border: 1px #c4dbd5 solid;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  outline: none;
}

.hidden {
  display: none;
}

select {
  color: #92aaa3;
  font-family: "Aeonik";
}

.custom:focus {
  border: #008060 1px solid;
}

.checkbox {
  /* margin-right: 4px; */
}
.py_mode {
  display: flex;
  flex-direction: column;
}
.py_mode > input {
  padding: 12px;
  border-radius: 6px;
  background-color: #f5fcf4;
  border: 1px #c4dbd5 solid;
  font-family: "Aeonik";
  margin-top: 4px;
  margin-bottom: 12px;
}

h3 {
  font-weight: 400;
  font-size: 14px;
  color: rgba(53, 95, 83, 1);
}
.py {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;
}
.card_drpdowns {
  margin-bottom: 24px;
}

.variants_list {
  color: #008060;
  border-bottom: #008060 1px solid;
  padding: 1px;
  width: 65%;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.variants_list > img {
  margin-right: 8px;
}
.pd_centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #c4dbd5;
}

.pd_centered img{
  max-width: 300px;
  border-radius: 8px;
}
</style>
