<script>
import cancelDark from "@/assets/Images/cancelDark.svg";
import avatar from "@/assets/Images/avatarThree.svg";
import box from "@/assets/Images/box.svg";
import email from "@/assets/Images/email.svg";
import calendar from "@/assets/Images/calendar.svg";
import basket from "@/assets/Images/basket.svg";
import greenBox from "@/assets/Images/greenBox.svg";
import greenCopy from "@/assets/Images/greenCopy.svg";
export default {
  props: {
    shareInvoice: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      cancelDark,
      avatar,
      box,
      email,
      calendar,
      basket,
      greenBox,
      greenCopy,
    };
  },
  methods: {
    closePopup() {
      this.$emit("cancel");
    },
  },
};
</script>
<template>
  <div class="modal" v-if="shareInvoice">
    <div class="container_holder">
      <div class="cancel_container">
        <div class="cancel">
          <img :src="cancelDark" alt="cancel" @click="closePopup" />
        </div>
      </div>
      <div class="container">
        <h1>Share invoice / receipt</h1>

        <div class="main-text">
          <div class="header">
            <div class="profile_details">
              <img :src="avatar" alt=" an avatar of boy" />
              <div class="profile_info">
                <h2>Debola Adeola</h2>
                <p>09014567839</p>
              </div>
            </div>
            <button>View profile</button>
          </div>
          <div class="body">
            <div class="order_dets">
              <div>
                <img :src="box" alt="box" />
                <p class="name">Order No</p>
              </div>
              <p class="dets">#16978484874868</p>
            </div>
            <div class="order_dets">
              <div>
                <img :src="email" alt="envelope" />
                <p class="name">Email</p>
              </div>
              <p class="dets">leyyyow@shop.com</p>
            </div>
            <div class="order_dets">
              <div>
                <img :src="calendar" alt="calendar" />
                <p class="name">Date</p>
              </div>
              <p class="dets">Sun, Mar 24 2024</p>
            </div>
            <div class="order_dets">
              <div>
                <img :src="basket" alt="basket" />
                <p class="name">Orders</p>
              </div>
              <p class="dets">Derby shoes & 1 other item</p>
            </div>
            <div class="total">
              <p
                :style="{
                  paddingBottom: '12px',
                  display: 'flex',
                  alignItems: 'center',
                }"
              >
                <span
                  :style="{
                    padding: '5px',
                    borderRadius: '12px',
                    fontSize: '11px',
                  }"
                  >NGN</span
                >Total Amount
              </p>
              <p class="dets">NGN 10,000.00</p>
            </div>
            <div class="last_section">
              <p class="contents">
                <img :src="greenCopy" alt=" copy icon" /> Copy invoice link
              </p>
              <p class="contents">
                <img :src="greenBox" alt="green box icon" /> Send invoice
              </p>
              <p class="contents" :style="{border: 'none'}">
                <img :src="greenBox" alt="green box icon" /> Download as pdf
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  backdrop-filter: blur(3px);
  z-index: 1001;
}
.cancel_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}
.container {
  background: #f0f5ef;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  height: 95%;
   overflow: scroll;
   scroll-behavior: smooth;
}
.container_holder {
   height: 85%;
}
.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px 16px;
  border: 1px solid #c4dbd5;
}
h1 {
  color: #002e22;
  font-size: 18px;
  font-weight: 500;
 padding: 20px 16px;
 border: 1px solid #c4dbd5;
}
h2 {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #143e32;
}
h3 {
  font-weight: 500;
  font-size: 18px;
  color: #002e22;
}
span {
  background: #008060;
  color: white;
  font-size: 12px;
  margin-right: 5px;
}
p {
  color: #355f53;
}
button {
  background-color: #0080601a;
  color: #008060;
  border: none;
  border-radius: 24px;
  padding: 8px 12px;
}

.main-text {
  display: flex;

  flex-direction: column;

}
.cancel img {
  width: 12px;
  height: 12px;
  background: #90a59d;
  padding: 12px;
  border-radius: 50px;
}
.profile_details {
  display: flex;
  align-items: center;
}
.profile_info{
  margin-left: 16px;
}
.profile_details > img {
  margin-left: 6px;
  width: 52px;
  height: 52px;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 25px 16px;
  border-bottom: #c4dbd5 1px solid;
}
.body {
  padding: 25px 16px;
}
.order_dets {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin: 8px 0;
}
.order_dets > div {
  display: flex;
  align-items: center;
}
.name {
  margin-left: 6px;
  color: #355f53;
}
.dets {
  color: #355f53;
  font-weight: 600;
  font-size: 16px;
}
.total {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px #c4dbd5 solid;
  border-bottom: 1px #c4dbd5 solid;
}
.last_section {
  padding: 16px;
  background: white;
  color: #008060;
  margin-top: 20px;
  border-radius: 8px;
}
.contents {
  display: flex;
  padding: 16px;
  border-bottom: 1px #c4dbd5 solid;
  /* margin: 8px 0; */
}
.contents > img {
  margin-right: 6px;
}
</style>
