<script>
import CustomHeader from "../CustomHeader.vue";
import ButtonComponent from "../ButtonComponent.vue";

export default {
components: {
    CustomHeader,
    ButtonComponent
},
data() {
    return {

    }
}
}
</script>
<template>
<CustomHeader text="My Profile" />
<div class="header">
    <ul>
        <li>Basic Details</li>
        <li>
            <RouterLink to="/subscription" class="li">Subscription</RouterLink>
        </li>
        <li>
            <RouterLink to="/payment" class="li">Payment</RouterLink>
        </li>
    </ul>
    <h1>Basic Details</h1>
</div>

<form>
    <label for="">Store name</label>
    <input type="text" placeholder="e.g solesnshades, smile socks">
    <label for="">Store type</label>
    <select class="custom">
        <option value="e.g solesnshades, smile socks">e.g solesnshades, smile socks"</option>
        <option value="Gadget">Gadget</option>

        <option value="food">Food</option>
        <option value="skincare">Skincare</option>
    </select>
    <label for="">Email</label>
    <input type="text" placeholder="example@gmail.com">
    <label for="">Phone number</label>
    <input type="number" placeholder="+234 8082837122">
    <label for="">Password</label>
    <input type="password" placeholder="*****************">

</form>
<div class="last_section">
    <a>Change password?</a>
</div>
<ButtonComponent>Update Basic details</ButtonComponent>
</template>



<style scoped>
.header {
margin: 0 16px;
}

input,
select {
padding: 12px;
border: 1px solid #C4DBD5;
border-radius: 6px;
margin-bottom: 8px;
background-color: #F5FCF4;
}

form {
display: flex;
flex-direction: column;
margin: 16px;
}

input::placeholder {
color: #92AAA3;
font-family: 'Aeonik';
}

label {
font-weight: 400;
font-size: 14px;
margin-bottom: 4px;
color: #355F53;
}

.last_section {
display: flex;
justify-content: end;
margin: 0 16px;
}

a {
text-decoration: none;
color: #355F53;
}

li,
.li {
color: #92AAA3;
padding: 8px;
}

ul {
border-bottom: 1px solid #C4DBD5;
display: flex;
list-style: none;
padding: 0;
margin: 16px 0;
}

h1 {
font-size: 16px;
font-weight: 500;
color: #002E22;
margin-top: 24px;
}

input:focus,
.custom:focus {
border: #008060 1px solid;
}

.custom {
width: 100%;
appearance: none;
background-image: url('@/assets/Images/select.svg');
background-size: 25px;
background-position: right 8px center;
background-repeat: no-repeat;
padding-right: 25px;
}

select {
border: 1px #c4dbd5 solid;
border-radius: 6px;
width: 100%;
padding: 12px;
margin-bottom: 8px;
outline: none;
}

select:first-child {
color: #355F53;
}

li:first-child {
color: #008060;
border-bottom: #008060 1px solid;
}
</style>