<script>
import heroImg from "@/assets/Images/debolabgImg.svg";
import logo from "@/assets/Images/logo.svg";
import Menu from "@/assets/Images/menu.svg";
import Header from '@/components/LandingPage/LandingPageHeader.vue'
import Footer from "@/components/LandingPage/LandingPageFooter.vue";
import * as mutationTypes from '@/store/mutationTypes'

export default {
  components: {
   Header,
   Footer
  },
  data() {
    return {
      heroImg,
        logo,
      Menu,
      
    };
  },
  mounted() {
		this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false)
	},
	unmounted() {
		this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true)
	}
};
</script>
<template>
   <Header />
<div class="container_holder">
  <div class="container">
    <div class="hero">
    <img :src="heroImg" alt="" />
  </div>
  <div class="story_container">
    <div class="story">
      <h2>Our story</h2>
      <div>
      <p>  Our team of experts is comprised of seasoned professionals with years of
        experience in their respective fields. From software engineers to data
        scientists, we have assembled a diverse and talented group dedicated to
        delivering innovative solutions tailored to your specific challenges. We
        take the time to understand your business inside and out, allowing us to
        develop customized strategies that maximize efficiency, minimize costs,
        and accelerate growth.</p> <br/> <p>At the heart of our approach is a commitment to
        transparency, integrity, and results. We believe in building long-term
        partnerships based on trust and mutual success. When you work with
        [Startup Name], you can trust that we'll be with you every step of the
        way, providing ongoing support and guidance to help you navigate the
        complexities of the digital landscape.</p> <br/> <p>
          Whether you're looking to
        streamline your operations, optimize your marketing efforts, or
        revolutionize your customer experience, we have the tools and expertise
        to make it happen. Our solutions are scalable, adaptable, and designed
        to grow with your business, ensuring that you stay ahead of the
        competition today, tomorrow, and beyond.
        </p><br/> <p>So why wait? Experience the
        power of real growth with [Startup Name]. Contact us today to learn more
        about how we can help take your business to the next level. Together, we
        can turn your vision into reality and unlock the full potential of your
        business in the digital age.</p>
      </div>
    </div>
  </div>
</div>
</div>
   <Footer />
</template>

<style scoped>
.hero {
  background-color: #008060;
  position: relative;
  z-index: 1;
  border-radius: 12px;
}
.hero img {
  mix-blend-mode: luminosity;
  width: 100%;
}
.container, .container_holder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.story div {
  margin: 30px 0;
  color: #355f53;
}
h2 {
  color: #008060;
}
.story {
  background-color: white;
  padding: 24px;
  position: relative;
  z-index: 2;
  top: -50px;
  margin-bottom: 40px;
  border-radius: 24px;

}
.story div p{
text-align: justify;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
}

.footer_text_container button {
  padding: 12.5px;
  background-color: #008060;
  color: white;
  border-radius: 8px;
  margin-bottom: 48px;
  border: none;
}

ul {
  padding: 0px;
}
li {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
li img {
  margin-right: 8px;
}
footer{
  background-color: #002e22;
  padding: 48px 8px;
}
footer li, li a{
    color:#92AAA3;
}
li a{
    text-decoration: none;
}
.footer_text_container {
    display: flex;
    align-items: center;
  flex-direction: column;

}
.footer_header {
  font-size: 36px;
  font-weight: 500;
  color: #c4dbd5;
  text-align: center;
}
.footer_text_container > p {
  color: #c4dbd5;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 24px;
}
.footer_container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 32px 16px;
    border-top: 1px solid #355F53;
}
@media screen and (min-width: 1000px){
  .story_container, .container_holder{
    display: flex;
    justify-content: center;

  }
  .container_holder{
align-items:center;
margin-top: 80px;
  }
  .container {
    max-width: 1070px;
  }
  .hero{
    border-radius: 16px;
  }
  .hero img {
  width: 1000px;
  
}
.story{
  top:-150px;
  border: 1px solid #c4dbd5;
  width:650px;
}
.story div > p{
  margin-bottom:16px;
}
}
</style>
