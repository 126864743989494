// import { createStore } from 'vuex'
import mutations from './mutations'
import getters from './getters'
import state from './state'

import Vuex from "vuex"
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  key: "leyyow"
})

export default new Vuex.Store({
// export default createStore({
  state,
  mutations,
  getters,
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin]
})