<script>
import searchIcon from "@/assets/Images/searchIcon.svg";
export default {
  props: {
    modelValue: String,
  },
  data() {
    return {
      searchIcon,
      inputValue: this.modelValue,
    };
  },
  methods: {
    updateInput() {
      this.$emit("update:modelValue", this.inputValue);
    },
    watch: {
      modelValue(newValue) {
        this.inputValue = newValue;
      },
    },
  },
};
</script>
<template>
  <div class="search">
    <input
      type="text"
      v-model="inputValue"
      @input="updateInput"
      placeholder="Search..."
    />
    <span><img :src="searchIcon" alt="a small search icon" /></span>
  </div>
</template>

<style scoped>
input {
  width: 100%;
  background: none;
  border: none;
}
.search {
  width: 60%;
  display: flex;
  flex-direction: row-reverse;
  padding: 4px;
  border-radius: 4px;
  background-color: #F5FCF4;
  outline: 1px solid #c4cdc4;
}
img {
  padding-right: 4px;
}
</style>
