<script>
import avatarThree from "@/assets/Images/avatarThree.svg";
import avatarOne from "@/assets/Images/avatarOne.svg";
import cancel from "@/assets/Images/cancel.svg";
import addBtn from "@/assets/Images/addBtn.svg";

import { mapGetters } from "vuex";
import {
  saveCustomer,
  updateCustomer,
} from "@/services/apiServices";
import * as mutationTypes from "@/store/mutationTypes";
// import CustomerAddedPopup from '@/components/Customer/CustomerAddedPopUp.vue';

export default {
  name: "CustomerForm",

  components: {
    // CustomerAddedPopup
  },
  data() {
    return {
      errorMessage: false,
      avatarThree,
      avatarOne,
      cancel,
      addBtn,
      customer: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        line1: "",
        city: "",
        dob: "",
        line2: "",
        state: "",
        instagram: "",
      },
      disabled: false,
      isCustomerAddedPopupVisible: false,

      isFilled: true,
    };
  },
  methods: {
    checkout() {
      saveCustomer(this.customer)
      .then((res) => {
        this.new_customer_id = res.data.customer.id
        this.$store.commit(mutationTypes.SAVE_NEW_CUSTOMER, res.data.customer)
        this.$store.commit(mutationTypes.SAVE_SELECTED_CUSTOMER, this.new_customer_id)
        this.$router.replace({ name: "Checkout" })
      })
    },
    close() {
      // !this.edit.id
      //   ? this.$store.commit(mutationTypes.SET_CUSTOMER_TO_EDIT, {})
      //   : ""
      this.$router.back();
    },
    createCustomer() {
      this.disabled = true

      this.timeout = setTimeout(() => {
        this.disabled = false
      }, 5000)

      console.log(this.customer)

      saveCustomer(this.customer)
      .then((res) => {
        this.new_customer_id = res.data.customer.id
        this.$store.commit(mutationTypes.SAVE_NEW_CUSTOMER, res.data.customer)
        this.$router.push('/customers/home')
        // give feedback
          // this.isCustomerAddedPopupVisible = true;
      }).catch(err => {
        console.log('error ', err)
      })
    },
    enforcePhoneFormat() {
      // getting an error
      // const x = this.customer.phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,5})/);
      // this.customer.phone = !x[2]? x[1] : `(${x[1]}) ${x[2]}${x[3] ? "-" + x[3] : ""}`;
    },
    saveEdit() {
      // if (this.isFormValid) {
      console.log('saving')
        updateCustomer(this.customer, this.edit.id).then((res) => {
          this.$store.commit(mutationTypes.UPDATE_CUSTOMER, {
            id: this.edit.id,
            updated_customer: res.data,
          });
          // give feedback
          alert(this.customer.first_name + " updated")
        });
      // }
    },
  },
  computed: {
    ...mapGetters({
      edit: "getCustomerToEdit",
    }),
    origin() {
      return this.$route.params.origin;
    },
  },
  mounted() {
    if (this.edit) {
        this.customer.first_name = this.edit.first_name;
        this.customer.last_name = this.edit.last_name;
        this.customer.email = this.edit.email;
        this.customer.phone = this.edit.phone;
        this.customer.line1 = this.edit.line1;
        this.customer.line2 = this.edit.line2;
        this.customer.city = this.edit.city;
        this.customer.state = this.edit.state;
        this.customer.instagram = this.edit.instagram;
        this.customer.dob = this.edit.dob;
    }
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false);
  },
  unmounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true);
    this.disabled = null
  },
};
</script>
<template>
  <div class="morphism">
    <div class="header_main">
      <h1>Customers</h1>
      <div class="profile">
        <img :src="avatarOne" alt="small img of an avatar" />
      </div>
    </div>
    <div class="customer_details_container">
      <div class="cancel_container">
        <div class="cancel">
          <button @click="close()">
            <img :src="cancel" alt="cancel icon" />
          </button>
        </div>
      </div>

      <div class="form_container">
        <div class="header">
          <h1>Add new customer</h1>
        </div>

        <div class="avatar">
          <div class="profile_pic" @change="handleFileUpload">
            <img :src="avatarThree" alt="avatar" />
            <img :src="addBtn" alt="addBtn" class="add" />
          </div>
        </div>

     <form @submit.prevent="handleSubmit">
          <label for="">First Name</label>
          <input type="text" v-model="customer.first_name" placeholder="Ada" />

          <label for="">Last Name</label>
          <input type="text" v-model="customer.last_name" placeholder="Lovelace" />

          <label for="">Phone number</label>
          <!-- <input type="tel" v-model="customer.phone" @input="enforcePhoneFormat" placeholder="08091234567" /> -->
          <input type="tel" v-model="customer.phone" placeholder="08091234567" />

          <label for="">Instagram <span> </span></label>
          <input v-model="customer.instagram" placeholder="@" />

          <label for="">Email <span> </span></label>
          <input type="email" v-model="customer.email" placeholder="example@gmail.com" />

          <label for="">Address line 1</label>
          <textarea type="address" v-model="customer.line1" placeholder=" "></textarea>

          <label for="">Address line 2</label>
          <textarea type="address" v-model="customer.line2" placeholder=" "></textarea>

          <label for="">City or Town </label>
          <input type="text" v-model="customer.city" placeholder="Ikoyi" />

          <label for="">State </label>
          <input type="text" v-model="customer.state" placeholder="Lagos">

          <button v-if="origin === 'cart'" class="btn" :style="{ backgroundColor: isFilled ? '#008060' : '#C4DBD5' }"
                  @click="checkout" type="submit">Go to checkout
          </button>
          <button v-else-if="!edit.id" class="btn" :style="{ backgroundColor: isFilled ? '#008060' : '#C4DBD5' }"
                  @click="createCustomer" :disabled="disabled" type="submit">Add customer
          </button>
          <button v-else class="btn" :style="{ backgroundColor: isFilled ? '#008060' : '#C4DBD5' }"
                  @click="saveEdit" type="submit">Save update
          </button>
        </form>
        <!-- <CustomerAddedPopup v-if="isCustomerAddedPopupVisible" @close="isCustomerAddedPopupVisible = false" /> -->
      </div>
    </div>
  </div>
</template>
<style scoped>
*,
body {
  box-sizing: border-box;
  margin: 0;
}

.morphism {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  background: rgba(79, 75, 75, 0.35);
  z-index: 1;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.header_main {
  filter: blur(5px);
  padding: 0px 16px;
  color: hsl(164, 100%, 9%);
  font-size: 18px;
  font-weight: 500;
  border-bottom: #c4dbd5 1px solid;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: -1;
}

.add {
  position: relative;
  right: 13px;
}

.profile_pic {
}

.cancel {
  padding: 6.6px;
  border-radius: 8px;
  background-color: white;
}

.cancel_container {
  margin-top: 24px;
  position: relative;
  margin-bottom: 16px;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
}

.cancel > button {
  background-color: rgb(53, 95, 83, 0.6);
  color: #355f53;
  opacity: 0.8;
  border: none;
  display: flex;
  padding: 10px;
  width: 100%;
  border-radius: 50%;
}

.header {
  border-bottom: #355f53 1px solid;
  text-align: left;
  width: 100%;
}
.header > h1 {
  margin: 16px 0px;
  margin-left: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #002e22;
  text-align: left;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 24px;
  margin-bottom: 16px;
}

.form_container {
  overflow: scroll;
  position: relative;
  width: 100%;
  z-index: 3;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

form {
  overflow: scroll;
  display: flex;
  margin: 0 16px;
  flex-direction: column;
}

label {
  color: #355f53;
  margin: 4px 0;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

input {
  outline: none;
  padding: 12px;
  margin: 12px 0;
  border-radius: 6px;
  border: 1px solid #c4dbd5;
}

input:focus {
  border: #355f53 1px solid;
}

input::placeholder {
  color: #92aaa3;
}

span {
  color: #92aaa3;
}
.err {
  font-size: small;
  color: red;
  margin: 12px 0 !important;
}
.error {
  border: 1px red solid;
}
.btn {
  color: white;
  padding: 12px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  margin: 24px 0px;
}
</style>
