<script>
import logo from "@/assets/Images/logo.svg";
import Menu from "@/assets/Images/menu.svg";

export default {
    props: {
    navItems: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      logo,
      invisible: false,
      Menu,
    };
  },
  methods: {
     scrollToSection(id) {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    toggleMobileMenu() {
      this.invisible = !this.invisible;
    }
  }, 
};
</script>

<template>
  <div class="menu_holder">
    <div class="menu_container">
      <div class="menu_contents">
        <RouterLink to="/"> <img :src="logo" alt="logo icon" /></RouterLink>
        <div class="side_menu" @click="toggleMobileMenu">
          <img
            class="menu"
            :src="Menu"
            alt="menu icon"
          />
        </div>
      </div>
      <div class="menu_drpdown" :class="{ visible: invisible }">
        <ul>
          <li><a href="aboutUs">Why Leyyow</a></li>
          <li>
            <a href="#features">Feature</a>
          </li>
          <li>
            <a href="#pricing" >Pricing</a>
          </li>
        </ul>
        <div class="action_btns">
      <RouterLink to="login">
        <button class="white_btn">Log in</button>
      </RouterLink>
     <RouterLink to="signUp">
       <button class="green_btn">Get started</button>
     </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.menu_drpdown.visible {
  display: flex;
}
.menu_contents {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.side_menu {
  display: flex;
  color: #355f53;
  align-items: center;
}

.action_btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.menu_drpdown {
  display: none;
  width: 90%;
  padding: 32px 16px;
  flex-direction: column;
}
ul {
  padding: 0;
  padding-bottom: 24px;
}
.menu_drpdown > ul li {
  list-style: none;
  padding-bottom: 24px;
  color: #008060;
  font-size: 14px;
  font-weight: 400;
}
a {
  text-decoration: none;
  color: #008060;
}
button {
  width: 100%;
  border-radius: 8px;
  padding: 14px;
  margin-bottom: 12px;
}
.green_btn
{
  background: #008060;
  color: white;
  border: none;
}
.white_btn:hover {
  background: #c4dbd5;
  color: #008060;
}
.white_btn{
margin-right: 14px;
}
.white_btn,
.green_btn:hover {
  background-color: white;
  outline: 1px solid #c4dbd5;
  border: none;
  color: #008060;
}
.item {
  display: flex;
  align-items: center;
  padding: 8px;
}
.menu {
  width: 32px;
  height: 32px;
}
@media screen and (min-width: 1000px) {
  .menu_holder {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .menu_container {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    width: 100%;
    max-width: 1200px;
  }
  .menu_drpdown {
    display: flex;
  }
  ul {
    display: flex;
  }
  .side_menu {
    display: none;
  }
  .menu_drpdown,
  .menu_container,
  .action_btns,
  ul {
    flex-direction: row;
  }
  .menu_contents {
    width: 20%;
    padding: 0;
  }
  .menu_drpdown {
    width: 70%;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
  button {
    margin-bottom: 0;
    padding: 14px 24px;
  }
  .menu_drpdown > ul li {
    margin-right: 24px;
  }
  ul {
    max-width: 40%;
  }
  ul,
  .menu_drpdown > ul li {
    padding-bottom: 0;
  }
  .green_btn {
    margin-left: 12px;
  }
  .action_btns {
    width: 35%;
  }
}
</style>
