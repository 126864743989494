<template>
  <div class="container">
    <div>
      <img :src="hero" alt="heroImg" />
      <h1>Forgot password</h1>
      <p>
        Enter the email address you use to sign in, and we'll send you a link to
        reset your password.
      </p>
    </div>
    <form>
      <label for="">Email</label>
      <input
        type="email"
        v-model="email"
        placeholder="example@email.com"
        :class="{ error: errorMessage && !isEmailValid }"
      />
      <div v-if="errorMessage">
        <span v-if="!isEmailValid">Invalid email format.</span><br />
      </div>
      <button
        type="submit"
        :style="{ backgroundColor: isFilled ? '#008060' : '#C4DBD5' }"
        :disabled="!isFilled"
        @click="handleSubmit"
      >
        Send the link
      </button>
      <div class="return">
        <p>
          Remebered you password?
          <RouterLink to="/login" style="color: #008060; text-decoration: none"
            >Login</RouterLink
          >
        </p>
      </div>
    </form>
  </div>
</template>
<script>
import hero from "@/assets/Images/hero.svg";

export default {
  name: "forgotPassword",
  data() {
    return {
      hero,
      email: "",
      errorMessage: false,
    };
  },
  computed: {
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    isFormValid() {
      return this.isEmailValid;
    },
    isFilled() {
      return this.email !== "";
    },
  },
    methods: {
    
    handleSubmit(event) {
      if (!this.isFormValid) {
        event.preventDefault();
        this.errorMessage = true;
        return;
      }
      this.errorMessage = false;
    },
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
img {
  width: 100%;
}
.container{
    padding:8px;
}
.return {
  width: 100%;
  display: flex;
  justify-content: center;
}
h1 {
    margin-top: 14px;
  font-weight: 500;
  font-size: 20px;
  color: #002e22;
}

p {
  font-weight: 400;
  color: #355f53;
  font-size: 16px;
  margin: 12px 0;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-bottom: 4px;
  font-weight: 500;
  line-height: 20.3px;
  color: #355f53;
  font-size: 14px;
}

input {
  border: 1px #c4dbd5 solid;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  outline: none;
}

input::placeholder {
  color: #92aaa3;
}

input:focus {
  border: #002e22 1px solid;
}

span {
  font-size: small;
  color: red;
  margin-bottom: 8px;
}

.error {
  border: 1px red solid;
}

button {
  width: 100%;
  margin: 16px 0;
  color: white;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0px 2px 5px 0px rgba(168, 215, 196, 0.75);
  border: none;
  border-radius: 4px;
}
</style>
