<template>
  <label class="custom-checkbox">
    <input type="checkbox" :checked="modelValue" @change="handleChange" />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(event) {
      this.$emit("update:modelValue", event.target.checked);
    },
  },
};
</script>

<style scoped>
label {
}
.custom-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-right: 12px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  width: 24px;
  height: 24px;
  background-color: #92AAA3; 
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.custom-checkbox input:checked + .checkmark {
  background-color: #81c0b6;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked + .checkmark::after {
  display: block;
}

.custom-checkbox .checkmark::after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid #006d48;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>
