<script>
import { mapGetters } from "vuex";

export default {
  name: "VariantOptions",
  props: [
    "option1", 
    "option2", 
    "index1", 
    "index2", 
    "var2len",
    "currentQty",
    "currentPrice",
    "defaultPrice"
  ],
  data() {
    return {
      price: this.defaultPrice,
      qty: null,
      index: null,
    }; 
  },
  methods: {
    updateQtyAndPrice() {
      // console.log('triggered ', this.index, this.qty, this.price)
      this.$emit("updateQtyPrice", this.index, this.qty, this.price)
    },
  },
  computed: {
    ...mapGetters({
      edit: "getProductToEdit",
    }),
  },
  mounted() {
    if (this.option2) {
      this.index = this.index1 * this.var2len + this.index2;
    } else {
      this.index = this.index1;
    }

    this.defaultPrice == null ? this.price = 0 : null



    // if (this.edit.id) {
    //   if (this.option2) {
    //     this.index = this.index1 * this.var2len + this.index2;
    //   } else {
    //     this.index = this.index1;
    //   }

    //   let var_options = this.edit.variant_options.split(';')
    //   let opt_0 = var_options[0].split(',')

    //   if (this.edit.second_variant.split(',')[0] != "" && this.edit.second_variant.split(',')[0] != undefined) {
    //     if (this.index1 == 0 && this.index2 == 0) {
    //       this.qty = opt_0[2]
    //       this.price = opt_0[3]
    //     }

    //     // index 1 and beyond of the split result have a preceeding comma
    //     // hence the need to treat the first index [0] different from the rest
    //     let var1Length = this.edit.first_variant.split(',')
    //     for (let i=1; i < var_options.length; i++) {
    //       let opt_i = var_options[i].substring(1)
    //       opt_i = opt_i.split(',')

    //       let ind = (((var1Length.length - 1) * this.index1) + this.index2) + this.index1
    //       if (ind == i) {
    //         this.qty = opt_i[2]
    //         this.price = opt_i[3]
    //       }
    //     }

    //   } else {
    //     if (this.index1 == 0) {
    //       this.qty = opt_0[1]
    //       this.price = opt_0[2]
    //     }

    //     for (let i=1; i < var_options.length; i++) {
    //       let opt_i = var_options[i].substring(1)
    //       opt_i = opt_i.split(',')

    //       if (this.index1 == i) {
    //         this.qty = opt_i[1]
    //         this.price = opt_i[2]
    //       }
    //     }        
    //   }
    // }
  },
  watch: {

  },
};
</script>

<template>
  <div class="table">
    <h4 class="">{{ option1 }} <span v-if="option2">/</span> {{ option2 }}</h4>
    <div class="td">
      <input
        class="qty"
        type="number"
        v-model="qty"
        pattern="[0-9]*"
        inputmode="numeric"
        placeholder="0"
        @keyup="updateQtyAndPrice()"
      />
      <input
        class="price"
        type="number"
        v-model="price"
        pattern="[0-9]*"
        inputmode="numeric"
        placeholder="0"
        @keyup="updateQtyAndPrice()"
      />
    </div>
  </div>
</template>

<style scoped>
*,
body {
  box-sizing: border-box;
  margin: 0;
}
input {
  outline: none;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #c4dbd5;
  background-color: #f5fcf4;
  text-align: center;
}
input:focus {
  border: #355f53 1px solid;
}
input::placeholder {
  color: #92aaa3;
}
h4{
	font-size: 14px;
	font-weight: 400;
}
.table {
  padding: 13px 17px;
  color: #143e32;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border-bottom: #c4dbd5 1px solid;
}
.td {
  display: flex;
  justify-content: space-between;
}
.qty {
  width: 50px;
}
.price {
  width: 100px;
  margin-left: 10px;
}
</style>
