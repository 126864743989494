<script>
// import * as mutationTypes from "@/store/mutationTypes";

export default {
  name: "NotFound",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  },
  mounted() {
    // this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false);
  },
  unmounted() {
    // this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true);
  },
};
</script>
<template>
  <p>Page not found</p>
</template>

<style scoped>
</style>