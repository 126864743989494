<script>
export default {
  name: "OrderItemNote",
  props: ["index"],
  data() {
    return {
      note: "",
    };
  },
  methods: {
    enterNote() {
      this.$emit("updateNote", this.index, this.note);
    },
  },
};
</script>
<template>
  <textarea
    v-model="note"
    @blur="enterNote()"
    placeholder="Leave a note about this item"
  ></textarea>
</template>

<style scoped>
textarea {
  background: transparent;
  width: 95%;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #c4dbd5;
  margin-top: 10px;
  font-family: 'Aeonik';
}
</style>
