export const ADD_TO_CART_MAP = "ADD_TO_CART_MAP"
// export const INITIALISE = "INITIALISE"
export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DISPLAY_APP_NAV = "DISPLAY_APP_NAV"
export const LOGGED_IN = "LOGGED_IN"
export const SAVE_NEW_CUSTOMER = "SAVE_NEW_CUSTOMER"
export const SAVE_ACCOUNT_ID = "SAVE_ACCOUNT_ID"
export const SAVE_CART_MAP = "SAVE_CART_MAP"
export const SAVE_CHECKOUT_CART = "SAVE_CHECKOUT_CART"
export const SAVE_INVENTORY = "SAVE_INVENTORY"
export const SAVE_METRICS = "SAVE_METRICS"
export const SAVE_SALES_REPORT = "SAVE_SALES_REPORT"
export const LOAD_ORDERS = "SAVE_ORDERS"
export const LOGOUT = "LOGOUT";
export const SAVE_PRODUCT = "SAVE_PRODUCT"
export const SAVE_OWNERS = "SAVE_OWNERS"
// export const SAVE_SALE = "SAVE_SALE"
export const SAVE_SELECTED_CUSTOMER = "SAVE_SELECTED_CUSTOMER"
// export const SAVE_SETTLEMENT = "SAVE_SETTLEMENT"
export const SAVE_STORE = "SAVE_STORE"
export const SAVE_STORE_CUSTOMERS = "SAVE_STORE_CUSTOMERS"
export const SAVE_STORE_SLUG = "SAVE_STORE_SLUG"
export const SAVE_UNPACKED_CART = "SAVE_UNPACKED_CART"
// export const SET_ADD_BTN_DISPLAY = "SET_ADD_BTN_DISPLAY"
// export const SET_BACK_BTN_DISPLAY = "SET_BACK_BTN_DISPLAY"
export const SET_CUSTOMER_STATS = "SET_CUSTOMER_STATS"
export const SET_CUSTOMER_TO_EDIT = "SET_CUSTOMER_TO_EDIT"
// export const SET_HEADER_SETTINGS = "SET_HEADER_SETTINGS"
// export const SET_NEXT_BTN_DISPLAY = "SET_NEXT_BTN_DISPLAY"
export const SET_PRODUCT_TO_EDIT = "SET_PRODUCT_TO_EDIT"
export const SET_NAV_POSITION = "SET_NAV_POSITION"
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_OPTIONS = "UPDATE_OPTIONS"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_SALE = "UPDATE_SALE" // reserve this for updating an existing sale e.g. fulfilled
export const UPDATE_VARIANTS = "UPDATE_VARIANTS"

// export const SAVE_EMAIL = "SAVE_EMAIL";
export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
// export const SAVE_USER = "SAVE_USER";
// export const SAVE_VISITOR_INVENTORY = "SAVE_VISITOR_INVENTORY";
// export const SET_SETTINGS_STATE = "SET_SETTINGS_STATE";
// export const UPDATE_EMAIL_VERIFIED = "UPDATE_EMAIL_VERIFIED";
// export const UNSAVED_CHANGE = "UNSAVED_CHANGE";
// export const SET_FILTER_OPTION = "SET_FILTER_OPTION";
