
<script>
import logo from "@/assets/Images/logo.svg";
    export default {
        data(){
            return {
                logo
            }
        }
    }
</script>
<template>
 <footer>
    <div class="footer_contents">
      <div class="footer_text_container">
        <!-- <h1 class="footer_header">Expand your business' horizons with us.</h1> -->
        <!-- <p class="">Get started in 5 minutes.</p> -->
        <button class="footer_btn">Get started now</button>
      </div>
      <div class="footer_container">
        <img :src="logo" alt="" class="logo" />
        <ul>
          <!-- <li>Privacy Policy</li> -->
          <li><a href="mailto:hello@leyyow.com">hello@leyyow.com</a></li>
        </ul>
        <ul>
          <!-- <li><a href="http://instagram.com/_u/leyyow_app/">Instagram</a></li> -->
          <!-- <li><a href="">Twitter</a></li> -->
          <!-- <li><a href="">Linkendin</a></li> -->
          <!-- <li><a href="">Facebook</a></li> -->
        </ul>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer_text_container, .footer_container{
    display: flex;
}
.footer_text_container button {
  padding: 12.5px;
  background-color: #008060;
  color: white;
  border-radius: 8px;
  margin-bottom: 48px;
  border: none;
}

ul {
  padding: 0px;
}
li {
  align-items: center;
  margin: 20px 0;
  list-style: none;
}
li img {
  margin-right: 8px;
}
footer {
  background-color: #002e22;
  padding: 48px 8px;
}
footer > div li,
li a {
  color: #92aaa3;
}
li a {
  text-decoration: none;
}
.footer_text_container {
  flex-direction: column;
}
.footer_header {
  font-size: 36px;
  font-weight: 500;
  color: #c4dbd5;
  text-align: center;
}
.footer_text_container > p {
  color: #c4dbd5;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 24px;
}
.footer_text_container {
  justify-content: center;
  align-items: center;
}
.footer_container {
  justify-content: space-between;
  align-items: baseline;
  padding: 32px 16px;
  border-top: 1px solid #355f53;
}
@media screen and (min-width:1000px) {
    .footer_header {
    font-size: 64px;
  }
  .footer_container ul {
    display: flex;
  }
  .footer_container ul > li {
    margin-right: 8px;
  }
  footer {
    display: flex;
    justify-content: center;
  }
  .footer_contents {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1070px;
  }

.footer_container {
  flex-direction: row;
}
.footer_text_container h1 {
  max-width: 723px;
}
}

</style>