<template>
      <CustomHeader text="Upload Image"/>
    <div class="contents">

        <h1>Upload Image</h1>
        <button>
            <RouterLink to="/productDets"> Upload Image</RouterLink>
        </button>
    </div>
</template>

<script>
import returnHome from "@/assets/Images/returnHome.svg";
import CustomHeader from '../CustomHeader.vue'
export default {
    data() {
        return {
            returnHome
        }
    },
    components:{
        CustomHeader
    }
}
</script>

<style scoped>


.contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

h1 {
    color: rgba(0, 46, 34, 1);
    font-size: 18px;
}

header>h1 {
    font-size: 16px;
}

.contents>button {
    width: 90%;
    border: none;
    padding: 21px 8px;
    font-size: 16px;
    border-radius: 8px;
    margin-top: 16px;
    color: white;
    background-color: rgba(0, 128, 96, 1);

}
a{
    color: white;
    text-decoration: none;
font-weight:500;
font-size: 14px;
}
</style>