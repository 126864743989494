<script>
import returnHome from "@/assets/Images/returnHome.svg";
import cancelDup from "@/assets/Images/cancelFaint.svg";

import { mapGetters } from "vuex";
import * as mutationTypes from "@/store/mutationTypes";
import { saveOrder, saveOrderItems } from "@/services/apiServices";

export default {
  name: "CheckOut",
  components: {},
  data() {
    return {
      address: "",
      cancelDup,
      channel: 0, // 0,1,2,3,4: offline, IG, whatsapp, website, other
      dispatch: [
        { name: "Other", value: 0, },
        { name: "DHL", value: 1, },
        { name: "GUO Transport", value: 2, },
        { name: "GIG", value: 3, },
        { name: "Uber", value: 4, },
        { name: "Bolt", value: 5,},
        { name: "Gokada", value: 6 },
      ],
      disabled: false,
      displayAddress: null,
      focusedLabel: null,
      fulfilled: null,
      has_customer: false,
      newAddress: true,
      new_dispatch: false,
      orderID: "",
      order_date: null,
      paid_amount: null, //
      payment_mode: 0, // 0,1,2,3: cash, card, transfer, other
      payment_status: null, // 0,1,2: unpaid, paid, partial
      returnHome,
      selected_dispatch: null,
      shipping_price: null,
      shipping_company: "NA",
      shipping_mode: null, // false,true : Delivery, Pickup
      shipping_owner: 1, // person to pay dispatch rider
      shipping_paid: false, //
      timeout: null,
      total_amount: null,
      
    };
  },
  methods: {
    handleFocus(label) {
      this.isFocused = label;
    },
    back() {
      this.$router.go(-1);
    },
    createOrder(data) {
      saveOrder(data)
        .then((res) => {
          // this.$store.commit(mutationTypes.SAVE_SALE, res.data.order)
          this.$store.commit(mutationTypes.UPDATE_CUSTOMER, {
            id: res.data.customer.id,
            updated_customer: res.data.customer,
          })
          this.createOrderItems();
        })
        .catch((err) => {
          err ? "" : "";
        })
        .finally(() => {
          // clear out all options (customer, cart, etc)
          this.$store.commit(mutationTypes.SAVE_CART_MAP, {});
          this.$store.commit(mutationTypes.SAVE_SELECTED_CUSTOMER, {});
          // this.$router.replace("/order_confirmation");
          this.$router.replace("/orders");
        });
    },
    createOrderID() {
      var ref_type = "2"; // '1' purchase by customer; 2, sale added by merchant
      var rand_int = Math.floor(Math.random() * 9999) + 1000;
      const today = new Date();
      var year = today.getFullYear().toString().slice(-2);
      var store_id = this.store.id.toString(); // this allows for up to 9999 stores merchants
      // var customer_id = '0000' // 0 for anonymous
      var month = (today.getMonth() + 1).toString(); // cause month is 0 indexed
      var day = today.getDate().toString();
      var cart_count = this.cart.items.length.toString(); // unique items (products) or absolute number of items? I go for absolute. Use unique because OrderItem signal (backend) depends on it. Duplicate item (not qty) is considered unique
      // var hour = today.getHours().toString();

      // hour = hour.padStart(2,"0")
      month = month.padStart(2, "0");
      day = day.padStart(2, "0");
      cart_count = cart_count.padStart(2, "0");
      store_id = store_id.padStart(4, "0");
      // ref_type + store_id + year + month + day + cart_count // + nth purchase of the day | this.customer_id.padStart(3,"0")
      this.orderID =
        ref_type + store_id + month + day + cart_count + year + rand_int;
    },
    createOrderItems() {
      saveOrderItems(this.orderItems).catch((err) => {
        err ? "" : "";
      });
    },
    paymentStatus(e) {
      this.payment_status = e.target.value;
    },
    saveOrderHandler() {
      // dull the colour of the button to indicate it has been clicked
      this.disabled = true; // not clear what this does

      if (this.fulfilled === null) {
        alert('Please indicate if the order has been fulfilled')
        this.disabled = false
        return
      }
      if (this.shipping_mode === null) {
        alert('Please select if pick-up or delivery')
        this.disabled = false
        return
      }
      if (this.payment_status === null) {
        alert('Please answer if payment has been made')
        this.disabled = false
        return
      }
      // to prevent error creating order while not being strict with merchant
      if (this.new_dispatch && this.shipping_company == "NA") {
        this.shipping_company = "Other"
      }
      this.shipping_price === null ? this.shipping_price = 0 : null

      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, 5000);
      // check paid
      // check delivery
      // check amount and payment mode
      this.createOrderID();
      this.newAddress == false ? (this.address = "") : "";
      let data = {
        channel: this.channel,
        customer_id: this.customer_id,
        fulfilled: this.fulfilled,
        has_customer: this.has_customer,
        items_count: this.cart.items.length, // incorrect
        order_ref: this.orderID,
        order_date: this.order_date,
        paid_amount: this.paid_amount,
        payment_mode: this.payment_mode,
        payment_status: this.payment_status,
        products_total: this.totalBeforeShipping,
        shipping_price: this.shipping_price,
        shipping_company: this.shipping_company, // make compulsory if delivery
        shipping_mode: this.shipping_mode,
        shipping_paid: this.shipping_paid,
        store: this.store.id,
        total_amount: this.totalAmount,
        unique_items: this.cart.items.length,
        // an option was to send customer data
        // and order data in one payload and resolve both at the back end.
        address: this.address, //
      };
      
      this.createOrder(data)
    },
    selectDispatch() {
      if (this.selected_dispatch == 0) {
        this.new_dispatch = true;
      } else {
        this.new_dispatch = false;
        this.shipping_company = this.dispatch[this.selected_dispatch].name;
      }
    },
    setFulfilled(bool) {
      if (bool == 1) {
        this.fulfilled = true;
      } else {
        this.fulfilled = false;
      }
    },
    setDeliveryMethod(value) {
      // Pick up
      value == 1 ? (this.shipping_price = 0) : null;
    },
    setUnpaidAmount() {
      this.paid_amount = 0;
    },
    uncheck() {
      // document.getElementById('amountCheck') = false
    },
    useCustomerAddress() {
      if (document.getElementById("textArea").checked) {
        this.newAddress = false;
      } else {
        this.newAddress = true;
      }
      // apply selected customer's address to payload
    },
    orderDate() {
      console.log(this.order_date)
      const today = new Date();
      var year = today.getFullYear().toString().slice(-2);
      var month = (today.getMonth() + 1).toString(); // cause month is 0 indexed
      var day = today.getDate().toString();
      let date = "20"+year+'-'+month.padStart(2, "0")+'-'+day.padStart(2, "0")

      if (this.order_date > date) {
        alert("Please select a date in the past")
        this.order_date = null
      } else if (this.order_date == date) {
        this.order_date = null
      }
    }
  },
  computed: {
    ...mapGetters({
      cart: "getCheckoutCart",
      customer_id: "getCheckoutCustomer",
      store: "getStore",
    }),
    orderItems() {
      return this.cart.items.map((item, i) => {
        var order_item = {};
        order_item.price_sold = item.price;
        order_item.sub_total = Number(item.subTotal);
        order_item.index = i + 1;
        order_item.order = this.orderID;
        order_item.product = item.id;
        order_item.selected_option1 = item.selected_variant1;
        order_item.selected_option2 = item.selected_variant2;
        order_item.qty = item.count;
        order_item.productid = item.id; // how is this different from product
        item.product_image == null ? order_item.image_url = "" : order_item.image_url = item.product_image
        order_item.note = item.note;
        // order_item.lead_time = item.note;
        // order_item.image = 'images/' + item.product_image
        order_item.has_feedback = item.has_feedback;

        this.fulfilled ? order_item.status = 1 : order_item.status = 0

        return order_item;
      });
    },
    totalAmount() {
      return this.shipping_price + this.totalBeforeShipping;
    },
    totalBeforeShipping() {
      return Number(this.cart.preShippingTotal);
    },
  },
  watch: {
    shipping_mode() {
      if (this.shipping_mode == 0) {
        this.displayAddress = true;
      } else {
        this.displayAddress = false;
      }
    },
  },
  mounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false);
    this.customer_id != 0 ? (this.has_customer = true) : "";
  },
  unmounted() {
    this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true);
    this.disabled = null;
  },
};
</script>
<template>
  <div class="header_main">
    <img
      :src="returnHome"
      alt="return home arrow"
      class="arr"
      @click="$router.back()"
    />
    <p>Additional information</p>
  </div>
  <div class="container">
    <!-- replace with steps 1, 2, 3, etc -->
    <!-- <div class="notice">
      <div class="notice_header">
        <span>Last Step 🙏</span>
        <button class="cancel"><img :src="cancelDup" alt="" /></button>
      </div>
      <p>Last step</p>
    </div> -->
    <form>
      <div class="section">
        <div style="width: 20px;"></div>
        <div style="width: 100%;">
          <h2 class="h2_head">Delivery details</h2>

          <div class="delivery_status">
            <h2>Has the order been delivered or fulfilled?</h2>
            <div class="radio_options diff">
              <label>
                <input
                  type="radio"
                  name="fulfilled"
                  value="true"
                  v-model="fulfilled"
                  @change="setFulfilled(true)"
                /><span class="check"></span>
                Yes
              </label>
              <label :class="{ focused: focusedLabel === 'fulfilled' }">
                <input
                  type="radio"
                  name="fulfilled"
                  @focus="handleFocus"
                  value="false"
                  v-model="fulfilled"
                  @change="setFulfilled(false)"
                /><span class="check"></span>
                No
              </label>
            </div>
          </div>

          <div v-if="fulfilled == true || fulfilled == false" class="delivery_status">
            <h2 v-if="fulfilled">Select delivery method used</h2>
            <h2 v-else>Will this order be dispatched or picked up?</h2>
            <div class="radio_options diff">
              <label :class="{ focused: focusedLabel === 'delivery' }">
                <input
                  type="radio"
                  name="shipping_mode"
                  @focus="handleFocus"
                  @change="setDeliveryMethod(0)"
                  value="0"
                  v-model="shipping_mode"
                /><span class="check"></span>
                Dispatch
              </label>
              <label>
                <input
                  type="radio"
                  name="shipping_mode"
                  @focus="handleFocus"
                  @change="setDeliveryMethod(1)"
                  value="1"
                  v-model="shipping_mode"
                /><span class="check"></span>
                Pick up / NA
              </label>
            </div>
          </div>

          <div v-if="displayAddress" class="form">
            <div style="width: 100%; margin-bottom: 20px;">
              <h2>Delivery Address</h2>
              <div v-if="newAddress">
                <textarea v-model="address" placeholder="Lagos, Nigeria"></textarea>
              </div>
              <small>
                <input
                  id="textArea"
                  type="checkbox"
                  class="checkbox"
                  @change="useCustomerAddress"
                />Same as customer's address
              </small>
            </div>
            <div style="width: 20px;"></div>
          </div>

          <div v-if="shipping_mode == 0" class="delivery_status">
            <h2>Include dispatch fee in customer bill?</h2>
            <div class="radio_options diff">
              <label :class="{ focused: focusedLabel === 'delivery' }">
                <input
                  type="radio"
                  name="shipping_owner"
                  @focus="handleFocus"
                  value="0"
                  v-model="shipping_owner"
                /><span class="check"></span>
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="shipping_owner"
                  @focus="handleFocus"
                  value="1"
                  v-model="shipping_owner"
                /><span class="check"></span>
                No
              </label>
            </div>
          </div>

          <div v-if="shipping_owner == 0" class="py" style="display: flex;">
            <div class="py_mode">
              <h2>Courier</h2>
              <select v-model="selected_dispatch" class="custom" @change="selectDispatch()" style="margin-bottom: 0;">
                <option disabled selected="selected">Select dispatch company</option>
                <option
                  v-for="(service, i) in this.dispatch"
                  :key="i"
                  :value="service.value"
                >
                  {{ service.name }}
                </option>
              </select>
            </div>
            <div style="width: 20px;"></div>
            <!-- <input type="" v-model="shipping_company" placeholder="Dispatch company" /> -->
            <div class="py_mode">
              <h2>Delivery price</h2>
              <input
                pattern="[0-9]*"
                inputmode="numeric"
                type="number"
                v-model="shipping_price"
                placeholder="0"
                class="df"
              />
            </div>
            <div style="width: 20px;"></div>
          </div>
        </div>
        <div style="width: 20px;"></div>
      </div>
      
      <div class="section">
        <div style="width: 20px;"></div>
        <div style="width: 100%">
          <h2 class="h2_head">Payment details</h2>

          <div>
            <div class="paid">
              <h2>Has the order been paid for?</h2>
              <div class="abnf">
                <label :class="{ focused: focusedLabel === 'payment' }">
                  <input
                    type="radio"
                    name="paymentStatus"
                    value="1"
                    v-model="payment_status"
                    @focus="handleFocus('payment')"
                  />
                  <span class="check"></span>
                  Paid
                </label>

                <label :class="{ focused: focusedLabel === 'pay' }">
                  <input
                    type="radio"
                    name="paymentStatus"
                    value="0"
                    v-model="payment_status"
                    @focus="handleFocus('pay')"
                    @change="setUnpaidAmount"
                  />
                  <span class="check"></span>
                  Unpaid
                </label>
              </div>
            </div>
          </div>


          <div v-if="payment_status == 1" class="py" style="display: flex;">
            <div class="py_mode">
              <h2>Payment mode</h2>
              <select v-model="payment_mode" class="custom" style="margin-bottom: 0;">
                <option value="0">Cash</option>
                <option value="1">Card</option>
                <option value="2" selected="selected">Transfer</option>
                <option value="3">Other</option>
              </select>
            </div>
            <div style="width: 20px;"></div>
            <div class="py_mode">
              <h2>Amount</h2>
              <input
                pattern="[0-9]*"
                inputmode="numeric"
                type="number"
                v-model="paid_amount"
                placeholder="0"
                @keypress="uncheck"
                class="df"
              />
              <div class="apache">
                <input
                  type="checkbox"
                  placeholder="0"
                  class="checkbox"
                  id="amountCheck"
                  @change="paid_amount = totalAmount"
                />Full amount
              </div>
            </div>
            <div style="width: 20px;"></div>
          </div>
        </div>
        <div style="width: 20px;"></div>
      </div>

      <div class="section">
        <div style="width: 20px;"></div>
        <div style="width: 100%">
          <h2 class="h2_head">Order details</h2>
          <div style="width: 100%;">
            <h2>Select order date if it happened in the past</h2>
            <input @change="orderDate()" v-model="order_date" type="date" required />
          </div>

          <div style="margin: 25px 0 20px;">
            <h2>Where did the order originate?</h2>
            <select class="custom" v-model="channel">
              <option value="" disabled selected class="dis">
                E.g Physical store, Whatsapp
              </option>
              <option value="0">Store / Walk-in</option>
              <option value="1">Instagram</option>
              <option value="5">Facebook</option>
              <option value="6">X</option>
              <option value="2">Whatsapp</option>
              <option value="3">Website</option>
              <option value="4">Other</option>
            </select>
          </div>
        </div>
        <div style="width: 20px;"></div>
      </div>
    </form>
  </div>

  <div style="display: flex; padding-bottom: 20px;">
    <div style="width: 20px"></div>
    <button :disabled="disabled" @click="saveOrderHandler" style="width: 100%; padding: 15px; color: #FFF; font-weight: bold;">Save order</button>
    <div style="width: 20px"></div>
  </div>

  <div class="cart_total" style="display: none;">
    <div class="cart_total_header">
      <h2 class="h2_head">Order summary</h2>
    </div>
    <div class="cart_total_dets">
      <div>
        <p>
          <span v-if="this.cart.items.length > 1"
            >Total ({{ this.cart.items.length }} items)</span
          >
          <span v-else>Total ({{ this.cart.items.length }} item)</span>
        </p>
        <p>&#8358; {{ totalBeforeShipping }}</p>
      </div>
      <div v-if="this.shipping_owner == 0">
        <p>Ship with {{ this.shipping_company }}</p>
        <p>&#8358; {{ this.shipping_price }}</p>
      </div>
      <div>
        <p>Grand total</p>
        <p>&#8358; {{ this.totalAmount }}</p>
      </div>
    </div>
    <button :disabled="disabled" @click="saveOrderHandler" style="padding: 15px; font-weight: bold;">Save order</button>
  </div>
  <div style="height: 260px; display: none;"></div>
</template>
<style scoped>
* {
  font-size: 16px;
}
.h2_head {
  font-size: 18px; font-weight: bold; width: 100%; padding-bottom: 10px; margin-bottom: 20px; border-bottom: 1px solid rgba(196, 219, 213, 1);
}
.section {
  display: flex; margin-top: 20px; padding-top: 20px; width: 100%; border-radius: 8px; border: 1px solid rgba(196, 219, 213, 1);
}
.cart_total {
  width: 90%;
  padding: 20px;
  background: #f5fcf4;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #c4dbd5;
  position: fixed;
  overflow: hidden;
  transition: 2s ease-in-out;
  bottom: 0;
}
.cart_total_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart_total_dets {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.cart_total_dets div {
  display: flex;
  justify-content: space-between;
}
.cart_total button {
  width: 100%;
  color: white;
  padding: 10px;
  /* margin: 16px 0; */
}
.cart_total p {
  padding: 0 0 10px;
}
.header_main {
  padding: 10px 0;
  border-bottom: 1px solid #c4dbd5;
  display: flex;
  align-items: center;
}

.arr {
  padding: 4px;
  background-color: rgba(53, 95, 83, 0.5);
  border-radius: 4px;
  margin: 0 8px;
}

.container {
  margin: 16px;
}

.notice {
  background: white;
  border-radius: 8px;
  margin: 0;
  margin-bottom: 8px;
  padding: 12px;
  color: #355f53;
}

.notice > p {
  font-size: 16px;
}

.notice_header {
  display: flex;
  justify-content: space-between;
}

.notice_header > button {
  padding: 8.42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7.15px;
  color: #92aaa3;
  background-color: rgba(146, 170, 163, 0.4);
  height: 24px;
  border-radius: 50%;
  border: none;
}

form {
  margin-top: 16px;
}

input[type="checkbox" i] {
  accent-color: rgb(147, 202, 185);
  border-radius: 50%;
}
.focused {
  outline: 1px solid #008060;
}

h2 {
  font-size: 17px;
  font-weight: 500;
  color: #002e22;
  margin-bottom: 8px;
}

.radio_options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.diff {
  display: flex;
  justify-content: left;
}

.diff > label {
  margin-right: 8px;
}

h3 {
  font-weight: 400;
  font-size: 14px;
  color: rgba(53, 95, 83, 1);
}

.custom {
  width: 100%;
  appearance: none;
  background-image: url("@/assets/Images/select.svg");
  background-color: rgba(245, 252, 244, 1);
  background-size: 25px;
  background-position: right 8px center;
  background-repeat: no-repeat;
  padding-right: 25px;
}

select {
  background-color: rgba(245, 252, 244, 1);
  border: 1px #c4dbd5 solid;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  /* margin-bottom: 8px; */
  outline: none;
}

.hidden {
  display: none;
}

select {
  color: #92aaa3;
  font-family: "Aeonik";
}

.custom:focus {
  border: #008060 1px solid;
}

.delivery_status {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.py {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 24px;
}
.checkbox {
  margin-right: 11px;
}
.py_mode {
  display: flex;
  flex-direction: column;
  width: 45%;
}

label {
  display: flex;
  background: rgba(245, 252, 244, 1);
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid rgba(196, 219, 213, 1);
  color: #355f53;
  align-items: center;
}

.abnf {
  display: flex;
}

.abnf > label {
  margin-right: 5px;
}
.apache {
  display: flex;
  color: #355f53;
  margin-top: 5px;
}
label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.check {
  position: relative;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: rgba(185, 187, 186, 0.5);
  border-radius: 50%;
  padding: 5px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  margin-bottom: 0 !important;
}

label input:checked ~ .check {
  background: rgba(196, 219, 213, 1);
}

.check:after {
  content: "";
  position: absolute;
  display: none;
}

label input:checked ~ .check:after {
  display: block;
}

label .check:after {
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid rgba(0, 128, 96, 1);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.form {
  display: flex;
}
.form > input,
.py_mode > input {
  padding: 12px;
  border-radius: 6px;
  background-color: #f5fcf4;
  border: 1px #c4dbd5 solid;
  font-family: "Aeonik";
  /* margin-bottom: 12px; */
}

.form > label {
  color: #355f53;
  font-weight: 400;
  font-size: 14px;
}

input::placeholder {
  color: #92aaa3;
}

button {
  background: #008060;
  border: none;
  border-radius: 4px;
}

button > a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

button > a > img {
  margin: 0 4px;
}

.btn {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.btn > button {
  width: 95%;
  padding: 12.5px 0;
  color: white;
}
small {
  display: flex;
  color: #355f53;
  font-size: 14px;
  font-weight: 400;
}
textarea {
  width: 100%;
  border: 1px solid rgba(196, 219, 213, 1);
  font-family: "Aeonik";
  padding: 10px;
  border-radius: 8px;
  background: rgba(245, 252, 244, 1);
  margin-bottom: 5px;
  font-size: 16px;
}
.df {
  padding: 10px;
  /* width: 94%; */
  background: rgba(245, 252, 244, 1);
  border: 1px solid rgba(196, 219, 213, 1);
  border-radius: 6px;
}
.paid {
  margin: 14px 0 20px;
}
</style>
