<script>
import {
  bulkUploadCustomers,
} from '@/services/apiServices'
import * as XLSX from 'xlsx'

export default {
  name: 'BulkUploadCustomers',
  props: {
  },
  data: () => ({
    file: null,
    sheet: null,
    store_email: null,
  }),
  methods: {
    extractFile(event) {
      // ensure there's a file before submitting
      let file = event.target.files[0]
      const file_reader = new FileReader()
      file_reader.readAsBinaryString(file)
      file_reader.onload = (event) => {
        let binary_data = event.target.result 
        let workbook = XLSX.read(binary_data, {type: 'binary'})

        const sheet = workbook.SheetNames[0] // we know it's position [0] by looking in the workbook file
        console.log("const sheet ", sheet)
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
        this.sheet = data
        console.log("sheet is ", this.sheet)
        // nodes_sheet = JSON.stringify(data, undefined, 4) // converts to string. Not necessary


        // bulkUpload(data) // enable for bulk upload. Also separate the extract part from the api part
        // updateAddresses(data)
      }      
    },
    uploadContacts() {
      alert("Uploading")
      let data = {
        sheet: this.sheet,
        store_email: this.store_email
      }
      bulkUploadCustomers(data)
      .then((res) => { // does this depend on a response or does it fire immediately?
        console.log(res)
      })
    },
  },
  computed: {
  },
}
</script>

<template>
  <div class="">
    <input type="file" id="file" accept=".xls, .xlsx" @change="extractFile($event)" placeholder="Select file">
    <input v-model="store_email" placeholder="store email if different from uploader">
  </div>
  <div class="column table">
    <button @click="uploadContacts">Upload Contacts</button>
  </div>
</template>

<style scoped>
</style>