<script>
// import { UPDATE_PRODUCT } from "@/store/mutationTypes"
// import { updateProduct } from "@/services/apiServices";

export default {
  name: "SetStrictStock",
  props: [
    "id",
    "state",
    "product"
  ],
  data() {
    return {
      isAvailable: true,
    };
  },
  methods: {
    toggleMoreOptions(product) {
      product.toggleMoreOptions = !product.toggleMoreOptions;
    },
    toggleMode() {
      this.isAvailable = !this.isAvailable;
      this.isAvailable
        ? this.toggleStock(true)
        : this.toggleStock(false)
    },
    toggleStock(boolean) {
      let productRep = {};
      Object.assign(productRep, this.product)
      productRep.strict_stock_count = boolean
      productRep.price = productRep.price * 100 // updateProduct() divides by 100 due to fractional price in db

      // updateProduct(productRep, productRep.id)
      //   .then(res => {
      //     this.$store.commit(UPDATE_PRODUCT, res.data.product, {
      //       id: this.id,
      //       updated_product: res.data.product,})
      //   })
    }
  },
  computed: {
    modeTextColor() {
      return this.isAvailable ? '#008060' : 'gray';
    },
    modeText() {
      return this.isAvailable ? 'Strict' : 'Not strict';
    }
  },
  mounted() {
    this.isAvailable = this.state
  }
};
</script>

<template>
  <div class="availability" :style="{ color: modeTextColor }">
    {{ modeText }}
    <label class="switch">
      <input type="checkbox" :checked="state? true : null " @click="toggleMode()">
      <span class="slider round"></span>
    </label>
  </div>
</template>

<style scoped>
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 24px;
  margin-left: 8px;
}
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #93cab9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #93cab9;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.scroll_header {
  display: flex;
  overflow-x: scroll;
  justify-content: space-between;
  border-bottom: #c4dbd5 1px solid !important;
  margin: 16px 0;
  padding: 0;
}

.scroll_header::-webkit-scrollbar {
  display: none;
}

.scroll_header {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.availability > button {
  padding: 10px 20px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 20px;
}

.available {
  background-color: green;
}

.unavailable {
  background-color: lightgreen;
}




.options a > img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.pen {
  color: #355f53;
}
.thrash {
  color: red;
}

.available {
  background-color: green;
}

.unavailable {
  background-color: lightgreen;
}
</style>
