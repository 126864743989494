<script>
import hero from "@/assets/Images/hero.svg"
import drpdown from '@/assets/Images/select.svg'
import * as mutationTypes from '@/store/mutationTypes'
import {signUp, createStore} from "@/services/apiServices"
import axios from "axios"
import Header from "@/components/LandingPage/LandingPageHeader.vue";
import returnHome from "@/assets/Images/returnHome.svg";
export default {
  components: {
    Header
  },
  data() {
    return {
      business_type: "",
      errorMessage: false,
      email: "",
      drpdown,
      hero,
      password: "",
      returnHome,
      store_name: "",
      store_slug: null,
      store_link_url: "",
    };
  },
  computed: {
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    isFilled() {
      return this.store_name !== "" && this.email !== "" && this.password !== "" && this.business_type !== "";
    },
    isFormValid() {
      return this.store_name.length >= 4 && this.isEmailValid && this.password.length >= 6 && this.business_type !== "";
    },
  },
  methods: {
    handleSubmit(event) {
      if (!this.isFormValid) {
        event.preventDefault();
        this.errorMessage = true;
        return;
      }
      this.errorMessage = false;
    },
    registerUser() {
      this.validateInputs = true;
      // if (!this.v$.$error) {
      let data = {
        email: this.email.trim().toLowerCase(),
        password: this.password,
      };
      signUp(data)
        .then((res) => {
          sessionStorage.setItem("leyyow_token", res.data.token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Token ${res.data.token}`

          let data = {
            store_name: this.store_name,
            slug: this.store_slug,
            business_type: this.business_type,
          }

          if (res.status == 200 || res.status == 201) {
            this.email = ''
            this.password = ''
            // this.password2 = ''
            try {
              createStore(data)
                .then((res) => {
                  let store = res?.data.store;
                  // let settlement = res?.data.settlement;
                  let acct_id = res?.data.store.id;

                  // this.$store.commit(
                  //   mutationTypes.SAVE_STORE_SLUG,
                  //   data.slug
                  // );

                  this.$store.commit(mutationTypes.LOGGED_IN, true);
                  this.$store.commit(mutationTypes.SAVE_STORE, store);
                  // this.$store.commit(
                  //   mutationTypes.SAVE_SETTLEMENT,
                  //   settlement
                  // );
                  this.$store.commit(
                    mutationTypes.SAVE_ACCOUNT_ID,
                    acct_id
                  );
                  // this.$store.commit(mutationTypes.EMAIL_VERIFIED, false);
                  this.$router.push("/dashboard")
                  // this.loading = false;
                })
                .catch(() => {
                  // this.loading = false;
                });
            } catch {
                null
              // this.loading = false;
            }
          }
        })
        .catch(() => {
          // EventBus.$emit(
          //   "open_alert",
          //   "error",
          //   Object.values(err.response.data)[0][0]
          // )
        })
      // } else {
      //   // EventBus.$emit("open_alert", "error", "Sign up form incomplete");
      // }
    },
  },
  mounted() {
		this.$store.commit(mutationTypes.DISPLAY_APP_NAV, false)
	},
	unmounted() {
			this.$store.commit(mutationTypes.DISPLAY_APP_NAV, true)
	}
};
</script>

<template>
  <div class="hello">
    <Header r :navItems="navItems" />
      
    <img :src="hero" alt="hero" />
<div class="details">
  <h1></h1>
    <p>
      Track Sales, Monitor Expenses, and Cultivate Customer
      Relationships
    </p>
    <h2>Create a store</h2>
    <form @submit.prevent="handleSubmit">
      <label for="">Email</label>
      <input type="email" v-model="email" placeholder="example@email.com"
      :class="{ 'error': errorMessage && !isEmailValid}" />

     <div v-if="errorMessage">

      <span v-if="!isEmailValid">Invalid email format.</span><br />
     </div>
      <label for="">Store Name</label>
      <input 
        type="text" 
        v-model="store_name" 
        placeholder="e.g solesnshades, smile socks" 
        :class="{'error': errorMessage && store_name.length < 3 }"
      />
      <div v-if="errorMessage">
        <span v-if="store_name.length < 3">Store name must be at least 3 characters.</span>
      </div>
      <label for="">Industry</label>
      <select v-model="business_type" class="custom" :class="{'error': errorMessage && !business_type}">
        <option value="1">Fashion & Accessories</option>
        <option value="2">Health & Beauty</option>
        <option value="3">Gadgets & Electronics</option>
        <option value="4">Events</option>
        <option value="6">Food</option>
        <option value="5">Others</option>
      </select>
      <div v-if="errorMessage">
      <span v-if="!business_type">This is a required field</span></div>

      <label for="">Password</label><input v-model="password" type="password" :class="{ 'error': errorMessage && password.length <= 6}"/>
      <div v-if="errorMessage">
        <span v-if="password.length < 8">Password must be at least 8 characters.</span>
      </div>

      <button type="submit" :style="{ backgroundColor: isFilled ? '#008060' : '#C4DBD5' }" @click="registerUser">
        Create store
      </button>
    </form>
    <footer>
      <p>Already have an account?
        <RouterLink to="login">Log in</RouterLink>
      </p>
      <p class="privacy">
        By signing up, I agree to the
        <a href="#">Leyyow Privacy Policy and Terms of Services</a>
      </p>
    </footer>
</div>
  </div>
</template>

<style scoped>
*{
  box-sizing: border-box;
}
.menu_contents{
  padding: 0;
}
img {
  width: 100%;
  padding: 8px;
}
span{
  font-size: small;
  color: red;
  margin-bottom: 8px
}
.error{
border: 1px red solid;
}
h1 {
  font-weight: 500;
  font-size: 32px;
  color: #002e22;
  margin-bottom:8px;
}

p {
  font-weight: 400;
  color: #355f53;
  font-size: 16px;
  line-height:24px;
  margin-bottom: 20px;
}
.arrowReturn{
  padding: 8px 0px;
}

.arr {
  padding: 4px;
  background-color: rgba(53, 95, 83, 0.5);
  border-radius: 4px;
  margin: 0 8px;
  width:24px;
  height:24px;
}
h2 {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 400;
 color:#002e22;

}

.hello {
padding: 8px;
  width: 100%;
}
.details{
  padding: 8px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-bottom: 3px;
  font-weight: 400;
  line-height: 20.3px;
  color: #355f53;
  font-size: 14px;
}

input,
select {
  border: 1px #c4dbd5 solid;
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  outline: none;
}

input::placeholder {
  color: #92aaa3;
}

input:focus,
.custom:focus {
  border: #008060 1px solid;
}

.custom {
  width: 100%;
  appearance: none;
  background-image: url('../assets/Images/select.svg');
  background-size: 25px;
  background-position: right 8px center;
  background-repeat: no-repeat;
  padding-right: 25px;
}

button {
  width: 100%;
  margin: 16px 0;
  color: white;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 2px 5px 0px rgba(168, 215, 196, 0.75);

}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #008060;
}

footer {
  text-align: center;

}

.privacy {
  margin-top: 148px;

}

.privacy>a {
  color: #355F53;
}
</style>
