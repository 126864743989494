<script>
import cancelDark from "@/assets/Images/cancelDark.svg";
import shoe from "@/assets/Images/productImg.svg";
import calendar from "@/assets/Images/calendar.svg";
import plusWhite from "@/assets/Images/plusWhite.svg";
import profileBadge from "@/assets/Images/profileBadge.svg";
import shop from "@/assets/Images/shop.svg";
import thumbsUp from "@/assets/Images/thumbsUp.svg";
import shopWhitebg from "@/assets/Images/shopWhite.svg";
import sendRocket from "@/assets/Images/sendRocket.svg";
export default {
  props: {
    isChatBox: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      cancelDark,
      shoe,
      plusWhite,
      calendar,
      profileBadge,
      isChecked: false,
      shop,
      thumbsUp,
      shopWhitebg,
      sendRocket,
      messages: [
        {
          sender: "bot",
          text: "Hello! How can I help you today?",
          date: new Date(),
        },
      ],
      newMessage: "",
    };
  },
  methods: {
    closePopup() {
      this.$emit("cancel");
    },
    isIssueForm() {
      this.$emit("showForm");
    },
    setStatus() {
      this.$emit("statusPopUp");
    },
    sendMessage() {
      if (this.newMessage.trim()) {
        this.messages.push({
          id: this.messageIdCounter++,
          sender: "user",
          text: this.newMessage,
          date: new Date(),
        });
        this.newMessage = "";
        // Simulate bot response
        setTimeout(() => {
          this.messages.push({
            id: this.messageIdCounter++,
            sender: "bot",
            text: "Thank you for your message. This is a simulated response.",
            date: new Date(),
          });
        }, 1000);
      }
    },
    formatDate(date) {
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(date);
    },
    formatMessageTime(date) {
      return new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(date);
    },
    formatDateHeader(dateString) {
      const today = this.formatDate(new Date());
      if (dateString === today) {
        return "Today";
      }
      return dateString;
    },
    formatTime(date) {
      return new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(date);
    },
  },
  computed: {
    groupedMessages() {
      const groups = {};
      this.messages.forEach((message) => {
        const date = this.formatDate(message.date);
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(message);
      });
      return groups;
    },
  },
};
</script>
<template>
  <div class="modal" v-if="isChatBox">
    <div class="container_holder">
      <div class="cancel_container flex">
        <div class="cancel">
          <img :src="cancelDark" alt="cancel" @click="closePopup" />
        </div>
      </div>
      <div class="container">
        <h1>Raise an issue</h1>

        <div class="chat-box">
          <div class="message-list">
            <div v-for="(group, i) in groupedMessages" :key="i">
              <div :key="i" class="date-header">
                {{ formatDateHeader(i) }}
              </div>
              <div
                v-for="message in group"
                :key="message.id"
                class="message-wrapper"
              >
                <div :class="['message', message.sender]">
                  <div class="message-content">{{ message.text }}</div>
                  <div class="message-date">
                    <img
                      :src="shopWhitebg"
                      alt="shop icon"
                      @click="setStatus"
                    />
                    {{ formatMessageTime(message.i) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="input-area">
            <input
              v-model="newMessage"
              @keyup.enter="sendMessage"
              placeholder="Type a message..."
            />
            <button @click="sendMessage">
              <img :src="sendRocket" alt=" paprer rocket icon " />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  backdrop-filter: blur(3px);
  z-index: 1001;
}
.flex {
  display: flex;
}
.cancel_container {
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}
.container {
  background: #f0f5ef;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  height: 95%;
  overflow-y: scroll;
}
.input-area {
  position: fixed;
  bottom: 0;
  display: flex;
  padding: 12px 0;
  width: 100%;
  border-top: #008060 1px solid;
}

.chat-box {
  margin: 12px;
}
.date-header {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px;
  color: #355f53;
}
.container_holder {
  height: 85%;
}
.card_btm {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}
h1 {
  color: #002e22;
  font-size: 18px;
  font-weight: 500;
  padding: 20px 16px;
  border: 1px solid #c4dbd5;
}

p {
  color: #355f53;
}
button {
  background-color: #008060;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  width: auto;
  padding: 12px;
  margin: 0 8px;
}

.cancel img {
  width: 12px;
  height: 12px;
  background: #90a59d;
  padding: 12px;
  border-radius: 50px;
}
.card_status {
  border-radius: 24px;
}
.user {
  margin-left: auto;
  border-radius: 18px;
  border-bottom-right-radius: 4px;
}

.bot {
  border-radius: 18px;
  border-bottom-left-radius: 4px;
}
.message-content {
  margin-bottom: 5px;
}
.input-area {
  display: flex;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #eee;
  border-radius: 0 0 15px 15px;
}

input {
  border-radius: 20px;
  background-color: #f0f0f0;
  font-size: 16px;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #c4dbd5;
  width: 75%;
}
.message {
  max-width: 65%;
  padding: 12px;
  background-color: #008060;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  margin-bottom: 24px;
}
.message-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #78bba7;
  padding: 8px 0;
}
</style>
