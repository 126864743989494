<script>
import ProductList from "@/components/Inventory/ProductList.vue";
import cancelDup from "@/assets/Images/cancelFaint.svg";
import Hero from "@/assets/Images/InventoryHero.svg";
import { mapGetters } from "vuex";
import CustomHomeHeader from "@/components/CustomHomeHeader.vue";

export default {
  name: "InventoryView",
  data() {
    return {
      cancelDup,
      Hero,
    };
  },
  components: {
    CustomHomeHeader,
    ProductList,
  },
  computed: {
    ...mapGetters({
      has_product: "getHasProduct",
    }),
  },
};
</script>
<template>
 
    <div v-if="!has_product" class="container">
      <CustomHomeHeader text="Inventory" link="inventory" />
      <!-- <div class="notice">
				<div class="notice_header">
					<span>Note</span>
					<button class="cancel"><img :src="cancelDup" alt=""></button>
				</div>
				<p class="first">Add as many products as you like or import them for convenience, here is a <RouterLink to="/">Quick tip</RouterLink></p> <br/> <p>Choose one image per product to start; you can add more images later.</p>
			</div> -->

      <div class="body">
        <div class="content">
          <img :src="Hero" alt="cartoon of a lady sitting" />
          <h1>Add Product</h1>
          <p>You have no product yet</p>
          <button>
            <RouterLink to="/product_form" class="btn-link"
              >Add a product</RouterLink
            >
            <!-- <RouterLink to="/uploadImg" class="btn-link">Add product</RouterLink> -->
          </button>
          <!-- <RouterLink to="/" class="imp">Import</RouterLink> -->
        </div>
        <div></div>
      </div>
    </div>
    <div v-else>
      <ProductList />
    </div>
 
</template>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
}
.btn-link {
  text-decoration: none;
  color: white;
}
.body {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile > img {
  position: relative;
  bottom: 0;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content > h1 {
  margin: 4px 0;
  font-size: 18px;
  font-size: medium;
  color: #002e22;
}
.content > p {
  margin-bottom: 16px;
  font-size: 16px;
  color: #355f53;
}
button {
  border-radius: 4px;
  background: #008060;
  border: none;
  color: white;
  font-size: 14px;
  padding: 8px 30.5px;
}
.notice {
  display: flex;
  flex-direction: column;
  color: rgba(53, 95, 83, 1);
  padding: 12px;
}
.notice div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notice div > span {
  padding: 4px 8px;
  border-radius: 24px;
  font-size: 14px;
  color: red;
  background-color: rgba(206, 60, 85, 0.1);
}
.notice > p {
  font-size: 16px;
  margin-top: 10px;
}

.notice_header {
  display: flex;
  justify-content: space-between;
}

.notice_header > button {
  padding: 8.42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7.15px;
  color: #92aaa3;
  background-color: rgba(146, 170, 163, 0.4);
  height: 24px;
  border-radius: 50%;
  border: none;
}
.notice {
  background: white;
  border-radius: 8px;
  margin: 0;
  margin-bottom: 8px;
  padding: 12px;
  margin: 16px;
  color: #355f53;
}
.first {
  margin-bottom: 10px;
}
a {
  font-weight: 500;
  font-size: 14px;
  color: rgb(0, 128, 96);
}
.content_para {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content_para > h1 {
  color: rgba(0, 46, 34, 1);
  font-size: 18px;
}
.content_para > p {
  margin-bottom: 4px;
}
.imp {
  margin-top: 18px;
}
</style>
