<script>
import CustomHomeHeader from '@/components/CustomHomeHeader.vue'
import CustomerList from '@/components/Customer/CustomerList.vue'
import Hero from '@/assets/Images/CustomerHero.svg'
import { mapGetters } from 'vuex';

export default {
    name: 'CustomersView',
    components: {
        CustomHomeHeader,
        CustomerList
    },
    computed: {
        ...mapGetters({
        has_customer: 'getHasCustomer',
        }),
    },
    data() {
        return {
           Hero 
        }
    }
}

</script>
<template>
    <div v-if="!has_customer" class="container">
        <div class="body">
            <CustomHomeHeader text="Customers" link="CustomerView"/>
            <div class="content">
                 <img :src="Hero" alt="hero img" />
                <h1>Add Customer</h1>
                <p>Add customers fast and easy</p>
                <button>
                    <RouterLink to="/customer_form/customer/" class="btn-link">Add a customer</RouterLink>
                </button>
            </div>
            <div></div>
        </div>       
    </div>
    <div v-else>
        <CustomerList />
    </div>
</template>

<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
}

.container {
    display: flex;
    flex-direction: column;
}
.btn-link {
    text-decoration: none;
    color: white;
}
.body {
    height: 90vh;
}
.profile>img {
    position: relative;
    bottom: 0;
}
.content {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.content>h1 {
    margin: 4px 0;
    font-size: 18px;
    font-size: medium;
    color: #002E22;
}
.content>p {
    margin-bottom: 16px;
    font-size: 16px;
    color: #355F53;
}
button {
    border-radius: 4px;
    background: #008060;
    border: none;
    color: white;
    font-size: 14px;
    padding: 8px 30.5px;
}
</style>