<script>
import cancelDark from "@/assets/Images/cancelDark.svg";
import shoe from "@/assets/Images/productImg.svg";
import calendar from "@/assets/Images/calendar.svg";
import plusWhite from "@/assets/Images/plusWhite.svg";
import profileBadge from "@/assets/Images/profileBadge.svg";
import shop from "@/assets/Images/shop.svg";
import thumbsUp from "@/assets/Images/thumbsUp.svg";
export default {
  props: {
    isOrderIssue: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      cancelDark,
      shoe,
      plusWhite,
      calendar,
      profileBadge,
      isChecked: false,
      shop,
      thumbsUp,
    };
  },
  methods: {
    closePopup() {
      this.$emit("cancel");
    },
    isIssueForm() {
      this.$emit("showForm");
    },
    isChatModal() {
      this.$emit("showChat");
    },
  },
  components: {},
};
</script>
<template>
  <div class="modal" v-if="isOrderIssue">
    <div class="container_holder">
      <div class="cancel_container flex">
        <div class="cancel">
          <img :src="cancelDark" alt="cancel" @click="closePopup" />
        </div>
      </div>
      <div class="container">
        <h1>Raise an issue</h1>

        <div class="main-text flex">
          <div class="header flex">
            <h1>All issues (3)</h1>
            <button
              @click="isIssueForm"
              :style="{ padding: '10px', margin: '0' }"
            >
              <img
                :src="plusWhite"
                alt="plus white"
                :style="{ marginRight: '5px' }"
              />Add an issue
            </button>
          </div>
          <div>
            <div class="card" @click="isChatModal">
              <div class="card_top">
                <h2
                  :style="{
                    color: '#143E32',
                    fontSize: '16px',
                    marginTop: '6px',
                    marginBottom: '12px',
                  }"
                >
                  Missing button
                </h2>
                <div class="card_dets">
                  <div class="flex">
                    <img :src="calendar" alt="calendar" />
                    <p :style="{ marginLeft: '8px', color: '#92AAA3' }">
                      Date added
                    </p>
                  </div>
                  <p>Mar 22nd, 2024</p>
                </div>
                <div class="card_dets">
                  <div class="flex">
                    <img :src="calendar" alt="calendar" />
                    <p :style="{ marginLeft: '8px', color: '#92AAA3' }">
                      Date last updated
                    </p>
                  </div>
                  <p>Mar 29th, 2024</p>
                </div>
              </div>
              <div class="card_btm">
                <div class="flex">
                  <img
                    :src="profileBadge"
                    alt="profileBadge"
                    class="profile_Badge"
                  />
                  <p
                    class="card_status"
                    :style="{ color: 'red', marginLeft: '5px' }"
                  >
                    High
                  </p>
                </div>
                <p
                  class="card_status"
                  :style="{ borderColor: '#C4DBD5', color: '#92AAA3' }"
                >
                  Adebola
                </p>
              </div>
            </div>
            <div class="card" @click="isChatModal">
              <div class="card_top">
                <h2
                  :style="{
                    color: '#143E32',
                    fontSize: '16px',
                    marginTop: '6px',
                    marginBottom: '12px',
                  }"
                >
                  List of item not complete
                </h2>
                <div class="card_dets">
                  <div class="flex">
                    <img :src="calendar" alt="calendar" />
                    <p :style="{ marginLeft: '8px', color: '#92AAA3' }">
                      Date added
                    </p>
                  </div>
                  <p>Jun 22nd, 2024</p>
                </div>
                <div class="card_dets">
                  <div class="flex">
                    <img :src="calendar" alt="calendar" />
                    <p :style="{ marginLeft: '8px', color: '#92AAA3' }">
                      Date last updated
                    </p>
                  </div>
                  <p>Today</p>
                </div>
              </div>
              <div class="card_btm">
                <div class="flex">
                  <img
                    :src="profileBadge"
                    alt="profileBadge"
                    class="profile_Badge"
                  />
                  <p
                    class="card_status"
                    :style="{
                      color: 'orange',
                      borderColor: 'orange',
                      marginLeft: '5px',
                    }"
                  >
                    Medium
                  </p>
                </div>
                <p
                  class="card_status"
                  :style="{ borderColor: '#C4DBD5', color: '#92AAA3' }"
                >
                  Abdul
                </p>
              </div>
            </div>
            <div class="card" @click="isChatModal">
              <div class="card_top">
                <h2
                  :style="{
                    color: '#143E32',
                    fontSize: '16px',
                    marginTop: '6px',
                    marginBottom: '12px',
                  }"
                >
                  Missing Shirt
                </h2>
                <div class="card_dets">
                  <div class="flex">
                    <img :src="calendar" alt="calendar" />
                    <p :style="{ marginLeft: '8px', color: '#92AAA3' }">
                      Date added
                    </p>
                  </div>
                  <p>Jun 22nd, 2024</p>
                </div>
                <div class="card_dets">
                  <div class="flex">
                    <img :src="calendar" alt="calendar" />
                    <p :style="{ marginLeft: '8px', color: '#92AAA3' }">
                      Date last updated
                    </p>
                  </div>
                  <p>Today</p>
                </div>
              </div>
              <div class="card_btm">
                <div class="flex">
                  <img
                    :src="profileBadge"
                    alt="profileBadge"
                    class="profile_Badge"
                  />
                  <p
                    class="card_status"
                    :style="{
                      color: 'black',
                      borderColor: 'black',
                      marginLeft: '5px',
                    }"
                  >
                    Low
                  </p>
                </div>
                <p
                  class="card_status"
                  :style="{ borderColor: '#C4DBD5', color: '#92AAA3' }"
                >
                  Tunde
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  backdrop-filter: blur(3px);
  z-index: 1001;
}
.flex {
  display: flex;
}
.cancel_container {
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
}
.cancel {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}
.container {
  background: #f0f5ef;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  height: 95%;
  overflow-y: scroll;
}
.profile_Badge {
  border: #c4dbd5 1px solid;
  padding: 8px;
  border-radius: 24px;
  margin-right: 5px;
}

.container_holder {
  height: 85%;
}
.card_btm {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}
h1 {
  color: #002e22;
  font-size: 18px;
  font-weight: 500;
  padding: 20px 16px;
  border: 1px solid #c4dbd5;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.header > h1 {
  font-size: 16px;
  padding: 0;
  border: none;
}
.card {
  background-color: #f5fcf4;
  border: #c4dbd5 2px solid;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 12px;
}
.card_dets {
  display: flex;
  margin: 12px 0;
  justify-content: space-between;
}
.card_dets > p {
  font-weight: 500;
  color: #002e22;
}
.card_btm {
  border-top: 1px solid #c4dbd5;
}
.card_status {
  border: 1px solid red;
  border-radius: 24px;
  padding: 10px 6px;
  display: flex;
  justify-content: center;
}
span {
  background: #f9f3db;
  color: #a75a00;
  padding: 8px;
  font-size: 12px;
  margin-right: 5px;
  border-radius: 24px;
}
p {
  color: #355f53;
}
button {
  background-color: #008060;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 12px;
  font-size: 16px;
}
.main-text {
  display: flex;
  padding: 16px;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 25px;
}
.cancel img {
  width: 12px;
  height: 12px;
  background: #90a59d;
  padding: 12px;
  border-radius: 50px;
}
.card_status {
  border-radius: 24px;
}
</style>
